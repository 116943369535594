import API from './API';
/*
 * Subscription service for interacting with the API backend
 */

const SubscriptionService = () => {
  const get = async (type) => {
    const id = localStorage.getItem('client_token');
    if (!id) {
      return [];
    }
    return await API.call(`/subscriptions/${type}`);
  };

  const edit = async (type, subscriptions) => {
    const opts = {
      method: 'POST',
      body: JSON.stringify({
        type,
        subscriptions,
      }),
    };

    try {
      const response = await API.call('/subscriptions', opts);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const toggle = async (type, query, filters, subscribed) => {
    const opts = {
      method: 'POST',
      body: JSON.stringify({
        type,
        query,
        filters,
        subscribed,
      }),
    };

    try {
      const response = await API.call('/subscriptions/toggle', opts);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const subscribe = async (type, query, filters, subscribed) => {
    const opts = {
      method: 'POST',
      body: JSON.stringify({
        type,
        query,
        filters,
        subscribed,
      }),
    };

    try {
      const response = await API.call('/subscriptions/subscribe', opts);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const unsubscribe = async (type, query, filters) => {
    const opts = {
      method: 'POST',
      body: JSON.stringify({
        type,
        query,
        filters,
      }),
    };

    try {
      const response = await API.call('/subscriptions/unsubscribe', opts);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const unsubscribe_all = async (type, email) => {
    const opts = {
      method: 'POST',
      body: JSON.stringify({
        type,
        email,
      }),
    };

    try {
      const response = await API.call('/unsubscribe-all', opts);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  return {
    get,
    edit,
    toggle,
    subscribe,
    unsubscribe,
    unsubscribe_all,
  };
};

export default SubscriptionService;
