const types = [
  {
    value: 'Full synthesis',
    label: 'Full synthesis',
    legacyKey: '10_1',
  },
  {
    value: 'Rapid synthesis',
    label: 'Rapid synthesis',
    legacyKey: '10_2',
  },
  {
    label: 'Protocol',
    value: 'Protocol',
    legacyKey: '10_3',
  },
  {
    value: 'Other',
    label: 'Other',
    legacyKey: '10_4',
  },
  {
    value:
      'No, after reviewing the type of synthesis and eligibility criteria, this record is not eligible for inclusion in COVID-END.',
    label:
      'No, after reviewing the type of synthesis and eligibility criteria, this record is not eligible for inclusion in COVID-END.',
  },
];

const questionTypes = [
  {
    label: 'Benefits and harms',
    value: 'Benefits and harms',
  },
  {
    label: 'Views and experiences',
    value: 'Views and experiences',
  },
  {
    label: 'Test accuracy',
    value: 'Test accuracy',
  },
  {
    label: 'Cost effectiveness',
    value: 'Cost effectiveness',
  },
  {
    label: 'How and why it works',
    value: 'How and why it works',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const tree = {
  checkedKeysPHM: {
    title: 'Public-health Measures',
    legacyKey: '10',
    items: [
      {
        title: 'Infection prevention',
        key: 'infectionPrevention',
        legacyKey: '10_10',
        children: [
          {
            title: 'Vaccination',
            key: 'infectionPreventionVaccination',
            legacyKey: '10_10_10',
            children: [
              {
                title:
                  'Safety, efficacy and effectiveness of a complete course (not including boosters)',
                key: 'infectionPreventionVaccinationTwoSimilarDoses',
                legacyKey: '10_10_10_10',
              },
              {
                title:
                  'Safety, efficacy and effectiveness of an incomplete vaccination course',
                key: 'infectionPreventionVaccinationOneDoseTwoRecommended',
                legacyKey: '10_10_10_30',
              },
              {
                title:
                  'Safety, efficacy and effectiveness of vaccination boosters',
                key: 'infectionPreventionVaccinationTwoDosesBeforeVariants',
                legacyKey: '10_10_10_40',
              },
              {
                title:
                  'Safety, efficacy and effectiveness in special populations',
                key: 'infectionPreventionVaccinationEffectivenessPopulationSegment',
                legacyKey: '10_10_10_60',
              },
              {
                title:
                  'Safety, efficacy and effectiveness of vaccination against other infectious diseases to prevent COVID-19',
                key: 'infectionPreventionVaccinationComplementary',
                legacyKey: '10_10_10_70',
              },
              {
                title:
                  'Supporting discovery of a vaccine to prevent COVID-19 in general, and for specific population groups',
                key: 'infectionPreventionVaccinationDiscovery',
                legacyKey: '10_10_10_80',
              },
            ],
          },
          {
            title: 'Personal protection',
            key: 'personalProtection',
            legacyKey: '10_10_20',
            children: [
              {
                title: 'Washing hands or using hand sanitizer regularly',
                key: 'personalProtectionWashingHands',
                legacyKey: '10_10_20_10',
              },
              {
                title: 'Wearing masks and respirators',
                key: 'personalProtectionWearingMasks',
                legacyKey: '10_10_20_20',
              },
              {
                title: 'Cleaning and disinfecting in non-healthcare settings',
                key: 'personalProtectionDisinfecting',
                legacyKey: '10_10_20_40',
              },
              {
                title: 'Reducing contacts and physical distancing',
                key: 'personalProtectionPhysicalDistancing',
                legacyKey: '10_10_20_50',
              },
              {
                title: 'Altering sexual activities',
                key: 'personalProtectionSexualActivities',
                legacyKey: '10_10_20_70',
              },
              {
                title: 'Promoting adherence to public-health support measures',
                key: 'personalProtectionPublicBehaviorChangeSupports',
                legacyKey: '10_10_20_80',
              },
              {
                title: 'Mouthwashing',
                key: 'personalProtectionMouthwashing',
                legacyKey: '10_10_20_91',
              },
              {
                title: 'Other personal protection measures',
                key: 'personalProtectionOther',
                legacyKey: '10_10_20_100',
              },
            ],
          },
          {
            title:
              'Other infection prevention measures (e.g., ventilation, limiting capacity, etc.)',
            key: 'otherInfectionPreventionMeasures',
            legacyKey: '10_10_21',
          },
        ],
      },
      {
        title: 'Infection control',
        key: 'infectionControl',
        legacyKey: '10_20',
        children: [
          {
            title: 'Screening',
            key: 'infectionControlScreening',
            legacyKey: '10_20_10',
            children: [
              {
                title:
                  'Universal or targetting specific populations for conducting screening',
                key: 'infectionControlScreeningTargets',
                legacyKey: '10_20_10_10',
              },
              {
                title:
                  'Methods to screen populations (symtpom questionnaires, temperature, etc.)',
                key: 'infectionControlScreeningMethods',
                legacyKey: '10_20_10_20',
              },
              {
                title: 'Wastewater surveillance',
                key: 'infectionControlWastewaterSurveillance',
                legacyKey: '10_20_10_24',
              },
            ],
          },
          {
            title: 'Quarantining or isolating individuals',
            key: 'infectionControlServiceQuarantine',
            legacyKey: '10_20_20',
            children: [
              {
                title:
                  'Voluntary or imposed quarantine of exposed or potentially exposed individuals',
                key: 'infectionControlServiceQuarantineVoluntaryOrImposed',
                legacyKey: '10_20_20_10',
              },
              {
                title: 'Isolating suspected or confirmed cases',
                key: 'infectionControlServiceQuarantineIsolatingSuspectedOrConfirmed',
                legacyKey: '10_20_20_14',
              },
              {
                title:
                  'Duration of quarantine or isolation and criteria to exit',
                key: 'infectionControlServiceQuarantineExitCriteria',
                legacyKey: '10_20_20_18',
              },
            ],
          },
          {
            title: 'Testing of active infection',
            key: 'infectionControlServiceTesting',
            legacyKey: '10_20_30',
            children: [
              {
                title: 'Methods used in the test',
                key: 'infectionControlServiceTestingMethodsUsed',
                legacyKey: '10_20_30_20',
              },
              {
                title: 'Methods (type of specimen)',
                key: 'infectionControlServiceTestingMethodsType',
                legacyKey: '10_20_30_30',
              },
              {
                title: 'Methods (site from where specimen is taken)',
                key: 'infectionControlServiceTestingMethodsSite',
                legacyKey: '10_20_30_40',
              },
              {
                title: 'Rapid testing',
                key: 'infectionControlServiceTestingSpeedingResults',
                legacyKey: '10_20_30_50',
              },
            ],
          },
          {
            title: 'Susceptibility tracking',
            key: 'infectionControlServiceContactSusceptibilityTracking',
            legacyKey: '10_20_50',
            children: [
              {
                title:
                  'Antibody testing (seroprevalence) to detect previous infection or exposure',
                key: 'infectionControlServiceAntibodyTesting',
                legacyKey: '10_20_50_10',
              },
              {
                title: 'Antibody testing to detect protection from vaccination',
                key: 'infectionControlServiceAntibodyTestingProtection',
                legacyKey: '10_20_50_14',
              },
              {
                title: 'Antibody test usage',
                key: 'infectionControlServiceAntibodyTestUsage',
                legacyKey: '10_20_50_20',
              },
            ],
          },
          {
            title: 'Contact tracing',
            key: 'infectionControlServiceContactTracing',
            legacyKey: '10_20_60',
            children: [
              {
                title: 'Contact tracing teams',
                key: 'infectionControlContactTracingTeams',
                legacyKey: '10_20_60_10',
              },
              {
                title:
                  'Technology (e.g., apps, etc.) support for contact tracing',
                key: 'infectionControlContactTracingTechnology',
                legacyKey: '10_20_60_20',
              },
            ],
          },
          {
            title:
              'Other infection control measures (e.g., vaccination passports, etc.)',
            key: 'infectionControlOtherMeasures',
            legacyKey: '10_20_70',
          },
        ],
      },
      {
        title: 'Broader public-health measures',
        key: 'broaderPublicHealthMeasures',
        legacyKey: '10_30',
        children: [
          {
            title: 'Risk stratification',
            key: 'riskStratification',
            legacyKey: '10_30_10',
            children: [
              {
                title: 'Stratifying the population by risk of infection',
                key: 'riskStratificationByInfection',
                legacyKey: '10_30_10_10',
              },
            ],
          },
          {
            title: 'Outbreak management',
            key: 'outbreakManagement',
            legacyKey: '10_30_20',
            children: [
              {
                title: 'Locations (essential services or others)',
                key: 'outbreakManagementLocations',
                legacyKey: '10_30_20_10',
              },
              {
                title: 'Rapid-response mechanisms',
                key: 'outbreakManagementRapidResponse',
                legacyKey: '10_30_20_20',
              },
            ],
          },
          {
            title: 'Pandemic tracking',
            key: 'pandemicTracking',
            legacyKey: '10_30_30',
            children: [
              {
                title: 'Levels of re-emergence that trigger action',
                key: 'pandemicTrackingReemergenceLevels',
                legacyKey: '10_30_30_10',
              },
            ],
          },
          {
            title: 'Combination of public-health measures',
            key: 'combinationOfPublicHealthMeasures',
            legacyKey: '10_30_40',
          },
        ],
      },
    ],
  },
  checkedKeysCMHI: {
    title:
      'Clinical management of COVID-19 and pandemic-related health issues (no longer updated)',
    legacyKey: '20',
    items: [
      {
        title: 'Prophylaxis for COVID-19',
        key: 'clinicalManagementProphylaxis',
        legacyKey: '20_10',
        children: [
          {
            title: 'Drugs to prevent COVID-19 infection among people exposed',
            key: 'clinicalManagementProphylaxisDrugs',
            legacyKey: '20_10_10',
          },
          {
            title: 'Drugs to prevent symptoms of post COVID conditions',
            key: 'clinicalManagementProphylaxisDrugsPreventSymptoms',
            legacyKey: '20_10_20',
          },
        ],
      },
      {
        title: 'Clinical treatment of COVID-19',
        key: 'clinicalManagementTreatment',
        legacyKey: '20_20',
        children: [
          {
            title: 'Assessing most important prognostic factors',
            key: 'clinicalManagementTreatmentPrognosticFactors',
            legacyKey: '20_20_10',
          },
          {
            title: 'Drugs to treat COVID-19',
            key: 'clinicalManagementTreatmentDrugs',
            legacyKey: '20_20_20',
          },
          {
            title: 'Blood products to treat COVID-19',
            key: 'clinicalManagementTreatmentBlood',
            legacyKey: '20_20_30',
            children: [
              {
                title: 'Convalescent plasma',
                key: 'clinicalManagementTreatmentBloodConvalescentPlasma',
                legacyKey: '20_20_30_10',
              },
              {
                title: 'Hyperimmune immunoglobulin',
                key: 'clinicalManagementTreatmentBloodHyperimmuneImmunoglobin',
                legacyKey: '20_20_30_20',
              },
            ],
          },
          {
            title: 'Ventilation for COVID-19',
            key: 'clinicalManagementTreatmentVentilation',
            legacyKey: '20_20_40',
            children: [
              {
                title: 'Mechanical ventilation',
                key: 'clinicalManagementTreatmentVentilationInvasive',
                legacyKey: '20_20_40_10',
              },
              {
                title: 'Oxygen and other supports',
                key: 'clinicalManagementTreatmentVentilationNonInvasive',
                legacyKey: '20_20_40_20',
              },
            ],
          },
          {
            title: 'Other treatments for COVID-19',
            key: 'clinicalManagementTreatmentOther',
            legacyKey: '20_20_50',
          },
          {
            title:
              'Management of COVID-19 complications in patients with active infection',
            key: 'clinicalManagementTreatmentOtherAspects',
            legacyKey: '20_20_60',
            children: [
              {
                title: 'Management of cardiovascular complications',
                key: 'clinicalManagementTreatmentOtherAspectsCardiovascular',
                legacyKey: '20_20_60_10',
              },
              {
                title: 'Management of renal complications',
                key: 'clinicalManagementTreatmentOtherAspectsRenal',
                legacyKey: '20_20_60_20',
              },
              {
                title: 'Management of respiratory complications',
                key: 'clinicalManagementTreatmentOtherAspectsRespiratory',
                legacyKey: '20_20_60_30',
              },
              {
                title: 'Management of other complications',
                key: 'clinicalManagementTreatmentOtherAspectsOther',
                legacyKey: '20_20_60_40',
              },
            ],
          },
          {
            title:
              'Community-based treatment of COVID-19 and community-based home monitoring',
            key: 'clinicalManagementTreatmentCommunity',
            legacyKey: '20_20_70',
          },
          {
            title: 'Complementary and alternative therapies',
            key: 'clinicalManagementTreatmentComplementary',
            legacyKey: '20_20_80',
          },
        ],
      },
      {
        title: 'Management of COVID-19 with a syndemic orientation',
        key: 'clinicalManagementSyndemic',
        legacyKey: '20_30',
      },
      {
        title: 'Treatment of post-COVID-19 conditions',
        key: 'clinicalManagementTreatmentPost',
        legacyKey: '20_40',
        children: [
          {
            title:
              'Assessing most important prognostic factors to develop post COVID-19 conditions',
            key: 'clinicalManagementTreatmentPostPrognosticFactors',
            legacyKey: '20_40_5',
          },
          {
            title: 'Treatment of symptoms of post COVID-19 conditions',
            key: 'clinicalManagementTreatmentPostLongSymptoms',
            legacyKey: '20_40_10',
          },
          {
            title: 'Treatment of multiorgan effects',
            key: 'clinicalManagementTreatmentPostMultiOrganEffects',
            legacyKey: '20_40_20',
          },
          {
            title:
              'Treatment of the effects of COVID-19 treatment or hospitalization',
            key: 'clinicalManagementTreatmentPostHospitalizationEffects',
            legacyKey: '20_40_30',
          },
          {
            title: 'Treatment of other COVID-19 sequelae',
            key: 'clinicalManagementTreatmentPostSequelae',
            legacyKey: '20_40_40',
          },
        ],
      },
      {
        title:
          'Clinical management of pandemic-related impacts on health more generally',
        key: 'clinicalManagementPandemicImpact',
        legacyKey: '20_50',
        children: [
          {
            title: 'COVID-19 alongside other infectious diseases',
            key: 'clinicalManagementPandemicImpactInfectiousDiseases',
            legacyKey: '20_50_30',
          },
          {
            title:
              'Other types of urgent care (including interrupted management)',
            key: 'clinicalManagementPandemicImpactUrgentCare',
            legacyKey: '20_50_10',
          },
          {
            title: 'Chronic and other existing health conditions',
            key: 'clinicalManagementPandemicImpactExisting',
            legacyKey: '20_50_40',
            children: [
              {
                title: 'Chronic conditions',
                key: 'clinicalManagementPandemicImpactExistingChronic',
                legacyKey: '20_50_40_10',
              },
              {
                title: 'Cancer',
                key: 'clinicalManagementPandemicImpactExistingCancer',
                legacyKey: '20_50_40_20',
              },
              {
                title: 'Other conditions',
                key: 'clinicalManagementPandemicImpactExistingOther',
                legacyKey: '20_50_40_30',
              },
            ],
          },
          {
            title: 'Burn-out and trauma in essential workers',
            key: 'clinicalManagementPandemicImpactBurnout',
            legacyKey: '20_50_50',
          },
          {
            title:
              'Mental health and addiction issues related to the pandemic response',
            key: 'clinicalManagementPandemicImpactMentalHealth',
            legacyKey: '20_50_60',
            children: [
              {
                title: 'Remote management of existing mental-health conditions',
                key: 'clinicalManagementPandemicImpactMentalHealthRemote',
                legacyKey: '20_50_60_10',
              },
              {
                title:
                  'Management of pandemic-related mental-health conditions',
                key: 'clinicalManagementPandemicImpactMentalHealthPandemic',
                legacyKey: '20_50_60_20',
              },
            ],
          },
          {
            title: 'Reproductive care for patients with COVID-19',
            key: 'clinicalManagementPandemicImpactReproductive',
            legacyKey: '20_50_70',
            children: [
              {
                title: 'Antenatal care',
                key: 'clinicalManagementPandemicImpactReproductiveAntenatal',
                legacyKey: '20_50_70_10',
              },
              {
                title: 'Childbirth',
                key: 'clinicalManagementPandemicImpactReproductiveChildbirth',
                legacyKey: '20_50_70_20',
              },
              {
                title: 'Post-partum care',
                key: 'clinicalManagementPandemicImpactReproductivePostpartum',
                legacyKey: '20_50_70_30',
              },
              {
                title: 'Newborn care',
                key: 'clinicalManagementPandemicImpactReproductiveNewborn',
                legacyKey: '20_50_70_40',
              },
              {
                title: 'Termination services',
                key: 'clinicalManagementPandemicImpactReproductiveTermination',
                legacyKey: '20_50_70_60',
              },
            ],
          },
        ],
      },
      {
        title: 'Health promotion more generally',
        key: 'clinicalManagementPandemicHealthPromotion',
        legacyKey: '20_60',
        children: [
          {
            title: 'Eating healthy food',
            key: 'clinicalManagementPandemicHealthPromotionFood',
            legacyKey: '20_60_10',
          },
          {
            title:
              'Avoiding or minimizing unhealthy behaviours like smoking or excessive alcohol intake',
            key: 'clinicalManagementPandemicHealthPromotionAvoidUnhealthyIntake',
            legacyKey: '20_60_20',
          },
          {
            title: 'Remaining physically active',
            key: 'clinicalManagementPandemicHealthPromotionPhysicallyActive',
            legacyKey: '20_60_30',
          },
          {
            title: 'Staying socially connected',
            key: 'clinicalManagementPandemicHealthPromotionSociallyConnected',
            legacyKey: '20_60_40',
          },
        ],
      },
    ],
  },
  checkedKeysHSA: {
    title: 'Health-system arrangements',
    legacyKey: '30',
    items: [
      {
        title: 'Cross-cutting system arrangements',
        key: 'healthSystemArrangementsCrossCutting',
        legacyKey: '30_10',
        children: [
          {
            title: 'Approach to COVID-19 vaccine roll-out',
            key: 'healthSystemArrangementsCrossCuttingVaccineRollout',
            legacyKey: '30_10_10',
            children: [
              {
                title:
                  'Securing and distributing a reliable supply of vaccines and ancillary supplies',
                key: 'healthSystemArrangementsCrossCuttingVaccineRolloutSecuringDistributing',
                legacyKey: '30_10_10_10',
              },
              {
                title: 'Allocating vaccines and ancillary supplies equitably',
                key: 'healthSystemArrangementsCrossCuttingVaccineRolloutEquitableAllocation',
                legacyKey: '30_10_10_20',
              },
              {
                title:
                  'Communicating vaccine-allocation plans and the safety and effectiveness of vaccines',
                key: 'healthSystemArrangementsCrossCuttingVaccineRolloutCommunication',
                legacyKey: '30_10_10_30',
              },
              {
                title:
                  'Administering vaccines in ways that optimize timely uptake',
                key: 'healthSystemArrangementsCrossCuttingVaccineRolloutAdministration',
                legacyKey: '30_10_10_40',
              },
              {
                title:
                  'Surveillance, monitoring and evaluation, and reporting of vaccine roll-out progress',
                key: 'healthSystemArrangementsCrossCuttingVaccineRolloutSurveillance',
                legacyKey: '30_10_10_50',
              },
              {
                title:
                  'Incorporating COVID-19 boosters in regular vaccination schedules',
                key: 'healthSystemArrangementsCrossCuttingVaccineRolloutBoosters',
                legacyKey: '30_10_10_44',
              },
              {
                title: 'Supporting vaccine confidence and uptake',
                key: 'healthSystemArrangementsCrossCuttingVaccineRolloutConfidenceUptake',
                legacyKey: '30_10_10_60',
              },
            ],
          },
          {
            title:
              'Approach to population-health management for COVID-19 and for those whose care is disrupted by COVID-19',
            key: 'healthSystemArrangementsCrossCuttingPopHealth',
            legacyKey: '30_10_20',
            children: [
              {
                title:
                  'Segmenting the population into groups with shared health and social needs',
                key: 'healthSystemArrangementsCrossCuttingPopHealthSegmenting',
                legacyKey: '30_10_20_10',
              },
              {
                title:
                  'Re-designing care pathways and in-reach and out-reach services',
                key: 'healthSystemArrangementsCrossCuttingPopHealthCarePathways',
                legacyKey: '30_10_20_20',
              },
              {
                title:
                  'Addressing barriers to implementation of pathways and services',
                key: 'healthSystemArrangementsCrossCuttingPopHealthImplementationBarriers',
                legacyKey: '30_10_20_30',
              },
              {
                title:
                  'Addressing cultural safety in the implementation of pathways and services',
                key: 'healthSystemArrangementsCrossCuttingPopHealthCulturalSafety',
                legacyKey: '30_10_20_40',
              },
              {
                title:
                  'Maintaining gains made in population-health management (e.g., population segmentation, virtual care) and spreading and scaling them',
                key: 'healthSystemArrangementsCrossCuttingPopHealthGains',
                legacyKey: '30_10_20_50',
              },
            ],
          },
        ],
      },
      {
        title: 'Delivery arrangements',
        key: 'healthSystemArrangementsDelivery',
        legacyKey: '30_20',
        children: [
          {
            title:
              'Overall service planning for COVID-19 preventing and treatment',
            key: 'healthSystemArrangementsDeliveryOverallServicePlanning',
            legacyKey: '30_20_10',
            children: [
              {
                title: 'Leveraging existing health-system arrangements',
                key: 'healthSystemArrangementsDeliveryOverallServicePlanningExisting',
                legacyKey: '30_20_10_10',
              },
            ],
          },
          {
            title: 'Service planning for COVID-19 prevention',
            key: 'healthSystemArrangementsDeliveryPrevention',
            legacyKey: '30_20_20',
            children: [
              {
                title:
                  'Using personal protective equipment (e.g., gowns, gloves, eye protection, etc.) in healthcare facilities',
                key: 'healthSystemArrangementsDeliveryPPE',
                legacyKey: '30_20_20_4',
              },
              {
                title:
                  'Promoting adherence to public-health support measures in healthcare settings',
                key: 'healthSystemArrangementsDeliveryAdherence',
                legacyKey: '30_20_20_7',
              },
              {
                title:
                  'Changing emergency-medical service procedures (ambulances, paramedics)',
                key: 'healthSystemArrangementsDeliveryPreventionEmergencyService',
                legacyKey: '30_20_20_10',
              },
              {
                title:
                  'Re-locating hospital-based ambulatory clinics, cancer treatments, etc.',
                key: 'healthSystemArrangementsDeliveryPreventionRelocation',
                legacyKey: '30_20_20_20',
              },
              {
                title: 'Limiting access to health facilities',
                key: 'healthSystemArrangementsDeliveryPreventionLimitingAccess',
                legacyKey: '30_20_20_30',
              },
              {
                title: 'Changing hospital-discharge procedures',
                key: 'healthSystemArrangementsDeliveryPreventionDischarge',
                legacyKey: '30_20_20_40',
              },
              {
                title: 'Changing long-term care procedures',
                key: 'healthSystemArrangementsDeliveryPreventionLongTermCare',
                legacyKey: '30_20_20_50',
              },
              {
                title: 'Changing home and community care procedures',
                key: 'healthSystemArrangementsDeliveryPreventionHomeCommunity',
                legacyKey: '30_20_20_60',
              },
              {
                title:
                  'Other infection prevention measures in healthcare facilities',
                key: 'healthSystemArrangementsDeliveryPreventionHealthcareFacilities',
                legacyKey: '30_20_20_70',
              },
            ],
          },
          {
            title: 'Service planning for COVID-19 diagnosis and treatment',
            key: 'healthSystemArrangementsDeliveryTreatment',
            legacyKey: '30_20_30',
            children: [
              {
                title: 'Maintaining essential health services',
                key: 'healthSystemArrangementsDeliveryTreatmentMaintainingEssentialServices',
                legacyKey: '30_20_30_130',
              },
              {
                title: 'Scaling up/down testing capacity',
                key: 'healthSystemArrangementsDeliveryTreatmentScalingTestingCapacity',
                legacyKey: '30_20_30_10',
              },
              {
                title: 'Scaling up/down emergency-room capacity',
                key: 'healthSystemArrangementsDeliveryTreatmentScalingERCapacity',
                legacyKey: '30_20_30_20',
              },
              {
                title: 'Scaling up/down ICU capacity',
                key: 'healthSystemArrangementsDeliveryTreatmentScalingICUCapacity',
                legacyKey: '30_20_30_30',
              },
              {
                title:
                  'Scaling up/down post-ICU recovery capacity (e.g., hospital beds)',
                key: 'healthSystemArrangementsDeliveryTreatmentScalingPostICUCapacity',
                legacyKey: '30_20_30_40',
              },
              {
                title: 'Scaling up/down palliative-care capacity',
                key: 'healthSystemArrangementsDeliveryTreatmentScalingPalliative',
                legacyKey: '30_20_30_50',
              },
              {
                title:
                  'Scaling up/down capacity to treat post COVID-19 conditions',
                key: 'healthSystemArrangementsDeliveryTreatmentScalingSequelae',
                legacyKey: '30_20_30_60',
              },
              {
                title:
                  'Scaling up/down capacity to manage the pandemic-related impacts on health more generally (e.g., mental health and addictions)',
                key: 'healthSystemArrangementsDeliveryTreatmentScalingImpacts',
                legacyKey: '30_20_30_70',
              },
              {
                title: 'Surge-management models',
                key: 'healthSystemArrangementsDeliveryTreatmentSurgeModels',
                legacyKey: '30_20_30_80',
              },
              {
                title: 'Triage protocols',
                key: 'healthSystemArrangementsDeliveryTreatmentTriageProtocols',
                legacyKey: '30_20_30_90',
              },
              {
                title: 'Handling dead bodies',
                key: 'healthSystemArrangementsDeliveryTreatmentDeadBodies',
                legacyKey: '30_20_30_120',
              },
            ],
          },
          {
            title:
              'Service planning for the ongoing management of other conditions',
            key: 'healthSystemArrangementsDeliveryOther',
            legacyKey: '30_20_40',
            children: [
              {
                title: 'Changing acute care surgery and trauma-care procedures',
                key: 'healthSystemArrangementsDeliveryOtherAcuteCare',
                legacyKey: '30_20_40_10',
              },
              {
                title: 'Changing cancer-treatment procedures',
                key: 'healthSystemArrangementsDeliveryOtherCancerTreatment',
                legacyKey: '30_20_40_20',
              },
              {
                title: 'Changing reproductive care procedures',
                key: 'healthSystemArrangementsDeliveryOtherReproductiveCare',
                legacyKey: '30_20_40_30',
              },
              {
                title: 'Delaying return visits, elective procedures, etc.',
                key: 'healthSystemArrangementsDeliveryOtherDelayingVisits',
                legacyKey: '30_20_40_40',
              },
              {
                title:
                  'Changing the management of other health conditions (e.g., infectious diseases)',
                key: 'healthSystemArrangementsDeliveryOtherChangingManagement',
                legacyKey: '30_20_40_50',
              },
            ],
          },
          {
            title: 'Infrastructure planning and resource allocation',
            key: 'healthSystemArrangementsDeliveryInfrastructure',
            legacyKey: '30_20_50',
            children: [
              {
                title:
                  'Personal protective equipment under shortage conditions (including N95 respirators for health workers)',
                key: 'healthSystemArrangementsDeliveryInfrastructurePPE',
                legacyKey: '30_20_50_10',
              },
              {
                title:
                  'Optimizing testing across different types of individuals, settings and timing options',
                key: 'healthSystemArrangementsDeliveryInfrastructureOptimizingTesting',
                legacyKey: '30_20_50_14',
              },
              {
                title: 'Ventilators for sick COVID-19 patients',
                key: 'healthSystemArrangementsDeliveryInfrastructureVentilators',
                legacyKey: '30_20_50_20',
              },
              {
                title:
                  'Medications and other technologies (under shortage conditions due to disrupted supply chains)',
                key: 'healthSystemArrangementsDeliveryInfrastructureMedications',
                legacyKey: '30_20_50_30',
              },
              {
                title: 'Remote monitoring',
                key: 'healthSystemArrangementsDeliveryInfrastructureRemoteMonitoring',
                legacyKey: '30_20_50_40',
              },
              {
                title: 'Virtual visits',
                key: 'healthSystemArrangementsDeliveryInfrastructureRemoteVirtualVisits',
                legacyKey: '30_20_50_50',
              },
            ],
          },
          {
            title:
              'Workforce planning (including workforce shortages management) and development',
            key: 'healthSystemArrangementsDeliveryWorkforcePlanning',
            legacyKey: '30_20_60',
            children: [
              {
                title: 'Recruitment',
                key: 'healthSystemArrangementsDeliveryWorkforcePlanningRecruitment',
                legacyKey: '30_20_60_10',
              },
              {
                title: 'Role extensions',
                key: 'healthSystemArrangementsDeliveryWorkforcePlanningRoleExtensions',
                legacyKey: '30_20_60_20',
              },
              {
                title: 'Training in new procedures',
                key: 'healthSystemArrangementsDeliveryWorkforcePlanningTraining',
                legacyKey: '30_20_60_30',
              },
              {
                title: 'Replacements when sick',
                key: 'healthSystemArrangementsDeliveryWorkforcePlanningReplacements',
                legacyKey: '30_20_60_40',
              },
              {
                title: 'Re-deployment',
                key: 'healthSystemArrangementsDeliveryWorkforcePlanninggRedeployment',
                legacyKey: '30_20_60_50',
              },
              {
                title: 'Supports to unpaid caregivers',
                key: 'healthSystemArrangementsDeliveryWorkforcePlanningCaregiverSupports',
                legacyKey: '30_20_60_60',
              },
              {
                title: 'Volunteer engagement',
                key: 'healthSystemArrangementsDeliveryWorkforcePlanningVolunteerEngagement',
                legacyKey: '30_20_60_70',
              },
              {
                title: 'Self-management supports',
                key: 'healthSystemArrangementsDeliveryWorkforcePlanningSelfManagement',
                legacyKey: '30_20_60_80',
              },
            ],
          },
          {
            title: 'Service planning for ‘return to normal’',
            key: 'healthSystemArrangementsDeliveryServicePlanning',
            legacyKey: '30_20_70',
            children: [
              {
                title:
                  'Sequencing of services re-starting, by sector, conditions, treatments (including diagnostics), and populations',
                key: 'healthSystemArrangementsDeliveryServicePlanningSequencing',
                legacyKey: '30_20_70_10',
              },
              {
                title: 'Wait-lists management',
                key: 'healthSystemArrangementsDeliveryServicePlanningWaitlists',
                legacyKey: '30_20_70_20',
              },
              {
                title:
                  'Other strategies to restore health services after COVID-19',
                key: 'healthSystemArrangementsDeliveryServiceOtherStrategiesRestore',
                legacyKey: '30_20_70_30',
              },
            ],
          },
        ],
      },
      {
        title: 'Financial arrangements',
        key: 'financialArrangements',
        legacyKey: '30_30',
        children: [
          {
            title: 'Financing health services',
            key: 'financialArrangementsFinancing',
            legacyKey: '30_30_10',
          },
          {
            title: 'Funding organizations',
            key: 'financialArrangementsFundingOrganizations',
            legacyKey: '30_30_20',
          },
          {
            title: 'Remunerating providers',
            key: 'financialArrangementsRemuneratingProviders',
            legacyKey: '30_30_30',
            children: [
              {
                title: 'New or adjusted fee codes for virtual care',
                key: 'financialArrangementsRemuneratingProvidersNewAdjustedFeeCodes',
                legacyKey: '30_30_30_10',
              },
              {
                title:
                  'Income replacement when virtual care is not possible (at the same scale)',
                key: 'financialArrangementsRemuneratingProvidersIncomeReplacement',
                legacyKey: '30_30_30_20',
              },
            ],
          },
          {
            title: 'Purchasing products and services',
            key: 'financialArrangementsProductsServices',
            legacyKey: '30_30_40',
          },
        ],
      },
      {
        title: 'Governance arrangements (who can make what decisions)',
        key: 'governanceArrangements',
        legacyKey: '30_40',
        children: [
          {
            title: 'Consumer and stakeholder involvement',
            key: 'governanceArrangementsConsumerStakeholder',
            legacyKey: '30_40_10',
          },
          {
            title: 'Professional authority',
            key: 'governanceArrangementsProfessionalAuthority',
            legacyKey: '30_40_20',
            children: [
              {
                title:
                  'Licensure changes to accommodate out-of-jurisdiction or retired health workers',
                key: 'governanceArrangementsProfessionalAuthorityHealthWorkers',
                legacyKey: '30_40_20_10',
              },
            ],
          },
          {
            title: 'Commercial authority',
            key: 'governanceArrangementsCommercialAuthority',
            legacyKey: '30_40_30',
            children: [
              {
                title: 'Technology approvals, public-private partnerships',
                key: 'governanceArrangementsCommercialAuthorityTechnologyApprovals',
                legacyKey: '30_40_30_10',
              },
            ],
          },
          {
            title: 'Organizational authority',
            key: 'governanceArrangementsOrganizationalAuthority',
            legacyKey: '30_40_40',
            children: [
              {
                title: 'Limits of number of staff sent in',
                key: 'governanceArrangementsOrganizationalAuthorityStaffLimits',
                legacyKey: '30_40_40_10',
              },
              {
                title: 'Ownership',
                key: 'governanceArrangementsOrganizationalAuthorityOwnership',
                legacyKey: '30_40_40_20',
              },
            ],
          },
          {
            title: 'Policy authority',
            key: 'governanceArrangementsPolicyAuthority',
            legacyKey: '30_40_50',
            children: [
              {
                title: 'Strengthening health-system governance',
                key: 'governanceArrangementsPolicyAuthorityGovernance',
                legacyKey: '30_40_50_10',
              },
              {
                title: 'Federal versus provincial',
                key: 'governanceArrangementsPolicyAuthorityFederalProvincial',
                legacyKey: '30_40_50_20',
              },
              {
                title: 'Adhering to the International Health Regulations',
                key: 'governanceArrangementsPolicyAuthorityInternationalAdherance',
                legacyKey: '30_40_50_30',
              },
            ],
          },
        ],
      },
    ],
  },
  checkedKeysEcoSoc: {
    title: 'Economic and social responses',
    legacyKey: '40',
    items: [
      {
        title: 'Children and youth services',
        key: 'childrenYouthServices',
        legacyKey: '40_10',
      },
      {
        title: 'Citizenship',
        key: 'citizenship',
        legacyKey: '40_20',
        children: [
          {
            title: 'Community engagement',
            key: 'citizenshipCommunityEngagement',
            legacyKey: '40_20_10',
          },
          {
            title: 'Civil-rights violations',
            key: 'citizenshipCivilRights',
            legacyKey: '40_20_20',
          },
          {
            title: 'Elections',
            key: 'citizenshipElections',
            legacyKey: '40_20_30',
          },
        ],
      },
      {
        title: 'Climate action',
        key: 'climateAction',
        legacyKey: '40_30',
        children: [
          {
            title: 'Climate-action focused economic stimulus',
            key: 'climateActionEconomicStimulus',
            legacyKey: '40_30_10',
          },
        ],
      },
      {
        title: 'Community and social services',
        key: 'communitySocialServices',
        legacyKey: '40_40',
        children: [
          {
            title:
              'Shopping and other services for socially isolated individuals',
            key: 'communitySocialServicesShopping',
            legacyKey: '40_40_10',
          },
          {
            title:
              'Religious services restrictions (e.g., church, mosque or synagogue)',
            key: 'communitySocialServicesReligious',
            legacyKey: '40_40_20',
          },
          {
            title: 'Supports for community resilience',
            key: 'communitySocialServicesSupports',
            legacyKey: '40_40_30',
          },
        ],
      },
      {
        title: 'Culture and gender',
        key: 'cultureGender',
        legacyKey: '40_50',
        children: [
          {
            title: 'Stigma reduction',
            key: 'cultureGenderStigmaReduction',
            legacyKey: '40_50_10',
          },
          {
            title: 'Domestic and gender-based violence reduction',
            key: 'cultureGenderViolenceReduction',
            legacyKey: '40_50_20',
          },
          {
            title: 'Arts and cultural institutions',
            key: 'cultureGenderArtsCultural',
            legacyKey: '40_50_30',
          },
          {
            title: 'Religious institutions and practices',
            key: 'cultureGenderReligious',
            legacyKey: '40_50_40',
          },
        ],
      },
      {
        title: 'Economic development and growth',
        key: 'economicDevelopment',
        legacyKey: '40_60',
        children: [
          {
            title: 'Economic resilience',
            key: 'economicDevelopmentResilience',
            legacyKey: '40_60_10',
          },
          {
            title: 'Targeted support to most affected industries',
            key: 'economicDevelopmentTargettedSupport',
            legacyKey: '40_60_20',
          },
          {
            title: 'Interest rate reductions',
            key: 'economicDevelopmentInterestRateReductions',
            legacyKey: '40_60_30',
          },
          {
            title: 'Interest-free or reduced loans to businesses',
            key: 'economicDevelopmentInterestFree',
            legacyKey: '40_60_40',
          },
          {
            title: 'Revolving credit lines',
            key: 'economicDevelopmentCreditLines',
            legacyKey: '40_60_50',
          },
          {
            title: 'Corporate bond buying (by government)',
            key: 'economicDevelopmentCorporateBondGov',
            legacyKey: '40_60_60',
          },
          {
            title: 'Government bond buying (by central banks)',
            key: 'economicDevelopmentGovernmentBondCentralBanks',
            legacyKey: '40_60_70',
          },
          {
            title: 'Rent relief for businesss (by government)',
            key: 'economicDevelopmentCorporateRentReliefGov',
            legacyKey: '40_60_80',
          },
          {
            title: 'Debt relief for business (by government)',
            key: 'economicDevelopmentCorporateDebtReliefGov',
            legacyKey: '40_60_90',
          },
          {
            title: 'Debt relief for governments (e.g. by IMF)',
            key: 'economicDevelopmentCorporateDebtReliefIMF',
            legacyKey: '40_60_100',
          },
          {
            title: 'Tax deferral for businesses',
            key: 'economicDevelopmentCorporateTaxDeferral',
            legacyKey: '40_60_110',
          },
          {
            title:
              'Measures to `return to normal` while minimizing economic impact',
            key: 'economicDevelopmentCorporateMeasuresReturnNormal',
            legacyKey: '40_60_120',
          },
        ],
      },
      {
        title: 'Education',
        key: 'education',
        legacyKey: '40_70',
        children: [
          {
            title: 'Online instruction',
            key: 'educationOnlineInstruction',
            legacyKey: '40_70_10',
          },
          {
            title: 'Student supports',
            key: 'educationStudentSupports',
            legacyKey: '40_70_20',
          },
          {
            title: 'Instructor supports',
            key: 'educationInstructorSupports',
            legacyKey: '40_70_30',
          },
          {
            title: 'Classroom changes',
            key: 'educationClassroomChanges',
            legacyKey: '40_70_40',
          },
          {
            title:
              'Educational facility changes (elementary and high-schools, universities, etc.)',
            key: 'educationSchoolChanges',
            legacyKey: '40_70_50',
          },
          {
            title: 'Skill re-development programs',
            key: 'educationSkillRedevelopment',
            legacyKey: '40_70_60',
          },
          {
            title: "Planning for 'return to normal'",
            key: 'educationServicePlanning',
            legacyKey: '40_70_70',
          },
        ],
      },
      {
        title: 'Employment',
        key: 'employment',
        legacyKey: '40_80',
        children: [
          {
            title: 'Worker supports',
            key: 'employmentWorkerSupports',
            legacyKey: '40_80_10',
          },
          {
            title: 'Workplace changes',
            key: 'employmentWorkplaceChanges',
            legacyKey: '40_80_20',
          },
          {
            title: 'Building changes',
            key: 'employmentBuildingChanges',
            legacyKey: '40_80_30',
          },
          {
            title: 'Medical leaves due to COVID-19 or post-COVID conditions',
            key: 'employmentMedicalLeaves',
            legacyKey: '40_80_34',
          },
          {
            title:
              "Planning for 'return to normal' (e.g., home-based, hybrid work or in-person)",
            key: 'employmentServicePlanning',
            legacyKey: '40_80_40',
          },
        ],
      },
      { title: 'Energy supply', key: 'energySupply', legacyKey: '40_90' },
      {
        title: 'Environmental conservation',
        key: 'environmentalConservation',
        legacyKey: '40_100',
        children: [
          {
            title:
              'Fire bans due to limitations in and risk for fire-fighting personnel',
            key: 'environmentalConservationFireBans',
            legacyKey: '40_100_10',
          },
        ],
      },
      {
        title: 'Financial protection',
        key: 'financialProtection',
        legacyKey: '40_110',
        children: [
          {
            title: 'Income replacement',
            key: 'financialProtectionIncomeReplacement',
            legacyKey: '40_110_10',
          },
          {
            title: 'Wage subsidies for essential workers',
            key: 'financialProtectionWageSubsidies',
            legacyKey: '40_110_20',
          },
          {
            title: 'Rent deferral for citizens',
            key: 'financialProtectionRentDeferral',
            legacyKey: '40_110_30',
          },
          {
            title: 'Debt relief for citizens',
            key: 'financialProtectionDebtRelief',
            legacyKey: '40_110_40',
          },
          {
            title: 'Tax deferral for citizens',
            key: 'financialProtectionTaxDeferral',
            legacyKey: '40_110_50',
          },
          {
            title: 'Financial-scam prevention',
            key: 'financialProtectionFinancialScam',
            legacyKey: '40_110_60',
          },
          {
            title: 'Broader consumer protection',
            key: 'financialProtectionConsumerProtection',
            legacyKey: '40_110_70',
          },
          {
            title:
              'Measures to `return to normal` while minimizing economic impact',
            key: 'financialProtectionConsumerMeasuresReturnNormal',
            legacyKey: '40_110_80',
          },
        ],
      },
      {
        title: 'Food safety and security',
        key: 'foodSafety',
        legacyKey: '40_120',
        children: [
          {
            title: 'Agricultural processes',
            key: 'foodSafetyAgriculturalProcesses',
            legacyKey: '40_120_10',
          },
          {
            title: 'Food processing plant design',
            key: 'foodSafetyProcessingPlantDesign',
            legacyKey: '40_120_20',
          },
          {
            title: 'Food transportation adjustments',
            key: 'foodSafetyTransportation',
            legacyKey: '40_120_30',
          },
          {
            title: 'Food shopping changes',
            key: 'foodSafetyShoppingChanges',
            legacyKey: '40_120_40',
          },
          {
            title: 'Household food security',
            key: 'foodSafetyHouseholdSecurity',
            legacyKey: '40_120_50',
          },
          {
            title: 'Food handling practices',
            key: 'foodSafetyHandlingPractices',
            legacyKey: '40_120_60',
          },
        ],
      },
      {
        title: 'Government services',
        key: 'governmentServices',
        legacyKey: '40_130',
        children: [
          {
            title: 'Transitioning to e-services',
            key: 'governmentServicesTransitionEServices',
            legacyKey: '40_130_10',
          },
          {
            title:
              'Service planning for `return to normal` (e.g., managing backlogs)',
            key: 'governmentServicesPlanningReturnNormal',
            legacyKey: '40_130_20',
          },
        ],
      },
      {
        title: 'Housing',
        key: 'housing',
        legacyKey: '40_140',
        children: [
          {
            title: 'Homeless shelters',
            key: 'housingHomelessShelters',
            legacyKey: '40_140_10',
          },
          {
            title: 'Other congregate living environments',
            key: 'housingOtherCongregateLiving',
            legacyKey: '40_140_20',
          },
          {
            title:
              'Housing alternatives when quarantine or physical distancing is needed',
            key: 'housingAlternatives',
            legacyKey: '40_140_30',
          },
        ],
      },
      {
        title: 'Infrastructure',
        key: 'infrastructure',
        legacyKey: '40_150',
        children: [
          {
            title: 'Broadband internet access',
            key: 'infrastructureBroadbandInternetAccess',
            legacyKey: '40_150_10',
          },
          {
            title:
              'Cyber-security protocols for governments and businesses (see financial protection for protecting citizens from financial scams)',
            key: 'infrastructureCyberSecurity',
            legacyKey: '40_150_20',
          },
          {
            title:
              'Green-space re-allocations to accommodate physical distancing',
            key: 'infrastructureGreenspace',
            legacyKey: '40_150_30',
          },
          {
            title:
              'Road-space re-allocations to accommodate physical distancing',
            key: 'infrastructureRoadSpace',
            legacyKey: '40_150_40',
          },
          {
            title: 'Domestic production capacity for critical supplies',
            key: 'infrastructureDomesticProduction',
            legacyKey: '40_150_50',
          },
        ],
      },
      {
        title: 'Natural resources',
        key: 'naturalResources',
        legacyKey: '40_160',
        children: [
          {
            title: 'Price collapses',
            key: 'naturalResourcesPriceCollapses',
            legacyKey: '40_160_10',
          },
          {
            title: 'Distribution difficulties',
            key: 'naturalResourcesDistributionDifficulties',
            legacyKey: '40_160_20',
          },
        ],
      },
      {
        title: 'Public safety and justice',
        key: 'publicSafetyJustice',
        legacyKey: '40_170',
        children: [
          {
            title: 'Curfews',
            key: 'publicSafetyJusticeCurfews',
            legacyKey: '40_170_10',
          },
          {
            title: 'Enforcement of public-health measures',
            key: 'publicSafetyJusticeEnforcement',
            legacyKey: '40_170_20',
          },
          {
            title: 'Public demonstrations',
            key: 'publicSafetyJusticePublicDemonstrations',
            legacyKey: '40_170_30',
          },
          {
            title: 'Police work in pandemics',
            key: 'publicSafetyJusticePoliceWork',
            legacyKey: '40_170_40',
          },
          {
            title: 'Prisons',
            key: 'publicSafetyJusticePrisons',
            legacyKey: '40_170_50',
          },
        ],
      },
      {
        title: 'Recreation',
        key: 'recreation',
        legacyKey: '40_180',
        children: [
          {
            title: 'Public spaces like parks',
            key: 'recreationPublicSpaces',
            legacyKey: '40_180_10',
          },
          {
            title: 'Private spaces like gyms',
            key: 'recreationPrivateSpaces',
            legacyKey: '40_180_20',
          },
        ],
      },
      {
        title: 'Transportation',
        key: 'transportation',
        legacyKey: '40_190',
        children: [
          {
            title: 'Quarantining travellers',
            key: 'transportationQuarantiningTravellers',
            legacyKey: '40_190_10',
          },
          {
            title: 'Public transportation rules',
            key: 'transportationPublicTransportationRules',
            legacyKey: '40_190_20',
          },
          {
            title: 'Private transportation restrictions, including air travel',
            key: 'transportationPrivateRestrictions',
            legacyKey: '40_190_30',
          },
          {
            title: "Tourism planning for 'return to normal'",
            key: 'transportationTourismPlanning',
            legacyKey: '40_190_40',
          },
        ],
      },
    ],
  },
  // countries: {
  //   title: 'Area of focus',
  //   legacyKey: '0_3',
  //   items: [
  //     {
  //       title: 'Countries',
  //       key: 'areaOfFocusCountries',
  //       legacyKey: '0_6',
  //       children: [
  //         {
  //           title: 'Afghanistan',
  //           key: 'countryAfghanistan',
  //           legacyKey: '6_1',
  //         },
  //         {
  //           title: 'Albania',
  //           key: 'countryAlbania',
  //           legacyKey: '6_2',
  //         },
  //         {
  //           title: 'Algeria',
  //           key: 'countryAlgeria',
  //           legacyKey: '6_3',
  //         },
  //         {
  //           title: 'Andorra',
  //           key: 'countryAndorra',
  //           legacyKey: '6_4',
  //         },
  //         {
  //           title: 'Angola',
  //           key: 'countryAngola',
  //           legacyKey: '6_5',
  //         },
  //         {
  //           title: 'Antigua and Barbuda',
  //           key: 'countryAntiguaAndBarbuda',
  //           legacyKey: '6_6',
  //         },
  //         {
  //           title: 'Argentina',
  //           key: 'countryArgentina',
  //           legacyKey: '6_7',
  //         },
  //         {
  //           title: 'Armenia',
  //           key: 'countryArmenia',
  //           legacyKey: '6_8',
  //         },
  //         {
  //           title: 'Australia',
  //           key: 'countryAustralia',
  //           legacyKey: '6_9',
  //         },
  //         {
  //           title: 'Austria',
  //           key: 'countryAustria',
  //           legacyKey: '6_10',
  //         },
  //         {
  //           title: 'Azerbaijan',
  //           key: 'countryAzerbaijan',
  //           legacyKey: '6_11',
  //         },
  //         {
  //           title: 'Bahamas',
  //           key: 'countryBahamas',
  //           legacyKey: '6_12',
  //         },
  //         {
  //           title: 'Bahrain',
  //           key: 'countryBahrain',
  //           legacyKey: '6_13',
  //         },
  //         {
  //           title: 'Bangladesh',
  //           key: 'countryBangladesh',
  //           legacyKey: '6_14',
  //         },
  //         {
  //           title: 'Barbados',
  //           key: 'countryBarbados',
  //           legacyKey: '6_15',
  //         },
  //         {
  //           title: 'Belarus',
  //           key: 'countryBelarus',
  //           legacyKey: '6_16',
  //         },
  //         {
  //           title: 'Belgium',
  //           key: 'countryBelgium',
  //           legacyKey: '6_17',
  //         },
  //         {
  //           title: 'Belize',
  //           key: 'countryBelize',
  //           legacyKey: '6_18',
  //         },
  //         {
  //           title: 'Benin',
  //           key: 'countryBenin',
  //           legacyKey: '6_19',
  //         },
  //         {
  //           title: 'Bhutan',
  //           key: 'countryBhutan',
  //           legacyKey: '6_20',
  //         },
  //         {
  //           title: 'Bolivia (Plurinational State of)',
  //           key: 'countryBolivia',
  //           legacyKey: '6_21',
  //         },
  //         {
  //           title: 'Bosnia and Herzegovina',
  //           key: 'countryBosniaAndHerzegovina',
  //           legacyKey: '6_22',
  //         },
  //         {
  //           title: 'Botswana',
  //           key: 'countryBotswana',
  //           legacyKey: '6_23',
  //         },
  //         {
  //           title: 'Brazil',
  //           key: 'countryBrazil',
  //           legacyKey: '6_24',
  //         },
  //         {
  //           title: 'Brunei Darussalam',
  //           key: 'countryBruneiDarussalam',
  //           legacyKey: '6_25',
  //         },
  //         {
  //           title: 'Bulgaria',
  //           key: 'countryBulgaria',
  //           legacyKey: '6_26',
  //         },
  //         {
  //           title: 'Burkina Faso',
  //           key: 'countryBurkinaFaso',
  //           legacyKey: '6_27',
  //         },
  //         {
  //           title: 'Burundi',
  //           key: 'countryBurundi',
  //           legacyKey: '6_28',
  //         },
  //         {
  //           title: 'Cambodia',
  //           key: 'countryCambodia',
  //           legacyKey: '6_29',
  //         },
  //         {
  //           title: 'Cameroon',
  //           key: 'countryCameroon',
  //           legacyKey: '6_30',
  //         },
  //         {
  //           title: 'Canada',
  //           key: 'countryCanada',
  //           legacyKey: '6_31',
  //         },
  //         {
  //           title: 'Cape Verde',
  //           key: 'countryCapeVerde',
  //           legacyKey: '6_32',
  //         },
  //         {
  //           title: 'Central African Republic',
  //           key: 'countryCentralAfricanRepublic',
  //           legacyKey: '6_33',
  //         },
  //         {
  //           title: 'Chad',
  //           key: 'countryChad',
  //           legacyKey: '6_34',
  //         },
  //         {
  //           title: 'Chile',
  //           key: 'countryChile',
  //           legacyKey: '6_35',
  //         },
  //         {
  //           title: 'China',
  //           key: 'countryChina',
  //           legacyKey: '6_36',
  //         },
  //         {
  //           title: 'Colombia',
  //           key: 'countryColombia',
  //           legacyKey: '6_37',
  //         },
  //         {
  //           title: 'Comoros',
  //           key: 'countryComoros',
  //           legacyKey: '6_38',
  //         },
  //         {
  //           title: 'Congo (Brazzaville)',
  //           key: 'countryCongo',
  //           legacyKey: '6_39',
  //         },
  //         {
  //           title: 'Costa Rica',
  //           key: 'countryCostaRica',
  //           legacyKey: '6_40',
  //         },
  //         {
  //           title: "Côte d'Ivoire",
  //           key: 'countryCotedIvoire',
  //           legacyKey: '6_41',
  //         },
  //         {
  //           title: 'Croatia',
  //           key: 'countryCroatia',
  //           legacyKey: '6_42',
  //         },
  //         {
  //           title: 'Cuba',
  //           key: 'countryCuba',
  //           legacyKey: '6_43',
  //         },
  //         {
  //           title: 'Cyprus',
  //           key: 'countryCyprus',
  //           legacyKey: '6_44',
  //         },
  //         {
  //           title: 'Czech Republic',
  //           key: 'countryCzechRepublic',
  //           legacyKey: '6_45',
  //         },
  //         {
  //           title: "Korea (Democratic People's Republic of)",
  //           key: 'countryKoreaDPR',
  //           legacyKey: '6_46',
  //         },
  //         {
  //           title: 'Congo (Democratic Republic of)',
  //           key: 'countryCongoDPR',
  //           legacyKey: '6_47',
  //         },
  //         {
  //           title: 'Denmark',
  //           key: 'countryDenmark',
  //           legacyKey: '6_48',
  //         },
  //         {
  //           title: 'Djibouti',
  //           key: 'countryDjibouti',
  //           legacyKey: '6_49',
  //         },
  //         {
  //           title: 'Dominica',
  //           key: 'countryDominica',
  //           legacyKey: '6_50',
  //         },
  //         {
  //           title: 'Dominican Republic',
  //           key: 'countryDominicanRepublic',
  //           legacyKey: '6_51',
  //         },
  //         {
  //           title: 'Ecuador',
  //           key: 'countryEcuador',
  //           legacyKey: '6_52',
  //         },
  //         {
  //           title: 'Egypt',
  //           key: 'countryEgypt',
  //           legacyKey: '6_53',
  //         },
  //         {
  //           title: 'El Salvador',
  //           key: 'countryElSalvador',
  //           legacyKey: '6_54',
  //         },
  //         {
  //           title: 'Equatorial Guinea',
  //           key: 'countryEquatorialGuinea',
  //           legacyKey: '6_55',
  //         },
  //         {
  //           title: 'Eritrea',
  //           key: 'countryEritrea',
  //           legacyKey: '6_56',
  //         },
  //         {
  //           title: 'Estonia',
  //           key: 'countryEstonia',
  //           legacyKey: '6_57',
  //         },
  //         {
  //           title: 'Ethiopia',
  //           key: 'countryEthiopia',
  //           legacyKey: '6_58',
  //         },
  //         {
  //           title: 'Fiji',
  //           key: 'countryFiji',
  //           legacyKey: '6_59',
  //         },
  //         {
  //           title: 'Finland',
  //           key: 'countryFinland',
  //           legacyKey: '6_60',
  //         },
  //         {
  //           title: 'France',
  //           key: 'countryFrance',
  //           legacyKey: '6_61',
  //         },
  //         {
  //           title: 'Gabon',
  //           key: 'countryGabon',
  //           legacyKey: '6_62',
  //         },
  //         {
  //           title: 'Gambia',
  //           key: 'countryGambia',
  //           legacyKey: '6_63',
  //         },
  //         {
  //           title: 'Georgia',
  //           key: 'countryGeorgia',
  //           legacyKey: '6_64',
  //         },
  //         {
  //           title: 'Germany',
  //           key: 'countryGermany',
  //           legacyKey: '6_65',
  //         },
  //         {
  //           title: 'Ghana',
  //           key: 'countryGhana',
  //           legacyKey: '6_66',
  //         },
  //         {
  //           title: 'Greece',
  //           key: 'countryGreece',
  //           legacyKey: '6_67',
  //         },
  //         {
  //           title: 'Grenada',
  //           key: 'countryGrenada',
  //           legacyKey: '6_68',
  //         },
  //         {
  //           title: 'Guatemala',
  //           key: 'countryGuatemala',
  //           legacyKey: '6_69',
  //         },
  //         {
  //           title: 'Guinea',
  //           key: 'countryGuinea',
  //           legacyKey: '6_70',
  //         },
  //         {
  //           title: 'Guinea-Bissau',
  //           key: 'countryGuineaBissau',
  //           legacyKey: '6_71',
  //         },
  //         {
  //           title: 'Guyana',
  //           key: 'countryGuyana',
  //           legacyKey: '6_72',
  //         },
  //         {
  //           title: 'Haiti',
  //           key: 'countryHaiti',
  //           legacyKey: '6_73',
  //         },
  //         {
  //           title: 'Honduras',
  //           key: 'countryHonduras',
  //           legacyKey: '6_74',
  //         },
  //         {
  //           title: 'Hungary',
  //           key: 'countryHungary',
  //           legacyKey: '6_75',
  //         },
  //         {
  //           title: 'Iceland',
  //           key: 'countryIceland',
  //           legacyKey: '6_76',
  //         },
  //         {
  //           title: 'India',
  //           key: 'countryIndia',
  //           legacyKey: '6_77',
  //         },
  //         {
  //           title: 'Indonesia',
  //           key: 'countryIndonesia',
  //           legacyKey: '6_78',
  //         },
  //         {
  //           title: 'Iran (Islamic Republic of)',
  //           key: 'countryIran',
  //           legacyKey: '6_79',
  //         },
  //         {
  //           title: 'Iraq',
  //           key: 'countryIraq',
  //           legacyKey: '6_80',
  //         },
  //         {
  //           title: 'Ireland',
  //           key: 'countryIreland',
  //           legacyKey: '6_81',
  //         },
  //         {
  //           title: 'Israel',
  //           key: 'countryIsrael',
  //           legacyKey: '6_82',
  //         },
  //         {
  //           title: 'Italy',
  //           key: 'countryItaly',
  //           legacyKey: '6_83',
  //         },
  //         {
  //           title: 'Jamaica',
  //           key: 'countryJamaica',
  //           legacyKey: '6_84',
  //         },
  //         {
  //           title: 'Japan',
  //           key: 'countryJapan',
  //           legacyKey: '6_85',
  //         },
  //         {
  //           title: 'Jordan',
  //           key: 'countryJordan',
  //           legacyKey: '6_86',
  //         },
  //         {
  //           title: 'Kazakhstan',
  //           key: 'countryKazakhstan',
  //           legacyKey: '6_87',
  //         },
  //         {
  //           title: 'Kenya',
  //           key: 'countryKenya',
  //           legacyKey: '6_88',
  //         },
  //         {
  //           title: 'Kiribati',
  //           key: 'countryKiribati',
  //           legacyKey: '6_89',
  //         },
  //         {
  //           title: 'Kuwait',
  //           key: 'countryKuwait',
  //           legacyKey: '6_90',
  //         },
  //         {
  //           title: 'Kyrgyzstan',
  //           key: 'countryKyrgyzstan',
  //           legacyKey: '6_91',
  //         },
  //         {
  //           title: "Lao People's Democratic Republic",
  //           key: 'countryLao',
  //           legacyKey: '6_92',
  //         },
  //         {
  //           title: 'Latvia',
  //           key: 'countryLatvia',
  //           legacyKey: '6_93',
  //         },
  //         {
  //           title: 'Lebanon',
  //           key: 'countryLebanon',
  //           legacyKey: '6_94',
  //         },
  //         {
  //           title: 'Lesotho',
  //           key: 'countryLesotho',
  //           legacyKey: '6_95',
  //         },
  //         {
  //           title: 'Liberia',
  //           key: 'countryLiberia',
  //           legacyKey: '6_96',
  //         },
  //         {
  //           title: 'Libya',
  //           key: 'countryLibya',
  //           legacyKey: '6_97',
  //         },
  //         {
  //           title: 'Liechtenstein',
  //           key: 'countryLiechtenstein',
  //           legacyKey: '6_98',
  //         },
  //         {
  //           title: 'Lithuania',
  //           key: 'countryLithuania',
  //           legacyKey: '6_99',
  //         },
  //         {
  //           title: 'Luxembourg',
  //           key: 'countryLuxembourg',
  //           legacyKey: '6_100',
  //         },
  //         {
  //           title: 'Madagascar',
  //           key: 'countryMadagascar',
  //           legacyKey: '6_101',
  //         },
  //         {
  //           title: 'Malawi',
  //           key: 'countryMalawi',
  //           legacyKey: '6_102',
  //         },
  //         {
  //           title: 'Malaysia',
  //           key: 'countryMalaysia',
  //           legacyKey: '6_103',
  //         },
  //         {
  //           title: 'Maldives',
  //           key: 'countryMaldives',
  //           legacyKey: '6_104',
  //         },
  //         {
  //           title: 'Mali',
  //           key: 'countryMali',
  //           legacyKey: '6_105',
  //         },
  //         {
  //           title: 'Malta',
  //           key: 'countryMalta',
  //           legacyKey: '6_106',
  //         },
  //         {
  //           title: 'Marshall Islands',
  //           key: 'countryMarshallIslands',
  //           legacyKey: '6_107',
  //         },
  //         {
  //           title: 'Mauritania',
  //           key: 'countryMauritania',
  //           legacyKey: '6_108',
  //         },
  //         {
  //           title: 'Mauritius',
  //           key: 'countryMauritius',
  //           legacyKey: '6_109',
  //         },
  //         {
  //           title: 'Mexico',
  //           key: 'countryMexico',
  //           legacyKey: '6_110',
  //         },
  //         {
  //           title: 'Micronesia (Federated States of)',
  //           key: 'countryMicronesia',
  //           legacyKey: '6_111',
  //         },
  //         {
  //           title: 'Monaco',
  //           key: 'countryMonaco',
  //           legacyKey: '6_112',
  //         },
  //         {
  //           title: 'Mongolia',
  //           key: 'countryMongolia',
  //           legacyKey: '6_113',
  //         },
  //         {
  //           title: 'Montenegro',
  //           key: 'countryMontenegro',
  //           legacyKey: '6_114',
  //         },
  //         {
  //           title: 'Morocco',
  //           key: 'countryMorocco',
  //           legacyKey: '6_115',
  //         },
  //         {
  //           title: 'Mozambique',
  //           key: 'countryMozambique',
  //           legacyKey: '6_116',
  //         },
  //         {
  //           title: 'Myanmar',
  //           key: 'countryMyanmar',
  //           legacyKey: '6_117',
  //         },
  //         {
  //           title: 'Namibia',
  //           key: 'countryNamibia',
  //           legacyKey: '6_118',
  //         },
  //         {
  //           title: 'Nauru',
  //           key: 'countryNauru',
  //           legacyKey: '6_119',
  //         },
  //         {
  //           title: 'Nepal',
  //           key: 'countryNepal',
  //           legacyKey: '6_120',
  //         },
  //         {
  //           title: 'Netherlands',
  //           key: 'countryNetherlands',
  //           legacyKey: '6_121',
  //         },
  //         {
  //           title: 'New Zealand',
  //           key: 'countryNewZealand',
  //           legacyKey: '6_122',
  //         },
  //         {
  //           title: 'Nicaragua',
  //           key: 'countryNicaragua',
  //           legacyKey: '6_123',
  //         },
  //         {
  //           title: 'Niger',
  //           key: 'countryNiger',
  //           legacyKey: '6_124',
  //         },
  //         {
  //           title: 'Nigeria',
  //           key: 'countryNigeria',
  //           legacyKey: '6_125',
  //         },
  //         {
  //           title: 'Norway',
  //           key: 'countryNorway',
  //           legacyKey: '6_126',
  //         },
  //         {
  //           title: 'Oman',
  //           key: 'countryOman',
  //           legacyKey: '6_127',
  //         },
  //         {
  //           title: 'Pakistan',
  //           key: 'countryPakistan',
  //           legacyKey: '6_128',
  //         },
  //         {
  //           title: 'Palau',
  //           key: 'countryPalau',
  //           legacyKey: '6_129',
  //         },
  //         {
  //           title: 'Panama',
  //           key: 'countryPanama',
  //           legacyKey: '6_130',
  //         },
  //         {
  //           title: 'Papua New Guinea',
  //           key: 'countryPapuaNewGuinea',
  //           legacyKey: '6_131',
  //         },
  //         {
  //           title: 'Paraguay',
  //           key: 'countryParaguay',
  //           legacyKey: '6_132',
  //         },
  //         {
  //           title: 'Peru',
  //           key: 'countryPeru',
  //           legacyKey: '6_133',
  //         },
  //         {
  //           title: 'Philippines',
  //           key: 'countryPhilippines',
  //           legacyKey: '6_134',
  //         },
  //         {
  //           title: 'Poland',
  //           key: 'countryPoland',
  //           legacyKey: '6_135',
  //         },
  //         {
  //           title: 'Portugal',
  //           key: 'countryPortugal',
  //           legacyKey: '6_136',
  //         },
  //         {
  //           title: 'Qatar',
  //           key: 'countryQatar',
  //           legacyKey: '6_137',
  //         },
  //         {
  //           title: 'Korea (Republic of)',
  //           key: 'countryKorea',
  //           legacyKey: '6_138',
  //         },
  //         {
  //           title: 'Moldova (Republic of)',
  //           key: 'countryMoldova',
  //           legacyKey: '6_139',
  //         },
  //         {
  //           title: 'Romania',
  //           key: 'countryRomania',
  //           legacyKey: '6_140',
  //         },
  //         {
  //           title: 'Russian Federation',
  //           key: 'countryRussianFederation',
  //           legacyKey: '6_141',
  //         },
  //         {
  //           title: 'Rwanda',
  //           key: 'countryRwanda',
  //           legacyKey: '6_142',
  //         },
  //         {
  //           title: 'Saint Kitts and Nevis',
  //           key: 'countrySaintKittsAndNevis',
  //           legacyKey: '6_143',
  //         },
  //         {
  //           title: 'Saint Lucia',
  //           key: 'countrySaintLucia',
  //           legacyKey: '6_144',
  //         },
  //         {
  //           title: 'Saint Vincent and the Grenadines',
  //           key: 'countrySaintVincentAndTheGrenadines',
  //           legacyKey: '6_145',
  //         },
  //         {
  //           title: 'Samoa',
  //           key: 'countrySamoa',
  //           legacyKey: '6_146',
  //         },
  //         {
  //           title: 'San Marino',
  //           key: 'countrySanMarino',
  //           legacyKey: '6_147',
  //         },
  //         {
  //           title: 'Sao Tome and Principe',
  //           key: 'countrySaoTomeAndPrincipe',
  //           legacyKey: '6_148',
  //         },
  //         {
  //           title: 'Saudi Arabia',
  //           key: 'countrySaudiArabia',
  //           legacyKey: '6_149',
  //         },
  //         {
  //           title: 'Senegal',
  //           key: 'countrySenegal',
  //           legacyKey: '6_150',
  //         },
  //         {
  //           title: 'Serbia',
  //           key: 'countrySerbia',
  //           legacyKey: '6_151',
  //         },
  //         {
  //           title: 'Seychelles',
  //           key: 'countrySeychelles',
  //           legacyKey: '6_152',
  //         },
  //         {
  //           title: 'Sierra Leone',
  //           key: 'countrySierraLeone',
  //           legacyKey: '6_153',
  //         },
  //         {
  //           title: 'Singapore',
  //           key: 'countrySingapore',
  //           legacyKey: '6_154',
  //         },
  //         {
  //           title: 'Slovakia',
  //           key: 'countrySlovakia',
  //           legacyKey: '6_155',
  //         },
  //         {
  //           title: 'Slovenia',
  //           key: 'countrySlovenia',
  //           legacyKey: '6_156',
  //         },
  //         {
  //           title: 'Solomon Islands',
  //           key: 'countrySolomonIslands',
  //           legacyKey: '6_157',
  //         },
  //         {
  //           title: 'Somalia',
  //           key: 'countrySomalia',
  //           legacyKey: '6_158',
  //         },
  //         {
  //           title: 'South Africa',
  //           key: 'countrySouthAfrica',
  //           legacyKey: '6_159',
  //         },
  //         {
  //           title: 'Spain',
  //           key: 'countrySpain',
  //           legacyKey: '6_160',
  //         },
  //         {
  //           title: 'Sri Lanka',
  //           key: 'countrySriLanka',
  //           legacyKey: '6_161',
  //         },
  //         {
  //           title: 'Suriname',
  //           key: 'countrySuriname',
  //           legacyKey: '6_163',
  //         },
  //         {
  //           title: 'Swaziland',
  //           key: 'countrySwaziland',
  //           legacyKey: '6_164',
  //         },
  //         {
  //           title: 'Switzerland',
  //           key: 'countrySwitzerland',
  //           legacyKey: '6_165',
  //         },
  //         {
  //           title: 'Sweden',
  //           key: 'countrySweden',
  //           legacyKey: '6_166',
  //         },
  //         {
  //           title: 'Syrian Arab Republic',
  //           key: 'countrySyrianArabRepublic',
  //           legacyKey: '6_167',
  //         },
  //         {
  //           title: 'Tajikistan',
  //           key: 'countryTajikistan',
  //           legacyKey: '6_168',
  //         },
  //         {
  //           title: 'Thailand',
  //           key: 'countryThailand',
  //           legacyKey: '6_169',
  //         },
  //         {
  //           title: 'Macedonia (The former Yugoslav Republic of)',
  //           key: 'countryMacedonia',
  //           legacyKey: '6_170',
  //         },
  //         {
  //           title: 'Timor-Leste',
  //           key: 'countryTimorLeste',
  //           legacyKey: '6_171',
  //         },
  //         {
  //           title: 'Togo',
  //           key: 'countryTogo',
  //           legacyKey: '6_172',
  //         },
  //         {
  //           title: 'Tonga',
  //           key: 'countryTonga',
  //           legacyKey: '6_173',
  //         },
  //         {
  //           title: 'Trinidad and Tobago',
  //           key: 'countryTrinidadAndTobago',
  //           legacyKey: '6_174',
  //         },
  //         {
  //           title: 'Tunisia',
  //           key: 'countryTunisia',
  //           legacyKey: '6_175',
  //         },
  //         {
  //           title: 'Turkey',
  //           key: 'countryTurkey',
  //           legacyKey: '6_176',
  //         },
  //         {
  //           title: 'Turkmenistan',
  //           key: 'countryTurkmenistan',
  //           legacyKey: '6_177',
  //         },
  //         {
  //           title: 'Tuvalu',
  //           key: 'countryTuvalu',
  //           legacyKey: '6_178',
  //         },
  //         {
  //           title: 'Uganda',
  //           key: 'countryUganda',
  //           legacyKey: '6_179',
  //         },
  //         {
  //           title: 'Ukraine',
  //           key: 'countryUkraine',
  //           legacyKey: '6_180',
  //         },
  //         {
  //           title: 'United Arab Emirates',
  //           key: 'countryUnitedArabEmirates',
  //           legacyKey: '6_181',
  //         },
  //         {
  //           title: 'United Kingdom',
  //           key: 'countryUnitedKingdom',
  //           legacyKey: '6_182',
  //         },
  //         {
  //           title: 'Tanzania (United Republic of)',
  //           key: 'countryTanzania',
  //           legacyKey: '6_183',
  //         },
  //         {
  //           title: 'United States',
  //           key: 'countryUnitedStates',
  //           legacyKey: '6_184',
  //         },
  //         {
  //           title: 'Uruguay',
  //           key: 'countryUruguay',
  //           legacyKey: '6_185',
  //         },
  //         {
  //           title: 'Uzbekistan',
  //           key: 'countryUzbekistan',
  //           legacyKey: '6_186',
  //         },
  //         {
  //           title: 'Vanuatu',
  //           key: 'countryVanuatu',
  //           legacyKey: '6_187',
  //         },
  //         {
  //           title: 'Venezuela (Bolivarian Republic of)',
  //           key: 'countryVenezuela',
  //           legacyKey: '6_188',
  //         },
  //         {
  //           title: 'Viet Nam',
  //           key: 'countryVietNam',
  //           legacyKey: '6_189',
  //         },
  //         {
  //           title: 'Yemen',
  //           key: 'countryYemen',
  //           legacyKey: '6_190',
  //         },
  //         {
  //           title: 'Zambia',
  //           key: 'countryZambia',
  //           legacyKey: '6_191',
  //         },
  //         {
  //           title: 'Zimbabwe',
  //           key: 'countryZimbabwe',
  //           legacyKey: '6_192',
  //         },
  //         {
  //           title: 'Bermuda',
  //           key: 'countryBermuda',
  //           legacyKey: '6_193',
  //         },
  //         {
  //           title: 'French Polynesia',
  //           key: 'countryFrenchPolynesia',
  //           legacyKey: '6_194',
  //         },
  //         {
  //           title: 'Hong Kong',
  //           key: 'countryHongKong',
  //           legacyKey: '6_195',
  //         },
  //         {
  //           title: 'Kosovo',
  //           key: 'countryKosovo',
  //           legacyKey: '6_196',
  //         },
  //         {
  //           title: 'Northern Mariana Islands',
  //           key: 'countryNorthernMarianaIslands',
  //           legacyKey: '6_197',
  //         },
  //         {
  //           title: 'Palestine',
  //           key: 'countryPalestine',
  //           legacyKey: '6_198',
  //         },
  //         {
  //           title: 'Puerto Rico',
  //           key: 'countryPuertoRico',
  //           legacyKey: '6_199',
  //         },
  //         {
  //           title: 'Taiwan',
  //           key: 'countryTaiwan',
  //           legacyKey: '6_200',
  //         },
  //         {
  //           title: 'Anguilla',
  //           key: 'countryAnguilla',
  //           legacyKey: '6_253',
  //         },
  //         {
  //           title: 'Cook Islands',
  //           key: 'countryCookIslands',
  //           legacyKey: '6_255',
  //         },
  //         {
  //           title: 'Niue',
  //           key: 'countryNiue',
  //           legacyKey: '6_259',
  //         },
  //         {
  //           title: 'South Sudan',
  //           key: 'countrySouthSudan',
  //           legacyKey: '6_266',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Country groupings',
  //       key: 'areaOfFocusCountryGroupings',
  //       legacyKey: '0_7',
  //       children: [
  //         {
  //           title: 'ASEAN',
  //           key: 'areaOfFocusCountryGroupingsASEAN',
  //           legacyKey: '7_267',
  //         },
  //         {
  //           title: 'BRICS',
  //           key: 'areaOfFocusCountryGroupingsBRICS',
  //           legacyKey: '7_268',
  //         },
  //         {
  //           title: 'CARICOM',
  //           key: 'areaOfFocusCountryGroupingsCARICOM',
  //           legacyKey: '7_269',
  //         },
  //         {
  //           title: 'Commonwealth',
  //           key: 'areaOfFocusCountryGroupingsCommonwealth',
  //           legacyKey: '7_270',
  //         },
  //         {
  //           title: 'Francophonie',
  //           key: 'areaOfFocusCountryGroupingsFrancophonie',
  //           legacyKey: '7_271',
  //         },
  //         {
  //           title: 'G7',
  //           key: 'areaOfFocusCountryGroupingsG7',
  //           legacyKey: '7_272',
  //         },
  //         {
  //           title: 'G20',
  //           key: 'areaOfFocusCountryGroupingsG20',
  //           legacyKey: '7_273',
  //         },
  //         {
  //           title: 'EFTA',
  //           key: 'areaOfFocusCountryGroupingsEFTA',
  //           legacyKey: '7_274',
  //         },
  //         {
  //           title: 'European Union',
  //           key: 'areaOfFocusCountryGroupingsEU',
  //           legacyKey: '7_275',
  //         },
  //         {
  //           title: 'OPEC',
  //           key: 'areaOfFocusCountryGroupingsOPEC',
  //           legacyKey: '7_276',
  //         },
  //         {
  //           title: 'SAARC',
  //           key: 'areaOfFocusCountryGroupingsSAARC',
  //           legacyKey: '7_277',
  //         },
  //         {
  //           title: 'OECD',
  //           key: 'areaOfFocusCountryGroupingsOECD',
  //           legacyKey: '7_221',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'WHO regions',
  //       key: 'areaOfFocusWhoRegions',
  //       legacyKey: '0_8',
  //       children: [
  //         {
  //           title: 'WHO - African region',
  //           key: 'areaOfFocusWhoRegionAfrican',
  //           legacyKey: '8_1',
  //         },
  //         {
  //           title: 'WHO - Americas region',
  //           key: 'areaOfFocusWhoRegionAmericas',
  //           legacyKey: '8_2',
  //         },
  //         {
  //           title: 'WHO - Eastern Mediterranean region',
  //           key: 'areaOfFocusWhoRegionEasternMediterranean',
  //           legacyKey: '8_4',
  //         },
  //         {
  //           title: 'WHO - South East Asia region',
  //           key: 'areaOfFocusWhoRegionSouthEastAsia',
  //           legacyKey: '8_8',
  //         },
  //         {
  //           title: 'WHO - Western Pacific region',
  //           key: 'areaOfFocusWhoRegionWesternPacific',
  //           legacyKey: '8_16',
  //         },
  //         {
  //           title: 'WHO - European region',
  //           key: 'areaOfFocusWhoRegionEuropean',
  //           legacyKey: '8_32',
  //         },
  //       ],
  //     },
  //   ],
  // },
  features: {
    title: 'Document features',
    legacyKey: '0_4',
    items: [
      {
        title: 'Living',
        key: 'docFeatureLiving',
        legacyKey: '0_99',
      },
      {
        title: 'Type',
        key: 'docFeatureType',
        legacyKey: '0_10',
        children: [
          {
            title: 'Full synthesis',
            key: 'docFeatureTypeFullReview',
            legacyKey: '10_1',
          },
          {
            title: 'Rapid synthesis',
            key: 'docFeatureTypeRapidReview',
            legacyKey: '10_2',
          },
          {
            title: 'Protocol',
            key: 'docFeatureTypeProtocol',
            legacyKey: '10_3',
          },
          {
            title: 'Other',
            key: 'docFeatureTypeOther',
            legacyKey: '10_4',
          },
        ],
      },
      {
        title: 'Free full-text',
        key: 'docFeatureFreeFullText',
        legacyKey: '0_11',
        children: [
          {
            title: 'Limit to records with free full-text link',
            key: 'docFeatureTypeFreeFullText',
            legacyKey: '0_27',
          },
        ],
      },
      {
        title: 'Date range',
        key: 'docFeatureDateRange',
        legacyKey: '0_12',
        type: 'dateRange',
      },
    ],
  },
};

export default {
  types,
  questionTypes,
  tree,
};
