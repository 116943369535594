import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ArticleService from '../services/ArticleService';
import Context from './Context';
import _ from 'underscore';
import useQuerystring from './useQueryString';
import { formatLastLitDate } from '../utils/date';

const joinList = (array) => {
  if (array.length <= 1) {
    return array;
  }
  let lastItem = _.last(array);
  return array.slice(0, -1).join(', ') + ' and ' + lastItem;
};

const Links = (name, links, _language) => {
  if (_.isUndefined(links) || (links && links.length === 0)) {
    return null;
  }

  return (
    <ul>
      {links.map((link, i) => (
        <li key={`${name}-${i}`} className={`${name}-item`}>
          <a rel="noreferrer" href={link.url} target="_blank">
            {link.title}
          </a>
        </li>
      ))}
    </ul>
  );
};

const ArticleField = ({ visible = true, children }) => {
  return visible ? <div className="article-field">{children}</div> : null;
};

const CountryAuthorLinks = ({ items, _language, visible }) => {
  if (items.length === 0 || !visible) {
    return <div></div>;
  }
  return (
    <ul className="article-item-region-list">
      {items.map((item) => {
        if (item.value.links.length === 0) {
          return <span />;
        }
        return (
          <li key={item.key} className="region-item">
            <span className="region-item-title">{item.key}:</span>
            <ul className="article-author-list">
              {item.value.links.map((author, i) => (
                <li key={`author-item-${i}`} className="author-item">
                  <a rel="noreferrer" href={author.url} target="_blank">
                    {author.name}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};

const CountryLinks = ({ docType, items, t }) => {
  if (items.length === 0) {
    return <div>{t('articles_page.no_studies_conducted_in')}</div>;
  }
  return (
    <ul className="article-item-countries">
      {items.map((item) => {
        if (['Economic evaluations and costing studies'].includes(docType)) {
          return (
            <li key={item.key} className="country-item">
              <span>{item.key}</span>
            </li>
          );
        } else {
          return (
            <li key={item.key} className="country-item">
              <span>
                {item.key} ({item.value.count})
              </span>
            </li>
          );
        }
      })}
    </ul>
  );
};

const CountryGroups = (docType, country_groupings) => {
  if (country_groupings.length === 0) {
    return null;
  }
  return joinList(
    country_groupings.map((group) => {
      if (['Economic evaluations and costing studies'].includes(docType)) {
        return `${group.key}`;
      } else {
        return `${group.key} (${group.value})`;
      }
    })
  );
};

const NestedList = (list, listName, itemName) => {
  let result = [];

  if (list === null || list.length === 0) {
    return null;
  }

  list.forEach((item, i) => {
    result.push(
      <li key={`${itemName}-item-${i}`} className={`${itemName}-item`}>
        {item.title}
      </li>
    );
    if (item[listName] && item[listName].length > 0) {
      result.push(
        <li key={`${itemName}-item-nested-${i}`} className={`${itemName}-item`}>
          <ul>{NestedList(item[listName], listName, itemName)}</ul>
        </li>
      );
    }
  });

  return result;
};

const RelatedArticles = ({ article, t }) => (
  <ArticleField visible={article.related_documents_visible}>
    <Link
      className="btn btn-primary"
      to={{
        pathname: '/search',
        related_article_id: article.id,
      }}
    >
      {t('articles_page.related_documents')}
    </Link>
  </ArticleField>
);

const ArticleDetail = ({ id, site, language, t }) => {
  const [lang, setLang] = useQuerystring('lang', language || 'en');
  const [article, setArticle] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await ArticleService().get(id, site, lang);
      if (result) {
        setArticle(result);
        setError(false);
      } else {
        setError(true);
      }
    };
    fetchData();
  }, [id, site, lang]);

  useEffect(() => {
    setLang(language);
  }, [language]);

  const getQuality = (article) => {
    return article.quality
      ? `${article.quality} (${article.quality_note})`
      : t('articles_page.no_last_year_literature_searched');
  };

  const getAbstract = (article) => {
    return article.abstract || t('articles_page.no_topics');
  };

  const listItem = (elem) => {
    return <li>{elem}</li>;
  };

  const divItem = (elem) => {
    return <div>{elem}</div>;
  };

  return error ? (
    <div className="page-not-found">
      <h1>404 Page not found</h1>
      <p>Oops, looks like we could not find the page your are looking for.</p>
      <p>Try the links above to find what you need.</p>
    </div>
  ) : (
    article && (
      <div className="article-page">
        <div className="article-item">
          <h1>{article.title}</h1>
          <div className="article-item-body">
            <div className="section">
              <ArticleField>
                <h2>{article.label_document_type}</h2>
                <ul className="article-item-document-type">
                  <li>{article.documentType}</li>
                </ul>
              </ArticleField>

              <ArticleField visible={article.year_published_visible}>
                <h2>{article.label_year_published}</h2>
                <div>{article.published}</div>
              </ArticleField>

              <ArticleField
                visible={article.last_year_literature_searched_visible}
              >
                <h2>{article.label_last_year_literature_searched}</h2>
                <div>{formatLastLitDate(t, article)}</div>
              </ArticleField>

              <ArticleField visible={article.quality_rating_visible}>
                <h2>{article.label_quality_rating}</h2>
                <div>{getQuality(article)}</div>
              </ArticleField>

              <ArticleField visible={article.declarative_title_visible}>
                <h2>{article.label_declarative_title}</h2>
                <div>{article.declarative_title}</div>
              </ArticleField>

              <ArticleField visible={article.studies_conducted_in_visible}>
                <h2>{article.label_studies_conducted_in}</h2>
                <CountryLinks
                  docType={article.documentType}
                  items={article.country_links}
                  t={t}
                />
                <CountryAuthorLinks
                  items={article.country_links}
                  language={lang}
                  visible={article.country_author_links_visible}
                />
              </ArticleField>

              <ArticleField>
                <h2>{article.label_abstract}</h2>
                <div>{getAbstract(article)}</div>
              </ArticleField>

              <ArticleField visible={article.priority_area_visible}>
                <h2>{article.label_priority_areas}</h2>
                <ul>
                  {NestedList(
                    article.priority_areas,
                    'priority_areas',
                    'priority_area'
                  ) || listItem(t('articles_page.no_priority_areas'))}
                </ul>
              </ArticleField>
            </div>

            <div className="section">
              <ArticleField visible={article.health_systems_topics_visible}>
                <h2>{article.label_health_system_topics}</h2>
                <ul>
                  {NestedList(
                    article.health_systems_topic,
                    'children',
                    'health_systems_topic'
                  ) || listItem(t('articles_page.no_topics'))}
                </ul>
              </ArticleField>
              <ArticleField visible={article.domains_visible}>
                <h2>{article.label_domains}</h2>
                <ul>
                  {NestedList(article.domains, 'children', 'domain') ||
                    listItem(t('articles_page.no_domains'))}
                </ul>
              </ArticleField>
            </div>

            <div className="section">
              <ArticleField visible={article.themes_visible}>
                <h2>{article.label_themes}</h2>
                <ul>
                  {NestedList(article.themes, 'themes', 'theme') ||
                    listItem(t('articles_page.no_themes'))}
                </ul>
              </ArticleField>

              <ArticleField visible={article.country_groupings_visible}>
                <h2>{article.label_country_groupings}</h2>
                {CountryGroups(
                  article.documentType,
                  article.country_groupings
                ) || divItem(t('articles_page.no_country_groupings'))}
              </ArticleField>

              <ArticleField visible={article.lmic_focus_visible}>
                <h2>{article.label_lmic_focus}</h2>
                <div className="article-item-lmic-focus">
                  {(article.lmic_focus.length > 0 &&
                    joinList(
                      _.compact(_.pluck(article.lmic_focus, 'title'))
                    )) ||
                    t('articles_page.no_lmic_focus')}
                </div>
              </ArticleField>

              <ArticleField visible={article.type === 'cvd'}>
                <h2 style={{ fontWeight: 'bold' }}>
                  {article.label_covid_response_categories}
                </h2>
              </ArticleField>

              <ArticleField
                visible={
                  article.public_health_measures_visible &&
                  article.public_health_measures.length > 0
                }
              >
                <h2>{article.label_public_health_measures}</h2>
                <ul>
                  {NestedList(
                    article.public_health_measures,
                    'children',
                    'measure'
                  ) || listItem(t('articles_page.no_domains'))}
                </ul>
              </ArticleField>

              <ArticleField
                visible={
                  article.clinical_management_visible &&
                  article.clinical_management.length > 0
                }
              >
                <h2>{article.label_clinical_management}</h2>
                <ul>
                  {NestedList(
                    article.clinical_management,
                    'children',
                    'management'
                  ) || listItem(t('articles_page.no_domains'))}
                </ul>
              </ArticleField>

              <ArticleField
                visible={
                  article.health_system_arrangements_visible &&
                  article.health_system_arrangements.length > 0
                }
              >
                <h2>{article.label_health_system_arrangements}</h2>
                <ul>
                  {NestedList(
                    article.health_system_arrangements,
                    'children',
                    'arrangement'
                  ) || listItem(t('articles_page.no_domains'))}
                </ul>
              </ArticleField>

              <ArticleField
                visible={
                  article.economic_social_responses_visible &&
                  article.economic_social_responses.length > 0
                }
              >
                <h2>{article.label_economic_social_responses}</h2>
                <ul>
                  {NestedList(
                    article.economic_social_responses,
                    'children',
                    'response'
                  ) || listItem(t('articles_page.no_domains'))}
                </ul>
              </ArticleField>
            </div>

            <div className="desktop-sidebar">
              <div className="section">
                <ArticleField visible={article.user_friendly_summary_visible}>
                  <h2>{article.label_user_friendly_summary}</h2>
                  <div>
                    {Links(
                      'summary-link',
                      article.user_friendly_summary_links,
                      lang
                    ) || t('articles_page.no_summary_links')}
                  </div>
                </ArticleField>

                <ArticleField visible={article.scientific_abstract_visible}>
                  <h2>{article.label_scientific_abstract}</h2>
                  <div>
                    {Links('abstract-links', article.abstracts, lang) ||
                      t('articles_page.no_scientific_abstract')}
                  </div>
                </ArticleField>

                <ArticleField visible={article.full_text_report_visible}>
                  <h2>{article.label_full_text_report}</h2>
                  {Links('full-text-report', article.hyperlinks, lang) ||
                    divItem(t('articles_page.no_full_text_report'))}
                </ArticleField>

                <ArticleField>
                  <h2>{article.label_citation}</h2>
                  <div>{article.citation || t('articles_page.no_doi')}</div>
                </ArticleField>

                <ArticleField>
                  <h2>{article.label_doi}</h2>
                  <div>{article.DOI || t('articles_page.no_doi')}</div>
                </ArticleField>
              </div>

              <div className="section">
                <ArticleField>
                  <h2>{article.label_question_type}</h2>
                  <div>{article.questionType}</div>
                </ArticleField>

                <ArticleField visible={article.best_status_visible}>
                  <h2>{article.label_best_status}</h2>
                  <div>
                    {article.best
                      ? t('articles_page.yes')
                      : t('articles_page.no')}
                  </div>
                </ArticleField>

                <ArticleField visible={article.living_synthesis_visible}>
                  <h2>{article.label_living_synthesis}</h2>
                  <div>
                    {article.living
                      ? t('articles_page.yes')
                      : t('articles_page.no')}
                  </div>
                </ArticleField>

                <ArticleField visible={article.grade_profile_visible}>
                  <h2>{article.label_grade_profile}</h2>
                  <div>
                    {article.grade_profile_available
                      ? t('articles_page.yes')
                      : t('articles_page.no')}
                  </div>
                </ArticleField>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Context(ArticleDetail);
