import API from './API';
/*
 * CuratedSearch service for interacting with the API backend
 */

const CuratedSearchService = () => {
  const list = async (type) => {
    try {
      return await API.call(`/client-curated/list/${type}`);
    } catch (e) {
      throw e;
    }
  };

  const list_by_subscriber = async (type, user_id) => {
    const params = {
      method: 'GET',
      data: {
        type,
        user_id,
      },
    };
    try {
      return await API.call('/client-curated/list-by-subscriber', params);
    } catch (e) {
      throw e;
    }
  };

  const subscribe = async (_id, user_id) => {
    const params = {
      method: 'POST',
      body: JSON.stringify({
        _id,
        user_id,
      }),
    };

    try {
      return await API.call('/client-curated/subscribe', params);
    } catch (e) {
      throw e;
    }
  };

  const unsubscribe = async (_id, user_id) => {
    const params = {
      method: 'POST',
      body: JSON.stringify({
        _id,
        user_id,
      }),
    };

    try {
      return await API.call('/client-curated/unsubscribe', params);
    } catch (e) {
      throw e;
    }
  };

  return {
    list,
    list_by_subscriber,
    subscribe,
    unsubscribe,
  };
};

export default CuratedSearchService;
