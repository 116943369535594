import Context from './Context';
import { LayerConsumer } from './LayerContext';

const AccountMenu = ({ t, language }) => {
  return (
    <LayerConsumer>
      {({ dismissAll }) => (
        <ul className="account-menu main-menu menu-list">
          <li className="menu-item menu-item-profile">
            <span className="menu-item-icon"></span>
            <a rel="alternate" href="/profile" className="menu-item-text">
              {t('menus.account.profile')}
            </a>
          </li>
          <li className="menu-item menu-item-saved-articles">
            <span className="menu-item-icon"></span>
            <a rel="alternate" href="/user/articles" className="menu-item-text">
              {t('menus.account.saved_documents')}
            </a>
          </li>
          <li className="menu-item menu-item-saved-searches">
            <span className="menu-item-icon"></span>
            <a rel="alternate" href="/user/searches" className="menu-item-text">
              {t('menus.account.saved_searches')}
            </a>
          </li>
        </ul>
      )}
    </LayerConsumer>
  );
};

export default Context(AccountMenu);
