import { useState, useEffect } from 'react';
import Context from './Context';
import LayerToggle from './LayerToggle';

const AppliedFilters = ({
  filters = [],
  tree,
  onShowMenu,
  onFilterChange,
  t,
}) => {
  let [groups, setGroups] = useState({});

  const flatten = (tree) => {
    let keys = [];
    tree.forEach((item) => {
      keys.push(item.legacyKey);
      if (item.hasOwnProperty('children')) {
        keys = keys.concat(flatten(item.children));
      }
    });
    return keys;
  };

  const flatten_tree = () => {
    let flattened = {};
    const categories = Object.keys(tree);
    for (const category of categories) {
      for (const item of tree[category].items) {
        let parent_key = item.legacyKey;
        if (item.hasOwnProperty('children')) {
          const keys = flatten(item.children);
          keys.forEach((key) => {
            //console.log(key);
            if (filters.indexOf(key) >= 0) {
              if (!flattened[parent_key]) {
                flattened[parent_key] = [];
              }
              flattened[parent_key].push(key);
            }
          });
        } else {
          const date_range_filter = filters.find((f) => {
            const parts = f.split('_');
            return parts[0] === '0' && parts[1] === '12';
          });

          const has_date_range_filter =
            parent_key === '0_12' && date_range_filter;

          if (filters.indexOf(parent_key) >= 0 || has_date_range_filter) {
            if (!flattened[parent_key]) {
              flattened[parent_key] = [];
            }
            flattened[parent_key].push(parent_key);
          }
        }
      }
    }
    return flattened;
  };

  useEffect(() => {
    if (tree) {
      const flattened = flatten_tree(tree);
      setGroups(flattened);
    }
  }, [tree, filters]);

  const getMenu = (key) => {
    let result = {};
    for (const t in tree) {
      for (const menu of tree[t].items) {
        if (menu.legacyKey === key) {
          result = menu;
        }
      }
    }
    return result;
  };

  const toggle = (e, parent, keys) => {
    e.preventDefault();
    if (keys.length === 1 && keys[0] === '0_12') {
      const date_range = filters.find((f) => f.indexOf('0_12_') === 0);
      keys = [date_range];
    }
    keys.push(parent);
    const filtered = filters.filter((f) => !keys.includes(f));
    onFilterChange(filtered);
  };

  const getMenuName = (type) => {
    let menu = '';
    switch (type) {
      case 'countries':
        menu = 'countries_countries';
        break;
      case 'dateRange':
        menu = 'dateRange';
        break;
      default:
        menu = 'filters';
        break;
    }
    return menu;
  };

  return (
    <ul className="applied-filters">
      {Object.keys(groups).map((key) => {
        const isTopLevel =
          groups[key].length === 1 && groups[key][0] === key && key !== '0_12';
        const titles = groups[key].map((g) => t(`filters.${g}`));
        const title = titles.join(', ').substring(0, 50);
        const menu = getMenu(key);
        return (
          <li key={key} className="applied-filter-item">
            {isTopLevel ? (
              <a
                rel="alternate"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
                class="layer-toggle layer-toggle-filters applied-filter-item-content"
              >
                <span className="applied-filter-item-filters">{title}</span>
              </a>
            ) : (
              <LayerToggle
                className="applied-filter-item-content"
                menu={getMenuName(menu.type)}
                title=""
                context={{
                  menu,
                  filters,
                  onFilterChange,
                  onShowFilterGroup: onShowMenu,
                }}
                onToggle={onShowMenu}
              >
                <span className="applied-filter-item-filters">{title}</span>
              </LayerToggle>
            )}
            <a
              className="applied-filter-item-remove"
              onClick={(e) => toggle(e, key, groups[key])}
              href="/"
            >
              x
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Context(AppliedFilters);
