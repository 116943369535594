import React from 'react';
import { SiteConsumer } from './SiteContext';
import { LanguageConsumer } from './LanguageContext';
import { PageConsumer } from './PageContext';
import { LayerConsumer } from './LayerContext';
import { UserConsumer } from './UserContext';
import { ModalConsumer } from './ModalContext';

const Context = (BaseComponent) => (props) =>
  (
    <SiteConsumer>
      {({ site, sendEvent }) => (
        <LanguageConsumer>
          {({ t, language, updateLanguage }) => (
            <UserConsumer>
              {({ user, getProfile, logout }) => (
                <PageConsumer>
                  {({ sPage }) => (
                    <ModalConsumer>
                      {({ showModal }) => (
                        <LayerConsumer>
                          {({ toggleLayer }) => (
                            <BaseComponent
                              {...props}
                              t={t}
                              user={user}
                              getProfile={getProfile}
                              setPage={sPage}
                              site={site}
                              language={language}
                              updateLanguage={updateLanguage}
                              toggleLayer={toggleLayer}
                              logout={logout}
                              showModal={showModal}
                              sendEvent={sendEvent}
                            />
                          )}
                        </LayerConsumer>
                      )}
                    </ModalConsumer>
                  )}
                </PageConsumer>
              )}
            </UserConsumer>
          )}
        </LanguageConsumer>
      )}
    </SiteConsumer>
  );

export default Context;
