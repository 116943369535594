import marked from 'marked';
import Context from './Context';

const BestTipsMenu = ({ t }) => {
  let instructions = `<p>${t('menus.best_tips')}</p>`;
  return (
    <div
      className="help-menu"
      dangerouslySetInnerHTML={{ __html: marked(instructions) }}
    ></div>
  );
};

export default Context(BestTipsMenu);
