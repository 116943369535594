const types = [
  { value: 'Evidence briefs for policy', label: 'Evidence briefs for policy' },
  {
    value: 'Overviews of evidence syntheses',
    label: 'Overviews of evidence syntheses',
  },
  {
    label: 'Evidence syntheses of effects',
    value: 'Evidence syntheses of effects',
  },
  {
    value: 'Evidence syntheses addressing other questions',
    label: 'Evidence syntheses addressing other questions',
  },
  {
    value: 'Evidence syntheses in progress',
    label: 'Evidence syntheses in progress',
  },
  {
    value: 'Evidence syntheses being planned',
    label: 'Evidence syntheses being planned',
  },
  {
    value: 'Economic evaluations and costing studies',
    label: 'Economic evaluations and costing studies',
  },
  {
    value:
      'No, after reviewing the document types and eligibility criteria, this record is not eligible for inclusions in SSE.',
    label:
      'No, after reviewing the document types and eligibility criteria, this record is not eligible for inclusions in SSE.',
  },
];

const questionTypes = [
  {
    label: 'Many',
    value: 'Many',
  },
  {
    label: 'Effectiveness',
    value: 'Effectiveness',
  },
  {
    label: 'Not effectiveness',
    value: 'Not effectiveness',
  },
  {
    label:
      'Cost-effectiveness/benefit/utility analysis or description of costs',
    value:
      'Cost-effectiveness/benefit/utility analysis or description of costs',
  },
  {
    label: 'Description',
    value: 'Description',
  },
];

const tree = {
  checkedDomain: {
    title: 'Domains',
    legacyKey: '0_2',
    items: [
      {
        title: 'Programs and services',
        key: 'programsServices',
        legacyKey: '2_-1',
        children: [
          {
            title: 'Children and youth services',
            key: 'programsServicesChildren',
            legacyKey: '2_2022',
            children: [
              {
                title: 'Adoption services',
                key: 'programsServicesChildrenAdoption',
                legacyKey: '2_2209',
              },
              {
                title: 'Caregiver support',
                key: 'programsServicesChildrenCaregiver',
                legacyKey: '2_2210',
              },
              {
                title: 'Early childhood development services',
                key: 'programsServicesChildrenEarlyChildhood',
                legacyKey: '2_2211',
              },
              {
                title: 'Special needs services',
                key: 'programsServicesChildrenSpecialNeeds',
                legacyKey: '2_2213',
              },
              {
                title: 'Student support services',
                key: 'programsServicesChildrenStudentSupport',
                legacyKey: '2_2214',
              },
              {
                title: 'Parent/legal guardian support services',
                key: 'programsServicesChildrenParentGuardian',
                legacyKey: '2_2215',
              },
              {
                title: 'Child protection',
                key: 'programsServicesChildrenChildProtection',
                legacyKey: '2_2216',
              },
            ],
          },
          {
            title: 'Citizenship',
            key: 'programsServicesCitizenship',
            legacyKey: '2_2009',
            children: [
              {
                title: 'Admissibility, security and visa services',
                key: 'programsServicesCitizenshipAdmissibility',
                legacyKey: '2_2195',
              },
              {
                title: 'Border services',
                key: 'citizenshipBorder',
                legacyKey: '2_2196',
              },
              {
                title: 'Refugee services',
                key: 'citizenshipRefugee',
                legacyKey: '2_2197',
              },
              {
                title: 'Temporary residence permits',
                key: 'programsServicesCitizenshipTemporaryResidence',
                legacyKey: '2_2198',
              },
              {
                title: 'Permanent residence permits',
                key: 'programsServicesCitizenshipPermanentResidence',
                legacyKey: '2_2199',
              },
              {
                title: 'Citizenship grants',
                key: 'citizenshipGrants',
                legacyKey: '2_2200',
              },
              {
                title: 'Settlement/re-settlement',
                key: 'programsServicesCitizenshipSettlement',
                legacyKey: '2_2201',
              },
              {
                title: 'Civic engagement/volunteering',
                key: 'programsServicesCitizenshipVolunteering',
                legacyKey: '2_2202',
              },
              {
                title: 'Civic/cultural integration',
                key: 'programsServicesCitizenshipCulturalIntegration',
                legacyKey: '2_2203',
              },
              {
                title: 'Undocumented individuals',
                key: 'programsServicesCitizenshipUndocumented',
                legacyKey: '2_2204',
              },
              {
                title: 'Migration',
                key: 'citizenshipMigration',
                legacyKey: '2_2205',
              },
              {
                title: 'Detention, deportation and extradition',
                key: 'programsServicesCitizenshipDetentionDeportation',
                legacyKey: '2_2349',
              },
              {
                title: 'Sub-national cooperation',
                key: 'programsServicesCitizenshipSubnational',
                legacyKey: '2_2206',
              },
              {
                title: 'International cooperation',
                key: 'programsServicesCitizenshipInternational',
                legacyKey: '2_2208',
              },
            ],
          },
          {
            title: 'Climate action',
            key: 'programsServicesClimate',
            legacyKey: '2_2402',
            children: [
              {
                title: 'Energy-use reductions',
                key: 'programsServicesClimateEnergyReductions',
                legacyKey: '2_2406',
                children: [
                  {
                    title: 'Buildings',
                    key: 'programsServicesClimateEnergyBuildings',
                    legacyKey: '2_2439',
                  },
                  {
                    title: 'Energy production',
                    key: 'programsServicesClimateEnergyProduction',
                    legacyKey: '2_2440',
                  },
                  {
                    title: 'Households',
                    key: 'programsServicesClimateEnergyHouseholds',
                    legacyKey: '2_2441',
                  },
                  {
                    title: 'Industries',
                    key: 'programsServicesClimateEnergyIndustries',
                    legacyKey: '2_2442',
                  },
                  {
                    title: 'Land use',
                    key: 'programsServicesClimateEnergyLandUse',
                    legacyKey: '2_2443',
                  },
                  {
                    title: 'Tourism',
                    key: 'programsServicesClimateEnergyTourism',
                    legacyKey: '2_2444',
                  },
                  {
                    title: 'Transport',
                    key: 'programsServicesClimateEnergyTransport',
                    legacyKey: '2_2445',
                  },
                ],
              },
              {
                title: 'Low or zero-carbon electricity supply',
                key: 'programsServicesClimateZeroCarbon',
                legacyKey: '2_2407',
                children: [
                  {
                    title: 'Nuclear power',
                    key: 'programsServicesClimateZeroCarbonNuclear',
                    legacyKey: '2_2446',
                  },
                  {
                    title: 'Renewable energy',
                    key: 'programsServicesClimateZeroCarbonRenewable',
                    legacyKey: '2_2447',
                  },
                ],
              },
              {
                title: 'Electrification and other fuel switching',
                key: 'programsServicesClimateElectrification',
                legacyKey: '2_2408',
                children: [
                  {
                    title: 'Appliances',
                    key: 'programsServicesClimateElectrificationAppliances',
                    legacyKey: '2_2448',
                  },
                  {
                    title: 'Supporting infrastructure',
                    key: 'programsServicesClimateElectrificationSupportingInfra',
                    legacyKey: '2_2449',
                  },
                  {
                    title: 'Vehicles',
                    key: 'programsServicesClimateElectrificationVehicles',
                    legacyKey: '2_2450',
                  },
                ],
              },
              {
                title: 'Non-energy emission solutions',
                key: 'programsServicesClimateNonEnergy',
                legacyKey: '2_2409',
                children: [
                  {
                    title: 'Bio-sequestration',
                    key: 'programsServicesClimateNonEnergyBioSequestration',
                    legacyKey: '2_2451',
                  },
                  {
                    title: 'Carbon capture and storage',
                    key: 'programsServicesClimateNonEnergyCarbonCapture',
                    legacyKey: '2_2452',
                  },
                  {
                    title: 'Fugitive-emission reduction',
                    key: 'programsServicesClimateNonEnergyFugitiveEmissionReduction',
                    legacyKey: '2_2453',
                  },
                  {
                    title: 'Industrial-process improvements',
                    key: 'programsServicesClimateNonEnergyIndustrialProcessImprovements',
                    legacyKey: '2_2454',
                  },
                  {
                    title: 'Reuse and recycling',
                    key: 'programsServicesClimateNonEnergyReuseRecycling',
                    legacyKey: '2_2455',
                  },
                ],
              },
              {
                title: 'Climate-change risk management',
                key: 'programsServicesClimateChangeRisk',
                legacyKey: '2_2410',
                children: [
                  {
                    title: 'Financial',
                    key: 'programsServicesClimateChangeRiskFinancial',
                    legacyKey: '2_2456',
                  },
                  {
                    title: 'Human',
                    key: 'programsServicesClimateChangeRiskHuman',
                    legacyKey: '2_2457',
                  },
                  {
                    title: 'Property',
                    key: 'programsServicesClimateChangeRiskProperty',
                    legacyKey: '2_2458',
                  },
                  {
                    title: 'Settlement',
                    key: 'programsServicesClimateChangeRiskSettlement',
                    legacyKey: '2_2459',
                  },
                ],
              },
            ],
          },
          {
            title: 'Community and social services',
            key: 'programsServicesCommunity',
            legacyKey: '2_2010',
            children: [
              {
                title: 'Accessibility services',
                key: 'programsServicesCommunityAccessibility',
                legacyKey: '2_2217',
              },
              {
                title: 'Disability services',
                key: 'programsServicesCommunityDisability',
                legacyKey: '2_2218',
              },
              {
                title: 'Problem gambling services',
                key: 'programsServicesCommunityGambling',
                legacyKey: '2_2219',
              },
              {
                title: 'Other social services',
                key: 'programsServicesCommunitySocial',
                legacyKey: '2_2220',
              },
              {
                title: 'Community services',
                key: 'programsServicesCommunityServices',
                legacyKey: '2_2221',
              },
              {
                title: 'Community development',
                key: 'programsServicesCommunityDevelopment',
                legacyKey: '2_2222',
              },
              {
                title: 'Emergency response and preparedness',
                key: 'programsServicesCommunityEmergencyResponse',
                legacyKey: '2_2223',
              },
            ],
          },
          {
            title: 'Consumer protection',
            key: 'programsServicesConsumer',
            legacyKey: '2_2011',
            children: [
              {
                title: 'Consumer awareness and education',
                key: 'programsServicesConsumerAwarenessEducation',
                legacyKey: '2_2224',
              },
              {
                title: 'Consumer advocacy and rights',
                key: 'programsServicesConsumerAdvocacy',
                legacyKey: '2_2225',
              },
              {
                title: 'Advertising and marketing standards',
                key: 'programsServicesConsumerAdvertising',
                legacyKey: '2_2227',
              },
              {
                title: 'Consumer promotion standards',
                key: 'programsServicesConsumerPromotion',
                legacyKey: '2_2228',
              },
              {
                title: 'Responsible consumption initiatives',
                key: 'programsServicesConsumerConsumption',
                legacyKey: '2_2350',
              },
              {
                title: 'Product safety',
                key: 'programsServicesConsumerProductSafety',
                legacyKey: '2_2229',
              },
              {
                title: 'Fraud',
                key: 'programsServicesConsumerFraud',
                legacyKey: '2_2230',
              },
              {
                title: 'Privacy',
                key: 'programsServicesConsumerPrivacy',
                legacyKey: '2_2231',
              },
              {
                title: 'Competition supports',
                key: 'programsServicesConsumerCompetition',
                legacyKey: '2_2232',
              },
              {
                title: 'E-commerce and the sharing economy',
                key: 'programsServicesConsumerEcommerce',
                legacyKey: '2_2233',
              },
            ],
          },
          {
            title: 'Culture and gender',
            key: 'programsServicesCulture',
            legacyKey: '2_2012',
            children: [
              {
                title: 'Arts',
                key: 'programsServicesCultureArts',
                legacyKey: '2_2234',
              },
              {
                title: 'Heritage',
                key: 'programsServicesCultureHeritage',
                legacyKey: '2_2235',
              },
              {
                title: 'Cultural industries',
                key: 'programsServicesCultureIndustries',
                legacyKey: '2_2236',
              },
              {
                title: 'Cultural protectionism',
                key: 'programsServicesCultureProtectionism',
                legacyKey: '2_2237',
              },
              {
                title: 'Cultural competency training',
                key: 'programsServicesCultureTraining',
                legacyKey: '2_2238',
              },
              {
                title: 'Multiculturalism',
                key: 'programsServicesCultureMulticulturalism',
                legacyKey: '2_2239',
              },
              {
                title: 'Gender mainstreaming',
                key: 'programsServicesCultureGenderMainstreaming',
                legacyKey: '2_2240',
              },
              {
                title: 'Gender equality',
                key: 'programsServicesCultureGenderEquality',
                legacyKey: '2_2241',
              },
              {
                title: 'Human rights',
                key: 'programsServicesCultureHumanRights',
                legacyKey: '2_2242',
              },
            ],
          },
          {
            title: 'Economic development and growth',
            key: 'programsServicesEconomic',
            legacyKey: '2_2352',
            children: [
              {
                title: 'Access to finance',
                key: 'programsServicesEconomicAccessToFinance',
                legacyKey: '2_2353',
              },
              {
                title: 'Area-based initiatives',
                key: 'programsServicesEconomicAreaBasedInitiatives',
                legacyKey: '2_2354',
              },
              {
                title: 'Broadband access',
                key: 'programsServicesEconomicBroadbandAccess',
                legacyKey: '2_2355',
              },
              {
                title: 'Business advice',
                key: 'programsServicesEconomicBusinessAdvice',
                legacyKey: '2_2356',
              },
              {
                title: 'Housing stock renewal',
                key: 'programsServicesEconomicHousingStock',
                legacyKey: '2_2357',
              },
              {
                title: 'Human capital investments',
                key: 'programsServicesEconomicHumanCapital',
                legacyKey: '2_2358',
              },
              {
                title: 'Innovation supports',
                key: 'programsServicesEconomicInnovation',
                legacyKey: '2_2359',
              },
              {
                title: 'Public space improvements',
                key: 'programsServicesEconomicPublicSpace',
                legacyKey: '2_2360',
              },
              {
                title: 'Sport/culture events and facilities',
                key: 'programsServicesEconomicSportCulture',
                legacyKey: '2_2361',
              },
              {
                title: 'Transportation enhancements',
                key: 'programsServicesEconomicTransportationEnhancements',
                legacyKey: '2_2362',
              },
              {
                title: 'General fiscal policy tools',
                key: 'programsServicesEconomicFiscalPolicy',
                legacyKey: '2_2363',
              },
              {
                title: 'General monetary policy tools',
                key: 'programsServicesEconomicMonetaryPolicy',
                legacyKey: '2_2364',
              },
              {
                title: 'General trade policy tools',
                key: 'programsServicesEconomicTradePolicy',
                legacyKey: '2_2365',
              },
            ],
          },
          {
            title: 'Education',
            key: 'programsServicesEducation',
            legacyKey: '2_2023',
            children: [
              {
                title: 'Pre-primary education',
                key: 'programsServicesEducationPrePrimary',
                legacyKey: '2_2366',
              },
              {
                title: 'Primary education',
                key: 'programsServicesEducationPrimary',
                legacyKey: '2_2367',
              },
              {
                title: 'Secondary education',
                key: 'programsServicesEducationSecondary',
                legacyKey: '2_2368',
              },
              {
                title: 'Tertiary/higher education',
                key: 'programsServicesEducationTertiary',
                legacyKey: '2_2369',
              },
              {
                title: 'Academic planning',
                key: 'programsServicesEducationAcademicPlanning',
                legacyKey: '2_2370',
              },
              {
                title: 'Revenue streams',
                key: 'programsServicesEducationRevenueStreams',
                legacyKey: '2_2371',
              },
              {
                title: 'Tuition setting and subsidy',
                key: 'programsServicesEducationTuitionSubsidy',
                legacyKey: '2_2372',
              },
              {
                title: 'Marketing',
                key: 'programsServicesEducationMarketing',
                legacyKey: '2_2243',
              },
              {
                title: 'Admission and recruitment',
                key: 'programsServicesEducationAdmissionRecruitment',
                legacyKey: '2_2244',
              },
              {
                title: 'Streaming',
                key: 'programsServicesEducationStreaming',
                legacyKey: '2_2245',
              },
              {
                title: 'Curriculum',
                key: 'programsServicesEducationCurriculum',
                legacyKey: '2_2246',
              },
              {
                title: 'Literacy training',
                key: 'programsServicesEducationLiteracyTraining',
                legacyKey: '2_2247',
              },
              {
                title: 'Apprenticeships',
                key: 'programsServicesEducationApprenticeships',
                legacyKey: '2_2248',
              },
              {
                title: 'Teaching',
                key: 'programsServicesEducationTeaching',
                legacyKey: '2_2249',
              },
              {
                title: 'Assessment',
                key: 'programsServicesEducationAssessment',
                legacyKey: '2_2250',
              },
              {
                title: 'Student engagement',
                key: 'programsServicesEducationStudentEngagement',
                legacyKey: '2_2251',
              },
              {
                title: 'Parent/legal guardian engagement',
                key: 'educationParentEngagement',
                legacyKey: '2_2252',
              },
              {
                title: 'Teacher/faculty engagement',
                key: 'programsServicesEducationTeacherEngagement',
                legacyKey: '2_2253',
              },
              {
                title: 'Community engagement',
                key: 'programsServicesEducationCommunityEngagement',
                legacyKey: '2_2254',
              },
              {
                title: 'Prospective employers engagement',
                key: 'programsServicesEducationEmployerEngagement',
                legacyKey: '2_2255',
              },
            ],
          },
          {
            title: 'Employment',
            key: 'programsServicesEmployment',
            legacyKey: '2_2018',
            children: [
              {
                title: 'Job training/retraining',
                key: 'programsServicesEmploymentTraining',
                legacyKey: '2_2299',
              },
              {
                title: 'Wages',
                key: 'programsServicesEmploymentWages',
                legacyKey: '2_2300',
              },
              {
                title: 'Benefits',
                key: 'programsServicesEmploymentBenefits',
                legacyKey: '2_2301',
              },
              {
                title: 'Employment conditions',
                key: 'programsServicesEmploymentConditions',
                legacyKey: '2_2302',
              },
              {
                title: 'Displaced workers',
                key: 'programsServicesEmploymentDisplacedWorkers',
                legacyKey: '2_2303',
              },
              {
                title: 'Unemployment',
                key: 'programsServicesEmploymentUnemployment',
                legacyKey: '2_2304',
              },
              {
                title: 'Unpaid labour',
                key: 'programsServicesEmploymentUnpaidLabour',
                legacyKey: '2_2305',
              },
              {
                title: 'Workplace safety and prevention',
                key: 'programsServicesEmploymentWorkplaceSafety',
                legacyKey: '2_2306',
              },
              {
                title: 'Workplace violence and harassment',
                key: 'programsServicesEmploymentWorkplaceViolence',
                legacyKey: '2_2307',
              },
              {
                title: 'Unions and collective bargaining',
                key: 'programsServicesEmploymentUnions',
                legacyKey: '2_2308',
              },
              {
                title: 'Labour-market interventions',
                key: 'programsServicesEmploymentLabourMarket',
                legacyKey: '2_2309',
              },
              {
                title: 'Industry-level interventions',
                key: 'programsServicesEmploymentIndustry',
                legacyKey: '2_2310',
              },
            ],
          },
          {
            title: 'Energy supply',
            key: 'energySupply',
            legacyKey: '2_2403',
            children: [
              {
                title: 'Biofuel',
                key: 'energySupplyBiofuel',
                legacyKey: '2_2411',
                children: [
                  {
                    title: 'First-generation biofuels',
                    key: 'energySupplyBiofuelFirstGeneration',
                    legacyKey: '2_2460',
                  },
                  {
                    title: 'Second-generation biofuels',
                    key: 'energySupplyBiofuelSecondGeneration',
                    legacyKey: '2_2461',
                  },
                ],
              },
              { title: 'Coal', key: 'energySupplyCoal', legacyKey: '2_2412' },
              {
                title: 'Hydro-electricity',
                key: 'energySupplyHydro',
                legacyKey: '2_2413',
                children: [
                  {
                    title: 'Pumped storage',
                    key: 'energySupplyHydroPumpedStorage',
                    legacyKey: '2_2462',
                  },
                  {
                    title: 'Reservoir',
                    key: 'energySupplyHydroResevoir',
                    legacyKey: '2_2463',
                  },
                  {
                    title: 'Run-of-river',
                    key: 'energySupplyHydroRunOfRiver',
                    legacyKey: '2_2464',
                  },
                  {
                    title: 'Tidal range',
                    key: 'energySupplyHydroTidalRange',
                    legacyKey: '2_2465',
                  },
                  {
                    title: 'Tidal stream',
                    key: 'energySupplyHydroTidalStream',
                    legacyKey: '2_2466',
                  },
                ],
              },
              {
                title: 'Natural gas',
                key: 'energySupplyNaturalGas',
                legacyKey: '2_2414',
              },
              {
                title: 'Nuclear energy',
                key: 'energySupplyNuclearEnergy',
                legacyKey: '2_2415',
              },
              {
                title: 'Petroleum products',
                key: 'energySupplyPetroleum',
                legacyKey: '2_2416',
              },
              {
                title: 'Solar power',
                key: 'energySupplySolarPower',
                legacyKey: '2_2417',
              },
              {
                title: 'Wind power',
                key: 'energySupplyWindPower',
                legacyKey: '2_2418',
              },
              {
                title: 'Non-carbon fuel',
                key: 'energySupplyNonCarbon',
                legacyKey: '2_2419',
                children: [
                  {
                    title: 'Ammonia',
                    key: 'energySupplyNonCarbonAmonia',
                    legacyKey: '2_2467',
                  },
                  {
                    title: 'Hydrogen',
                    key: 'energySupplyNonCarbonHydrogen',
                    legacyKey: '2_2468',
                  },
                ],
              },
              {
                title: 'Geothermal',
                key: 'energySupplyGeothermal',
                legacyKey: '2_2420',
                children: [
                  {
                    title: 'Electricity generation',
                    key: 'energySupplyGeothermalElectricity',
                    legacyKey: '2_2469',
                  },
                  {
                    title: 'Heating and heating systems',
                    key: 'energySupplyGeothermalHeating',
                    legacyKey: '2_2470',
                  },
                  {
                    title: 'Heat pump',
                    key: 'energySupplyGeothermalHeatPump',
                    legacyKey: '2_2471',
                  },
                ],
              },
              {
                title: 'Waste (to) energy',
                key: 'energyWaste',
                legacyKey: '2_2421',
                children: [
                  {
                    title: 'Biological treatment',
                    key: 'energyWasteBiological',
                    legacyKey: '2_2472',
                  },
                  {
                    title: 'Thermochemical treatment',
                    key: 'energyWasteThermochemical',
                    legacyKey: '2_2473',
                  },
                ],
              },
              {
                title: 'Co-generation',
                key: 'energyCoGen',
                legacyKey: '2_2422',
                children: [
                  {
                    title: 'Power and heat',
                    key: 'energyCoGenPowerHeat',
                    legacyKey: '2_2474',
                  },
                  {
                    title: 'Power, heat and cooling (trigeneration)',
                    key: 'energyCoGenPowerHeatCooling',
                    legacyKey: '2_2475',
                  },
                  {
                    title:
                      'Power, heat, cooling and other products (polygeneration)',
                    key: 'energyCoGenPolygeneration',
                    legacyKey: '2_2476',
                  },
                ],
              },
              {
                title: 'Energy storage',
                key: 'energyStorage',
                legacyKey: '2_2423',
                children: [
                  {
                    title: 'Domestic',
                    key: 'energyStorageDomestic',
                    legacyKey: '2_2477',
                  },
                  {
                    title: 'Industrial',
                    key: 'energyStorageIndustrial',
                    legacyKey: '2_2478',
                  },
                ],
              },
              {
                title: 'Energy systems',
                key: 'energySystems',
                legacyKey: '2_2424',
                children: [
                  {
                    title: 'Energy mix',
                    key: 'energySystemsMix',
                    legacyKey: '2_2479',
                  },
                  {
                    title: 'Energy efficiency initiatives',
                    key: 'energySystemsEfficiency',
                    legacyKey: '2_2514',
                  },
                  {
                    title: 'Grid design',
                    key: 'energySystemsGrid',
                    legacyKey: '2_2480',
                  },
                  {
                    title: 'On-and off- grid source balancing',
                    key: 'energySystemsGridBalancing',
                    legacyKey: '2_2481',
                  },
                  {
                    title: 'Supply security',
                    key: 'energySystemsSupplySecurity',
                    legacyKey: '2_2482',
                  },
                ],
              },
            ],
          },
          {
            title: 'Environmental conservation',
            key: 'environmentalConservation',
            legacyKey: '2_2404',
            children: [
              {
                title: 'Air',
                key: 'environmentalConservationAir',
                legacyKey: '2_2425',
              },
              {
                title: 'Land',
                key: 'environmentalConservationLand',
                legacyKey: '2_2426',
                children: [
                  {
                    title: 'Land-use planning',
                    key: 'environmentalConservationLandPlanning',
                    legacyKey: '2_2483',
                  },
                  {
                    title: 'Parks and other protected areas',
                    key: 'environmentalConservationLandParks',
                    legacyKey: '2_2484',
                  },
                ],
              },
              {
                title: 'Water',
                key: 'environmentalConservationWater',
                legacyKey: '2_2427',
                children: [
                  {
                    title: 'Freshwater (lakes & rivers)',
                    key: 'environmentalConservationWaterFreshwater',
                    legacyKey: '2_2485',
                  },
                  {
                    title:
                      'Seas (marine & coasts, including coastal erosion) & their catchments',
                    key: 'environmentalConservationWaterSeas',
                    legacyKey: '2_2486',
                  },
                ],
              },
              {
                title: 'Biodiversity',
                key: 'environmentalConservationBiodiversity',
                legacyKey: '2_2428',
                children: [
                  {
                    title: 'Fauna protection',
                    key: 'environmentalConservationBiodiversityFauna',
                    legacyKey: '2_2487',
                  },
                  {
                    title: 'Flora protection',
                    key: 'environmentalConservationBiodiversityFlora',
                    legacyKey: '2_2488',
                  },
                  {
                    title: 'Conservation status assessment',
                    key: 'environmentalConservationBiodiversityStatusAssessment',
                    legacyKey: '2_2489',
                  },
                  {
                    title: 'Sustainable harvesting',
                    key: 'environmentalConservationBiodiversitySustainableHarvesting',
                    legacyKey: '2_2490',
                  },
                ],
              },
              {
                title: 'Recycling',
                key: 'environmentalConservationRecycling',
                legacyKey: '2_2429',
                children: [
                  {
                    title: 'Personal',
                    key: 'environmentalConservationRecyclingPersonal',
                    legacyKey: '2_2491',
                  },
                  {
                    title: 'Industrial',
                    key: 'environmentalConservationRecyclingIndustrial',
                    legacyKey: '2_2492',
                  },
                ],
              },
              {
                title: 'Restoration',
                key: 'environmentalConservationRestoration',
                legacyKey: '2_2430',
                children: [
                  {
                    title: 'Environmental remediation',
                    key: 'environmentalConservationRestorationRemediation',
                    legacyKey: '2_2493',
                  },
                  {
                    title: 'Land restoration',
                    key: 'environmentalConservationRestorationLand',
                    legacyKey: '2_2494',
                  },
                  {
                    title: 'Land rehabilitation',
                    key: 'environmentalConservationRehabilitationLand',
                    legacyKey: '2_2495',
                  },
                ],
              },
              {
                title: 'Waste',
                key: 'environmentalConservationWaste',
                legacyKey: '2_2431',
                children: [
                  {
                    title: 'Hazardous solid, liquid or other waste',
                    key: 'environmentalConservationWasteHazardous',
                    legacyKey: '2_2496',
                  },
                  {
                    title: 'Non-hazardous solid, liquid or other waste',
                    key: 'environmentalConservationWasteNonHazardous',
                    legacyKey: '2_2497',
                  },
                ],
              },
              {
                title: 'Environmental resilience',
                key: 'environmentalConservationResilience',
                legacyKey: '2_2432',
                children: [
                  {
                    title: 'Social',
                    key: 'environmentalConservationResilienceSocial',
                    legacyKey: '2_2498',
                  },
                  {
                    title: 'Economic',
                    key: 'environmentalConservationResilienceEconomic',
                    legacyKey: '2_2499',
                  },
                  {
                    title: 'Institutional',
                    key: 'environmentalConservationResilienceInstitutional',
                    legacyKey: '2_2500',
                  },
                  {
                    title: 'Physical',
                    key: 'environmentalConservationResiliencePhysical',
                    legacyKey: '2_2501',
                  },
                  {
                    title: 'Natural',
                    key: 'environmentalConservationResilienceNatural',
                    legacyKey: '2_2502',
                  },
                ],
              },
              {
                title: 'Environmental-threats management',
                key: 'environmentalConservationThreats',
                legacyKey: '2_2433',
                children: [
                  {
                    title: 'Droughts',
                    key: 'environmentalConservationThreatsDroughts',
                    legacyKey: '2_2517',
                  },
                  {
                    title: 'Fires',
                    key: 'environmentalConservationThreatsFires',
                    legacyKey: '2_2503',
                  },
                  {
                    title: 'Floods',
                    key: 'environmentalConservationThreatsFloods',
                    legacyKey: '2_2515',
                  },
                  {
                    title: 'Invasive species',
                    key: 'environmentalConservationThreatsInvasiveSpecies',
                    legacyKey: '2_2504',
                  },
                  {
                    title: 'Light',
                    key: 'environmentalConservationThreatsLight',
                    legacyKey: '2_2505',
                  },
                  {
                    title: 'Noise',
                    key: 'environmentalConservationThreatsNoise',
                    legacyKey: '2_2506',
                  },
                  {
                    title: 'Severe storm events',
                    key: 'environmentalConservationThreatsSevereStormEvents',
                    legacyKey: '2_2516',
                  },
                ],
              },
            ],
          },
          {
            title: 'Financial protection',
            key: 'financialProtection',
            legacyKey: '2_2256',
            children: [
              {
                title: 'Social assistance',
                key: 'financialSocialAssistance',
                legacyKey: '2_2257',
              },
              {
                title: 'Minimum wage',
                key: 'financialMinimumWage',
                legacyKey: '2_2258',
              },
              {
                title: 'Wage disparity limitations',
                key: 'financialWageDisparity',
                legacyKey: '2_2259',
              },
              {
                title: 'Employment/unemployment insurance',
                key: 'financialEmploymentInsurance',
                legacyKey: '2_2260',
              },
              {
                title: 'Pensions and allowances',
                key: 'financialPensionsAllowances',
                legacyKey: '2_2261',
              },
              {
                title: 'Poverty reduction',
                key: 'financialPovertyReduction',
                legacyKey: '2_2262',
              },
              {
                title: 'Goods and services subsidy',
                key: 'financialGoodServices',
                legacyKey: '2_2373',
              },
              {
                title: 'Guaranteed minimum income',
                key: 'financialGuaranteedIncome',
                legacyKey: '2_2263',
              },
              {
                title: 'Other income re-distribution',
                key: 'financialIncomeRedistribution',
                legacyKey: '2_2264',
              },
              {
                title: 'Income reporting',
                key: 'financialIncomeReporting',
                legacyKey: '2_2265',
              },
              {
                title: 'Asset reporting',
                key: 'financialAssetReporting',
                legacyKey: '2_2266',
              },
            ],
          },
          {
            title: 'Food safety and security',
            key: 'foodSafety',
            legacyKey: '2_2013',
            children: [
              {
                title: 'Food access',
                key: 'foodSafetyAccess',
                legacyKey: '2_2267',
              },
              {
                title: 'Nutritional awareness and education',
                key: 'foodSafetyNutritionalAwareness',
                legacyKey: '2_2268',
              },
              { title: 'Food aid', key: 'foodSafetyAid', legacyKey: '2_2269' },
              {
                title: 'Food standards',
                key: 'foodSafetyStandards',
                legacyKey: '2_2270',
              },
              {
                title: 'Food import/export',
                key: 'foodSafetyImportExport',
                legacyKey: '2_2271',
              },
              {
                title: 'International food relief',
                key: 'foodSafetyInternationalRelief',
                legacyKey: '2_2272',
              },
            ],
          },
          {
            title: 'Government services',
            key: 'government',
            legacyKey: '2_2014',
            children: [
              {
                title: 'Identification, licensing and registration',
                key: 'governmentIdLicensingRegistration',
                legacyKey: '2_2273',
              },
              {
                title: 'Service awareness and education',
                key: 'governmentServiceAwareness',
                legacyKey: '2_2274',
              },
              {
                title: 'e-Government',
                key: 'governmentEgovernment',
                legacyKey: '2_2275',
              },
              {
                title: 'Service coordination',
                key: 'governmentServiceCoordination',
                legacyKey: '2_2276',
              },
              {
                title: 'Government corruption protections',
                key: 'governmentCorruptionProtection',
                legacyKey: '2_2277',
              },
              {
                title: 'Government inclusivity protections',
                key: 'governmentInclusivityProtection',
                legacyKey: '2_2374',
              },
              {
                title: 'Government accountability protections',
                key: 'governmentAccountabilityProtection',
                legacyKey: '2_2375',
              },
              {
                title: 'Elections administration',
                key: 'governmentElectionAdministration',
                legacyKey: '2_2376',
              },
            ],
          },
          {
            title: 'Housing',
            key: 'housing',
            legacyKey: '2_2015',
            children: [
              {
                title: 'Access to housing',
                key: 'housingAccess',
                legacyKey: '2_2278',
              },
              {
                title: 'Temporary housing',
                key: 'housingTemporary',
                legacyKey: '2_2279',
              },
              {
                title: 'Public/social housing',
                key: 'housingSocial',
                legacyKey: '2_2280',
              },
              {
                title: 'Affordable housing',
                key: 'housingAffordable',
                legacyKey: '2_2281',
              },
              {
                title: 'Housing assistance',
                key: 'housingAssistance',
                legacyKey: '2_2282',
              },
              { title: 'Housing mix', key: 'housingMix', legacyKey: '2_2283' },
              {
                title: 'Housing safety',
                key: 'housingSafety',
                legacyKey: '2_2284',
              },
              {
                title: 'Property rights',
                key: 'housingPropertyRights',
                legacyKey: '2_2285',
              },
            ],
          },
          {
            title: 'Infrastructure',
            key: 'infrastructure',
            legacyKey: '2_2017',
            children: [
              {
                title: 'Capital planning',
                key: 'infrastructureCapitalPlanning',
                legacyKey: '2_2286',
              },
              {
                title: 'Urban planning',
                key: 'infrastructureUrbanPlanning',
                legacyKey: '2_2287',
              },
              {
                title: 'Social infrastructure',
                key: 'infrastructureSocial',
                legacyKey: '2_2288',
              },
              {
                title: 'Broadband infrastructure',
                key: 'infrastructureBroadband',
                legacyKey: '2_2289',
              },
              {
                title: 'Transportation infrastructure',
                key: 'infrastructureTransportation',
                legacyKey: '2_2377',
              },
              {
                title: 'Waste management infrastructure',
                key: 'infrastructureWasteManagement',
                legacyKey: '2_2291',
              },
              {
                title: 'Water and sanitation infrastructure',
                key: 'infrastructureWaterSanitation',
                legacyKey: '2_2290',
              },
              {
                title: 'Sustainable development',
                key: 'infrastructureSustainableDevelopment',
                legacyKey: '2_2292',
              },
              {
                title: 'Capital spending',
                key: 'infrastructureCapitalSpending',
                legacyKey: '2_2293',
              },
              {
                title: 'Public financing',
                key: 'infrastructurePublicFinancing',
                legacyKey: '2_2294',
              },
              {
                title: 'Public/private partnerships',
                key: 'infrastructurePublicPrivatePartnerships',
                legacyKey: '2_2295',
              },
              {
                title: 'Private financing',
                key: 'infrastructurePrivateFinancing',
                legacyKey: '2_2296',
              },
              {
                title: 'Foreign investment',
                key: 'infrastructureForeignInvestment',
                legacyKey: '2_2297',
              },
              {
                title: 'Risk management',
                key: 'infrastructureRiskManagement',
                legacyKey: '2_2298',
              },
            ],
          },
          {
            title: 'Natural resources',
            key: 'naturalResources',
            legacyKey: '2_2405',
            children: [
              {
                title: 'Aquatic life',
                key: 'naturalResourcesAquatic',
                legacyKey: '2_2434',
                children: [
                  {
                    title: 'Fishing and fisheries',
                    key: 'naturalResourcesAquaticFisheries',
                    legacyKey: '2_2507',
                  },
                  {
                    title: 'Aquatic plants including coral',
                    key: 'naturalResourcesAquaticPlants',
                    legacyKey: '2_2508',
                  },
                ],
              },
              {
                title: 'Water',
                key: 'naturalResourcesWater',
                legacyKey: '2_2435',
              },
              {
                title: 'Forests',
                key: 'naturalResourcesForests',
                legacyKey: '2_2436',
                children: [
                  {
                    title: 'Timber industries',
                    key: 'naturalResourcesForestsTimber',
                    legacyKey: '2_2509',
                  },
                ],
              },
              {
                title: 'Ground-based resources',
                key: 'naturalResourcesGround',
                legacyKey: '2_2437',
                children: [
                  {
                    title: 'Metals',
                    key: 'naturalResourcesGroundMetals',
                    legacyKey: '2_2510',
                  },
                  {
                    title: 'Minerals',
                    key: 'naturalResourcesGroundMinerals',
                    legacyKey: '2_2511',
                  },
                  {
                    title: 'Petroleum',
                    key: 'naturalResourcesGroundPetroleum',
                    legacyKey: '2_2512',
                  },
                  {
                    title: 'Gas',
                    key: 'naturalResourcesGroundGas',
                    legacyKey: '2_2513',
                  },
                ],
              },
              {
                title: 'Wildlife',
                key: 'naturalResourcesWildlife',
                legacyKey: '2_2438',
              },
            ],
          },
          {
            title: 'Public safety and justice',
            key: 'publicSafety',
            legacyKey: '2_2019',
            children: [
              {
                title: 'Crime prevention',
                key: 'publicSafetyCrimePrevention',
                legacyKey: '2_2311',
              },
              {
                title: 'Crime reduction',
                key: 'publicSafetyCrimeReduction',
                legacyKey: '2_2312',
              },
              {
                title: 'Security services',
                key: 'publicSafetySecurityServices',
                legacyKey: '2_2313',
              },
              {
                title: 'National security',
                key: 'publicSafetyNationalSecurity',
                legacyKey: '2_2314',
              },
              {
                title: 'Counter-terrorism',
                key: 'publicSafetyCounterTerrorism',
                legacyKey: '2_2378',
              },
              {
                title: 'Military',
                key: 'publicSafetyMilitary',
                legacyKey: '2_2315',
              },
              {
                title: 'Policing',
                key: 'publicSafetyPolicing',
                legacyKey: '2_2316',
              },
              {
                title: 'Court system',
                key: 'publicSafetyCourtSystem',
                legacyKey: '2_2317',
              },
              {
                title: 'Restorative justice',
                key: 'publicSafetyRestorativeJustice',
                legacyKey: '2_2318',
              },
              {
                title: 'Youth justice',
                key: 'publicSafetyYouthJustice',
                legacyKey: '2_2319',
              },
              {
                title: 'Domestic violence support',
                key: 'publicSafetyDomesticViolence',
                legacyKey: '2_2320',
              },
              {
                title: 'Victim support',
                key: 'publicSafetyVictimSupport',
                legacyKey: '2_2321',
              },
              {
                title: 'Offender diversion and support',
                key: 'publicSafetyOffenderDiversion',
                legacyKey: '2_2322',
              },
              {
                title: 'Sentencing',
                key: 'publicSafetySentencing',
                legacyKey: '2_2323',
              },
              {
                title: 'Prison administration and management',
                key: 'publicSafetyPrisonAdministration',
                legacyKey: '2_2324',
              },
              {
                title: 'Probation and parole',
                key: 'publicSafetyProbationParole',
                legacyKey: '2_2325',
              },
              {
                title: 'Community corrections',
                key: 'publicSafetyCommunityCorrections',
                legacyKey: '2_2326',
              },
              {
                title: 'Reintegration',
                key: 'publicSafetyReintegration',
                legacyKey: '2_2327',
              },
            ],
          },
          {
            title: 'Recreation',
            key: 'recreation',
            legacyKey: '2_2328',
            children: [
              {
                title: 'Promotion of recreation',
                key: 'recreationPromotion',
                legacyKey: '2_2329',
              },
              {
                title: 'Public spaces',
                key: 'recreationPublicSpaces',
                legacyKey: '2_2330',
              },
              {
                title: 'Community/recreation programs',
                key: 'recreationCommunityRecreation',
                legacyKey: '2_2331',
              },
              {
                title: 'Private recreation',
                key: 'recreationPrivateRecreation',
                legacyKey: '2_2332',
              },
              {
                title: 'Competitive sport',
                key: 'recreationCompetitiveSport',
                legacyKey: '2_2333',
              },
            ],
          },
          {
            title: 'Transportation',
            key: 'transportation',
            legacyKey: '2_2021',
            children: [
              {
                title: 'Accessible transportation options',
                key: 'transportationAccessibleOptions',
                legacyKey: '2_2334',
              },
              {
                title: 'Congestion management',
                key: 'transportationCongestionManagement',
                legacyKey: '2_2335',
              },
              {
                title: 'Public transportation',
                key: 'transportationPublic',
                legacyKey: '2_2336',
              },
              {
                title: 'Safety - Pedestrian',
                key: 'transportationPedestrianSafety',
                legacyKey: '2_2337',
              },
              {
                title: 'Safety - Cycling',
                key: 'transportationCyclingSafety',
                legacyKey: '2_2338',
              },
              {
                title: 'Safety - Transportation',
                key: 'transportationSafety',
                legacyKey: '2_2339',
              },
              {
                title: 'Sustainable transportation options',
                key: 'transportationSustainableOptions',
                legacyKey: '2_2340',
              },
              {
                title: 'Transportation subsidies',
                key: 'transportationSubsidies',
                legacyKey: '2_2341',
              },
              {
                title: 'Road tolls',
                key: 'transportationRoadTolls',
                legacyKey: '2_2342',
              },
            ],
          },
        ],
      },
      {
        title: 'System arrangements',
        key: 'system',
        legacyKey: '2_2002',
        children: [
          {
            title: 'Governance arrangements',
            key: 'systemGovernance',
            legacyKey: '2_2024',
            children: [
              {
                title: 'Policy authority',
                key: 'systemGovernancePolicy',
                legacyKey: '2_2027',
                children: [
                  {
                    title:
                      'Centralization/decentralization of policy authority',
                    key: 'systemGovernancePolicyCentralization',
                    legacyKey: '2_2041',
                  },
                  {
                    title:
                      "Accountability of the state sector's role in financing & delivery",
                    key: 'systemGovernancePolicyAccountability',
                    legacyKey: '2_2042',
                  },
                  {
                    title:
                      "Stewardship of the non-state sector's role in financing & delivery",
                    key: 'systemGovernancePolicyStewardship',
                    legacyKey: '2_2043',
                  },
                  {
                    title:
                      'Decision-making authority about who is covered and what can or must be provided to them',
                    key: 'systemGovernancePolicyDecisionMaking',
                    legacyKey: '2_2044',
                  },
                  {
                    title: 'Corruption protections',
                    key: 'systemGovernancePolicyCorruptionProtections',
                    legacyKey: '2_2045',
                  },
                ],
              },
              {
                title: 'Organizational authority',
                key: 'systemOrganizational',
                legacyKey: '2_2028',
                children: [
                  {
                    title: 'Ownership',
                    key: 'systemOrganizationalOwnership',
                    legacyKey: '2_2046',
                  },
                  {
                    title: 'Management approaches',
                    key: 'systemOrganizationalManagement',
                    legacyKey: '2_2047',
                  },
                  {
                    title: 'Accreditation',
                    key: 'systemOrganizationalAccreditation',
                    legacyKey: '2_2048',
                  },
                  {
                    title: 'Networks/multi-institutional arrangements',
                    key: 'systemOrganizationalNetworks',
                    legacyKey: '2_2049',
                  },
                ],
              },
              {
                title: 'Commercial authority',
                key: 'systemCommercial',
                legacyKey: '2_2029',
                children: [
                  {
                    title: 'Licensure & registration requirements',
                    key: 'systemCommercialLicensure',
                    legacyKey: '2_2050',
                  },
                  {
                    title: 'Patents & profits',
                    key: 'systemCommercialPatentsProfits',
                    legacyKey: '2_2051',
                  },
                  {
                    title: 'Pricing & purchasing',
                    key: 'systemCommercialPricingPurchasing',
                    legacyKey: '2_2052',
                  },
                  {
                    title: 'Marketing',
                    key: 'systemCommercialMarketing',
                    legacyKey: '2_2053',
                  },
                  {
                    title: 'Sales',
                    key: 'systemCommercialSales',
                    legacyKey: '2_2054',
                  },
                  {
                    title: 'Commercial liability',
                    key: 'systemCommercialLiability',
                    legacyKey: '2_2055',
                  },
                ],
              },
              {
                title: 'Professional authority',
                key: 'systemProfessional',
                legacyKey: '2_2030',
                children: [
                  {
                    title: 'Training & licensure requirements',
                    key: 'systemProfessionalTrainingLicensure',
                    legacyKey: '2_2056',
                  },
                  {
                    title: 'Scope of practice',
                    key: 'systemProfessionalScopePractice',
                    legacyKey: '2_2057',
                  },
                  {
                    title: 'Setting of practice',
                    key: 'systemProfessionalSettingPractice',
                    legacyKey: '2_2058',
                  },
                  {
                    title: 'Continuing competence',
                    key: 'systemProfessionalContinuingCompetence',
                    legacyKey: '2_2059',
                  },
                  {
                    title: 'Quality & safety',
                    key: 'systemProfessionalQualitySafety',
                    legacyKey: '2_2060',
                  },
                  {
                    title: 'Professional liability',
                    key: 'systemProfessionalLiability',
                    legacyKey: '2_2061',
                  },
                  {
                    title: 'Strike/job action',
                    key: 'systemProfessionalStrikeJobAction',
                    legacyKey: '2_2062',
                  },
                ],
              },
              {
                title: 'Citizen & stakeholder involvement',
                key: 'systemCitizen',
                legacyKey: '2_2031',
                children: [
                  {
                    title:
                      'Citizen participation in policy & organizational decisions',
                    key: 'systemCitizenPolicy',
                    legacyKey: '2_2063',
                  },
                  {
                    title: 'Citizen participation in system monitoring',
                    key: 'systemCitizenSystemMonitoring',
                    legacyKey: '2_2064',
                  },
                  {
                    title: 'Citizen participation in service delivery',
                    key: 'systemCitizenServiceDelivery',
                    legacyKey: '2_2065',
                  },
                  {
                    title: 'Citizen complaints management',
                    key: 'systemCitizenComplaintsManagement',
                    legacyKey: '2_2066',
                  },
                  {
                    title:
                      'Stakeholder participation in policy & organizational decisions (or monitoring)',
                    key: 'systemCitizenStakeholderPolicy',
                    legacyKey: '2_2067',
                  },
                ],
              },
            ],
          },
          {
            title: 'Financial arrangements',
            key: 'financialArrangements',
            legacyKey: '2_2025',
            children: [
              {
                title: 'Financing systems',
                key: 'financingSystems',
                legacyKey: '2_2032',
                children: [
                  {
                    title: 'Taxation',
                    key: 'financialTaxation',
                    legacyKey: '2_2068',
                  },
                  {
                    title: 'Social insurance',
                    key: 'financialSocialHealthInsurance',
                    legacyKey: '2_2069',
                  },
                  {
                    title: 'Community-based insurance',
                    key: 'financialCommunityBasedHealthInsurance',
                    legacyKey: '2_2070',
                  },
                  {
                    title: 'Community loan funds',
                    key: 'financialCommunityLoanFunds',
                    legacyKey: '2_2071',
                  },
                  {
                    title: 'Private insurance',
                    key: 'financialPrivateInsurance',
                    legacyKey: '2_2072',
                  },
                  {
                    title: 'Social savings accounts (Individually financed)',
                    key: 'financialHealthSavingsAccountsIndividuallyFinanced',
                    legacyKey: '2_2073',
                  },
                  {
                    title: 'User fees',
                    key: 'financialUserFees',
                    legacyKey: '2_2074',
                  },
                  {
                    title: 'Donor contributions',
                    key: 'financialDonorContributions',
                    legacyKey: '2_2075',
                  },
                  {
                    title: 'Fundraising',
                    key: 'financialFundraising',
                    legacyKey: '2_2076',
                  },
                ],
              },
              {
                title: 'Funding organizations',
                key: 'fundingOrganization',
                legacyKey: '2_2033',
                children: [
                  {
                    title: 'Fee-for-service (Funding)',
                    key: 'fundingOrganizationServiceFees',
                    legacyKey: '2_2077',
                  },
                  {
                    title: 'Capitation (Funding)',
                    key: 'fundingOrganizationCapitation',
                    legacyKey: '2_2078',
                  },
                  {
                    title: 'Global budget',
                    key: 'fundingOrganizationGlobalBudget',
                    legacyKey: '2_2079',
                  },
                  {
                    title: 'Case-mix funding',
                    key: 'fundingOrganizationCaseMixFunding',
                    legacyKey: '2_2080',
                  },
                  {
                    title: 'Targeted payments/penalties (Funding)',
                    key: 'fundingOrganizationPaymentsPenalties',
                    legacyKey: '2_2082',
                  },
                ],
              },
              {
                title: 'Remunerating providers',
                key: 'remuneratingProvider',
                legacyKey: '2_2034',
                children: [
                  {
                    title: 'Fee-for-service (Remuneration)',
                    key: 'remuneratingProviderServiceFees',
                    legacyKey: '2_2083',
                  },
                  {
                    title: 'Capitation (Remuneration)',
                    key: 'remuneratingProviderCapitation',
                    legacyKey: '2_2084',
                  },
                  {
                    title: 'Salary',
                    key: 'remuneratingProviderSalary',
                    legacyKey: '2_2085',
                  },
                  {
                    title: 'Episode-based payment',
                    key: 'remuneratingProviderEpisodePayment',
                    legacyKey: '2_2086',
                  },
                  {
                    title: 'Fundholding',
                    key: 'remuneratingProviderFundholding',
                    legacyKey: '2_2087',
                  },
                  {
                    title: 'Targeted payments/penalties (Remuneration)',
                    key: 'remuneratingProviderPaymentPenalties',
                    legacyKey: '2_2089',
                  },
                ],
              },
              {
                title: 'Purchasing products & services',
                key: 'purchasing',
                legacyKey: '2_2035',
                children: [
                  {
                    title: 'Scope & nature of insurance plans',
                    key: 'purchasingScope',
                    legacyKey: '2_2090',
                  },
                  {
                    title:
                      'Lists of covered/reimbursed organizations, providers, services & products',
                    key: 'purchasingCoveredReimbursedOrganizations',
                    legacyKey: '2_2091',
                  },
                  {
                    title:
                      'Restrictions in coverage/reimbursement rates for organizations, providers, services & products',
                    key: 'purchasingRestrictions',
                    legacyKey: '2_2092',
                  },
                  {
                    title:
                      'Caps on coverage/reimbursement for organizations, providers, services & products',
                    key: 'purchasingCaps',
                    legacyKey: '2_2093',
                  },
                  {
                    title:
                      'Prior approval requirements for organizations, providers, services & products',
                    key: 'purchasingApprovalRequirements',
                    legacyKey: '2_2094',
                  },
                  {
                    title: 'Lists of substitutable services & products',
                    key: 'purchasingSubstitutes',
                    legacyKey: '2_2095',
                  },
                ],
              },
              {
                title: 'Incentivizing citizens',
                key: 'incentivizingCitizen',
                legacyKey: '2_2036',
                children: [
                  {
                    title: 'Premium (level & features)',
                    key: 'incentivizingCitizenPremium',
                    legacyKey: '2_2096',
                  },
                  {
                    title: 'Cost sharing',
                    key: 'incentivizingCitizenCostSharing',
                    legacyKey: '2_2097',
                  },
                  {
                    title:
                      'Social savings accounts (Third party contributions)',
                    key: 'incentivizingCitizenSavingsThirdPartyContributions',
                    legacyKey: '2_2098',
                  },
                  {
                    title:
                      'Targeted payments/penalties (Incentivizing citizens)',
                    key: 'incentivizingCitizenSavingsTargetedPaymentsPenalties',
                    legacyKey: '2_2099',
                  },
                ],
              },
            ],
          },
          {
            title: 'Delivery arrangements',
            key: 'deliveryArrangement',
            legacyKey: '2_2026',
            children: [
              {
                title: 'How services are designed to meet citizens’ needs',
                key: 'deliveryArrangementDesign',
                legacyKey: '2_2037',
                children: [
                  {
                    title: 'Availability of services',
                    key: 'deliveryArrangementDesignAvailability',
                    legacyKey: '2_2100',
                  },
                  {
                    title: 'Timely access to services',
                    key: 'deliveryArrangementDesignTimelyAcess',
                    legacyKey: '2_2101',
                  },
                  {
                    title: 'Culturally appropriate services',
                    key: 'deliveryArrangementDesignCulturallyAppropriate',
                    legacyKey: '2_2102',
                  },
                  {
                    title: 'Case management',
                    key: 'deliveryArrangementDesignCaseManagement',
                    legacyKey: '2_2103',
                  },
                  {
                    title: 'Package of services/service pathways',
                    key: 'deliveryArrangementDesignServicesPackage',
                    legacyKey: '2_2104',
                  },
                  {
                    title: 'Group services',
                    key: 'deliveryArrangementDesignGroup',
                    legacyKey: '2_2105',
                  },
                ],
              },
              {
                title: 'By whom services are provided',
                key: 'serviceProvider',
                legacyKey: '2_2038',
                children: [
                  {
                    title: 'System - Need, demand & supply',
                    key: 'serviceProviderAvailability',
                    legacyKey: '2_2106',
                  },
                  {
                    title: 'System - Recruitment, retention & transitions',
                    key: 'serviceProviderRecruitmentRetention',
                    legacyKey: '2_2107',
                  },
                  {
                    title: 'System - Performance management',
                    key: 'serviceProviderPerformanceManagement',
                    legacyKey: '2_2108',
                  },
                  {
                    title: 'Workplace conditions - Provider satisfaction',
                    key: 'serviceProviderWorkplaceConditionsProviderSatisfaction',
                    legacyKey: '2_2109',
                  },
                  {
                    title: 'Workplace conditions - Health & safety',
                    key: 'serviceProviderWorkplaceConditionsHealthSafety',
                    legacyKey: '2_2110',
                  },
                  {
                    title: 'Skill mix - Role performance',
                    key: 'serviceProviderSkillMixRolePerformance',
                    legacyKey: '2_2111',
                  },
                  {
                    title: 'Skill mix - Role expansion or extension',
                    key: 'serviceProviderSkillMixRoleExpansionOrExtension',
                    legacyKey: '2_2112',
                  },
                  {
                    title: 'Skill mix - Task shifting / substitution',
                    key: 'serviceProviderSkillMixTaskShiftingSubstitution',
                    legacyKey: '2_2113',
                  },
                  {
                    title: 'Skill mix - Multidisciplinary teams',
                    key: 'serviceProviderSkillMixMultidisciplinaryTeams',
                    legacyKey: '2_2114',
                  },
                  {
                    title: 'Skill mix - Volunteers or caregivers',
                    key: 'serviceProviderSkillMixVolunteersOrInformalFamilyCaregivers',
                    legacyKey: '2_2115',
                  },
                  {
                    title:
                      'Skill mix – Communication & case discussion between distant professionals',
                    key: 'serviceProviderSkillMixCommunicationDistantHealthProfessionals',
                    legacyKey: '2_2116',
                  },
                  {
                    title: 'Staff - Training',
                    key: 'serviceProviderStaffTraining',
                    legacyKey: '2_2117',
                  },
                  {
                    title: 'Staff - Support',
                    key: 'serviceProviderStaffSupport',
                    legacyKey: '2_2118',
                  },
                  {
                    title: 'Staff - Workload/workflow/intensity',
                    key: 'serviceProviderStaffWorkloadWorkflowIntensity',
                    legacyKey: '2_2119',
                  },
                  {
                    title: 'Staff - Continuity of services',
                    key: 'serviceProviderStaffContinuityOfCare',
                    legacyKey: '2_2120',
                  },
                  {
                    title: 'Staff/self - Shared decision-making',
                    key: 'serviceProviderStaffSelfSharedDecisionMaking',
                    legacyKey: '2_2121',
                  },
                  {
                    title: 'Self-management',
                    key: 'serviceProviderSelfManagement',
                    legacyKey: '2_2122',
                  },
                ],
              },
              {
                title: 'Where services are provided',
                key: 'serviceLocation',
                legacyKey: '2_2039',
                children: [
                  {
                    title: 'Site of service delivery',
                    key: 'serviceLocationSite',
                    legacyKey: '2_2123',
                  },
                  {
                    title: 'Physical structure, facilities & equipment',
                    key: 'serviceLocationPhysicalStructure',
                    legacyKey: '2_2124',
                  },
                  {
                    title: 'Organizational scale',
                    key: 'serviceLocationOrganizationalScale',
                    legacyKey: '2_2125',
                  },
                  {
                    title: 'Organizational structure',
                    key: 'serviceLocationOrganizationalStructure',
                    legacyKey: '2_2379',
                  },
                  {
                    title: 'Integration of services',
                    key: 'serviceLocationServiceIntegration',
                    legacyKey: '2_2126',
                  },
                  {
                    title: 'Continuity of services',
                    key: 'serviceLocationServiceContinuity',
                    legacyKey: '2_2127',
                  },
                  {
                    title: 'Outreach',
                    key: 'serviceLocationOutreach',
                    legacyKey: '2_2128',
                  },
                ],
              },
              {
                title: 'With what supports are services provided',
                key: 'serviceSupport',
                legacyKey: '2_2040',
                children: [
                  {
                    title: 'Record systems',
                    key: 'serviceSupportHealthRecordSystems',
                    legacyKey: '2_2129',
                  },
                  {
                    title: 'Electronic records',
                    key: 'serviceSupportElectronicHealthRecord',
                    legacyKey: '2_2130',
                  },
                  {
                    title:
                      'Other ICT that support individuals who provide services',
                    key: 'serviceSupportOtherICTThatSupportIndividuals',
                    legacyKey: '2_2131',
                  },
                  {
                    title: 'ICT that support individuals who receive services',
                    key: 'serviceSupportICTThatSupportIndividualsWhoReceiveCare',
                    legacyKey: '2_2132',
                  },
                  {
                    title: 'Financial monitoring and improvement systems',
                    key: 'serviceSupportFinancialMonitoring',
                    legacyKey: '2_2401',
                  },
                  {
                    title: 'Quality monitoring and improvement systems',
                    key: 'serviceSupportQualityMonitoringAndImprovementSystems',
                    legacyKey: '2_2134',
                  },
                  {
                    title: 'Safety monitoring and improvement systems',
                    key: 'serviceSupportSafetyMonitoringAndImprovementSystems',
                    legacyKey: '2_2348',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Implementation strategies',
        key: 'implementationStrategies',
        legacyKey: '2_2003',
        children: [
          {
            title: 'Citizen-targeted strategy',
            key: 'implementationCitizenTargeted',
            legacyKey: '2_2135',
            children: [
              {
                title: 'Information or education provision',
                key: 'implementationCitizenTargetedInformationOrEducationProvision',
                legacyKey: '2_2138',
              },
              {
                title: 'Behaviour change support',
                key: 'implementationCitizenTargetedBehaviourChangeSupport',
                legacyKey: '2_2139',
              },
              {
                title: 'Skills and competencies development',
                key: 'implementationCitizenTargetedSkillsAndCompetenciesDevelopment',
                legacyKey: '2_2140',
              },
              {
                title: '(Personal) Support',
                key: 'implementationCitizenTargetedPersonalSupport',
                legacyKey: '2_2141',
              },
              {
                title: 'Communication and decision-making facilitation',
                key: 'implementationCitizenTargetedCommunicationAndDecisionMakingFacilitation',
                legacyKey: '2_2142',
              },
              {
                title: 'System participation',
                key: 'implementationCitizenTargetedSystemParticipation',
                legacyKey: '2_2143',
              },
            ],
          },
          {
            title: 'Provider-targeted strategy',
            key: 'implementationProviderTargeted',
            legacyKey: '2_2136',
            children: [
              {
                title: 'Educational material',
                key: 'implementationProviderTargetedEducationalMaterial',
                legacyKey: '2_2144',
              },
              {
                title: 'Educational meeting',
                key: 'implementationProviderTargetedEducationalMeeting',
                legacyKey: '2_2145',
              },
              {
                title: 'Educational outreach visit',
                key: 'implementationProviderTargetedEducationalOutreachVisit',
                legacyKey: '2_2146',
              },
              {
                title: 'Local opinion leader',
                key: 'implementationProviderTargetedLocalOpinionLeader',
                legacyKey: '2_2147',
              },
              {
                title: 'Local consensus process',
                key: 'implementationProviderTargetedLocalConsensusProcess',
                legacyKey: '2_2148',
              },
              {
                title: 'Peer review',
                key: 'implementationProviderTargetedPeerReview',
                legacyKey: '2_2149',
              },
              {
                title: 'Audit and feedback',
                key: 'implementationProviderTargetedAuditFeedback',
                legacyKey: '2_2150',
              },
              {
                title: 'Reminders and prompts',
                key: 'implementationProviderTargetedRemindersAndPrompts',
                legacyKey: '2_2151',
              },
              {
                title: 'Tailored intervention',
                key: 'implementationProviderTargetedTailoredIntervention',
                legacyKey: '2_2152',
              },
              {
                title: 'Citizen-mediated intervention',
                key: 'implementationProviderTargetedCitizenMediatedIntervention',
                legacyKey: '2_2380',
              },
              {
                title: 'Multi-faceted intervention',
                key: 'implementationProviderTargetedMultiFacetedIntervention',
                legacyKey: '2_2154',
              },
            ],
          },
          {
            title: 'Organization-targeted strategy',
            key: 'implementationOrganizationTargeted',
            legacyKey: '2_2137',
          },
        ],
      },
      {
        title: 'Sustainable Development Goals',
        key: 'sustainableDevelopmentGoals',
        legacyKey: '2_2382',
        children: [
          {
            title: '1. No poverty',
            key: 'sustainableDevelopmentGoalsNoPoverty',
            legacyKey: '2_2383',
          },
          {
            title: '2. Zero hunger',
            key: 'sustainableDevelopmentGoalsZeroHunger',
            legacyKey: '2_2384',
          },
          {
            title: '3. Good health and well-being (partially covered)',
            key: 'sustainableDevelopmentGoalsGoodHealth',
            legacyKey: '2_2385',
          },
          {
            title: '4. Quality education',
            key: 'sustainableDevelopmentGoalsQualityEducation',
            legacyKey: '2_2386',
          },
          {
            title: '5. Gender equality',
            key: 'sustainableDevelopmentGoalsGenderEquality',
            legacyKey: '2_2387',
          },
          {
            title: '6. Clean water and sanitation',
            key: 'sustainableDevelopmentGoalsCleanWater',
            legacyKey: '2_2388',
          },
          {
            title: '7. Affordable and clean energy',
            key: 'sustainableDevelopmentGoalsAffordableCleanEnergy',
            legacyKey: '2_2389',
          },
          {
            title: '8. Decent work and economic growth',
            key: 'sustainableDevelopmentGoalsDecentWorkEconomicGrowth',
            legacyKey: '2_2390',
          },
          {
            title: '9. Industry, innovation and infrastructure',
            key: 'sustainableDevelopmentGoalsIndustryInnovationInfrastructure',
            legacyKey: '2_2391',
          },
          {
            title: '10. Reduced inequalities',
            key: 'sustainableDevelopmentGoalsReducedInequalities',
            legacyKey: '2_2392',
          },
          {
            title: '11. Sustainable cities and communities',
            key: 'sustainableDevelopmentGoalsSustainableCitiesCommunities',
            legacyKey: '2_2393',
          },
          {
            title: '12. Responsible consumption and production',
            key: 'sustainableDevelopmentGoalsResponsibleConsumptionProduction',
            legacyKey: '2_2394',
          },
          {
            title: '13. Climate action',
            key: 'sustainableDevelopmentGoalsClimateAction',
            legacyKey: '2_2398',
          },
          {
            title: '14. Life below water',
            key: 'sustainableDevelopmentGoalsLifeBelowWater',
            legacyKey: '2_2399',
          },
          {
            title: '15. Life on land',
            key: 'sustainableDevelopmentGoalsLifeOnLand',
            legacyKey: '2_2400',
          },
          {
            title: '16. Peace, justice and strong institutions',
            key: 'sustainableDevelopmentGoalsPeaceJusticeStrongInstitutions',
            legacyKey: '2_2395',
          },
          {
            title: '17. Partnerships for the goals',
            key: 'sustainableDevelopmentGoalsPartnerships',
            legacyKey: '2_2396',
          },
        ],
      },
      {
        title: 'Perspectives',
        key: 'perspectives',
        legacyKey: '0_31',
        children: [
          {
            title: 'Populations',
            key: 'perspectivesPopulations',
            legacyKey: '31_5',
            children: [
              {
                title: 'Children and youth',
                key: 'perspectivesPopulationChildrenYouth',
                legacyKey: '31_164',
              },
              {
                title: 'Ethnocultural minorities',
                key: 'perspectivesPopulationEthnoculturalMinorities',
                legacyKey: '31_165',
              },
              {
                title: 'Linguistic minorities',
                key: 'perspectivesPopulationLinguisticMinorities',
                legacyKey: '31_167',
              },
              {
                title: 'Immigrants and refugees',
                key: 'perspectivesPopulationImigrantsRefugees',
                legacyKey: '31_168',
              },
              {
                title: 'Indigenous peoples',
                key: 'perspectivesPopulationIndigenousPeoples',
                legacyKey: '31_169',
              },
              {
                title: 'Individuals who are homeless or marginally housed',
                key: 'perspectivesPopulationHomeless',
                legacyKey: '31_170',
              },
              {
                title: 'LGBTQ',
                key: 'perspectivesPopulationLGBTQ',
                legacyKey: '31_171',
              },
              {
                title: 'Military personnel, Veterans and their families',
                key: 'populationMilitaryPersonnelVeteransAndFamily',
                legacyKey: '31_199',
              },
              {
                title: 'Older adults',
                key: 'perspectivesPopulationOlderAdults',
                legacyKey: '31_166',
              },
              {
                title: 'People living in rural and remote communities',
                key: 'perspectivesPopulationRuralRemote',
                legacyKey: '31_172',
              },
              {
                title: 'People with disabilities',
                key: 'perspectivesPopulationDisabilities',
                legacyKey: '31_173',
              },
            ],
          },
          {
            title: 'Outcomes',
            key: 'outcomesProvider',
            legacyKey: '31_7',
            children: [
              {
                title: 'Economic',
                key: 'outcomesProviderEconomic',
                legacyKey: '31_175',
              },
              {
                title: 'Education',
                key: 'outcomesProviderEducation',
                legacyKey: '31_343',
              },
              {
                title: 'Employment',
                key: 'outcomesProviderEmployment',
                legacyKey: '31_344',
              },
              {
                title: 'Health',
                key: 'outcomesProviderHealth',
                legacyKey: '31_174',
              },
              {
                title: 'Housing',
                key: 'outcomesProviderHousing',
                legacyKey: '31_176',
              },
              {
                title: 'Other sector/area-specific outcomes',
                key: 'outcomesProviderOther',
                legacyKey: '31_381',
              },
              {
                title: 'Social inclusion',
                key: 'outcomesProviderSocialInclusion',
                legacyKey: '31_345',
              },
              {
                title: 'Well-being',
                key: 'outcomesProviderWellBeing',
                legacyKey: '31_346',
              },
            ],
          },
          {
            title: 'Disciplines',
            key: 'perspectivesDisciplines',
            legacyKey: '31_8',
            children: [
              {
                title: 'Anthropology',
                key: 'perspectivesDisciplineAnthropology',
                legacyKey: '31_180',
              },
              {
                title: 'Arts',
                key: 'perspectivesDisciplineArts',
                legacyKey: '31_179',
              },
              {
                title: 'Business administration',
                key: 'perspectivesDisciplineBusinessAdministration',
                legacyKey: '31_181',
              },
              {
                title: 'Communications',
                key: 'perspectivesDisciplineCommunication',
                legacyKey: '31_182',
              },
              {
                title: 'Criminology',
                key: 'perspectivesDisciplineCriminology',
                legacyKey: '31_183',
              },
              {
                title: 'Economics',
                key: 'perspectivesDisciplineEconomics',
                legacyKey: '31_184',
              },
              {
                title: 'Engineering',
                key: 'perspectivesDisciplineEngineering',
                legacyKey: '31_185',
              },
              {
                title: 'Geography',
                key: 'perspectivesDisciplineGeography',
                legacyKey: '31_186',
              },
              {
                title: 'Gerontology',
                key: 'perspectivesDisciplineGerontology',
                legacyKey: '31_187',
              },
              {
                title: 'History',
                key: 'perspectivesDisciplineHistory',
                legacyKey: '31_188',
              },
              {
                title: 'Law',
                key: 'perspectivesDisciplineLaw',
                legacyKey: '31_189',
              },
              {
                title: 'Philosophy',
                key: 'perspectivesDisciplinePhilosophy',
                legacyKey: '31_190',
              },
              {
                title: 'Political science',
                key: 'perspectivesDisciplinePoliticalScience',
                legacyKey: '31_191',
              },
              {
                title: 'Psychology',
                key: 'perspectivesDisciplinePsychology',
                legacyKey: '31_193',
              },
              {
                title: 'Public administration',
                key: 'perspectivesDisciplinePublicAdministration',
                legacyKey: '31_192',
              },
              {
                title: 'Social work',
                key: 'perspectivesDisciplineSocialWork',
                legacyKey: '31_194',
              },
              {
                title: 'Sociology',
                key: 'perspectivesDisciplineSociology',
                legacyKey: '31_193',
              },
            ],
          },
        ],
      },
    ],
  },
  checkedLMIC: {
    title: 'LMIC Focus',
    legacyKey: '0_9',
    items: [
      {
        title: 'Target of document',
        key: 'lmicTargetOfDocument',
        legacyKey: '9_1',
      },
      {
        title: 'At least one LMIC author',
        key: 'lmicAtLeastOneAuthor',
        legacyKey: '9_2',
      },
      {
        title: 'At least one LMIC study included',
        key: 'lmicAtLeastOneStudy',
        legacyKey: '9_3',
      },
    ],
  } /*
  checkedTheme: {
    title: 'Theme',
    legacyKey: '0_15',
    items: [
      { title: 'Optimal aging', key: 'themeOptimalAging', legacyKey: '15_1' },
    ],
  } 
  checkedAge: {
    title: 'Age',
    items: [
      { title: 'Neonates (birth to 1 month)', key: 'ageNeonates' },
      {
        title: 'Pediatrics (>1 month to <13 years)',
        key: 'agePediatrics',
      },
      {
        title: 'Adolescents (13 years to <19 years)',
        key: 'ageAdolescents',
      },
      {
        title: 'Adults (19 years to <60 years)',
        key: 'ageAdults',
      },
      { title: 'Geriatrics (>=60 years)', key: 'ageGeriatrics' },
    ],
  },
  checkedTarget: {
    title: 'Target',
    items: [
      { title: 'Individual', key: 'targetIndividual' },
      { title: 'Family/household', key: 'targetFamily' },
      { title: 'Community', key: 'targetCommunity' },
      { title: 'System', key: 'targetSystem' },
    ],
  },*/,
  countries: {
    title: 'Area of focus',
    legacyKey: '0_3',
    items: [
      {
        title: 'Countries',
        key: 'areaOfFocusCountries',
        legacyKey: '0_6',
        children: [
          {
            title: 'Afghanistan',
            key: 'countryAfghanistan',
            legacyKey: '6_1',
          },
          {
            title: 'Albania',
            key: 'countryAlbania',
            legacyKey: '6_2',
          },
          {
            title: 'Algeria',
            key: 'countryAlgeria',
            legacyKey: '6_3',
          },
          {
            title: 'Andorra',
            key: 'countryAndorra',
            legacyKey: '6_4',
          },
          {
            title: 'Angola',
            key: 'countryAngola',
            legacyKey: '6_5',
          },
          {
            title: 'Antigua and Barbuda',
            key: 'countryAntiguaAndBarbuda',
            legacyKey: '6_6',
          },
          {
            title: 'Argentina',
            key: 'countryArgentina',
            legacyKey: '6_7',
          },
          {
            title: 'Armenia',
            key: 'countryArmenia',
            legacyKey: '6_8',
          },
          {
            title: 'Australia',
            key: 'countryAustralia',
            legacyKey: '6_9',
          },
          {
            title: 'Austria',
            key: 'countryAustria',
            legacyKey: '6_10',
          },
          {
            title: 'Azerbaijan',
            key: 'countryAzerbaijan',
            legacyKey: '6_11',
          },
          {
            title: 'Bahamas',
            key: 'countryBahamas',
            legacyKey: '6_12',
          },
          {
            title: 'Bahrain',
            key: 'countryBahrain',
            legacyKey: '6_13',
          },
          {
            title: 'Bangladesh',
            key: 'countryBangladesh',
            legacyKey: '6_14',
          },
          {
            title: 'Barbados',
            key: 'countryBarbados',
            legacyKey: '6_15',
          },
          {
            title: 'Belarus',
            key: 'countryBelarus',
            legacyKey: '6_16',
          },
          {
            title: 'Belgium',
            key: 'countryBelgium',
            legacyKey: '6_17',
          },
          {
            title: 'Belize',
            key: 'countryBelize',
            legacyKey: '6_18',
          },
          {
            title: 'Benin',
            key: 'countryBenin',
            legacyKey: '6_19',
          },
          {
            title: 'Bhutan',
            key: 'countryBhutan',
            legacyKey: '6_20',
          },
          {
            title: 'Bolivia (Plurinational State of)',
            key: 'countryBolivia',
            legacyKey: '6_21',
          },
          {
            title: 'Bosnia and Herzegovina',
            key: 'countryBosniaAndHerzegovina',
            legacyKey: '6_22',
          },
          {
            title: 'Botswana',
            key: 'countryBotswana',
            legacyKey: '6_23',
          },
          {
            title: 'Brazil',
            key: 'countryBrazil',
            legacyKey: '6_24',
          },
          {
            title: 'Brunei Darussalam',
            key: 'countryBruneiDarussalam',
            legacyKey: '6_25',
          },
          {
            title: 'Bulgaria',
            key: 'countryBulgaria',
            legacyKey: '6_26',
          },
          {
            title: 'Burkina Faso',
            key: 'countryBurkinaFaso',
            legacyKey: '6_27',
          },
          {
            title: 'Burundi',
            key: 'countryBurundi',
            legacyKey: '6_28',
          },
          {
            title: 'Cambodia',
            key: 'countryCambodia',
            legacyKey: '6_29',
          },
          {
            title: 'Cameroon',
            key: 'countryCameroon',
            legacyKey: '6_30',
          },
          {
            title: 'Canada',
            key: 'countryCanada',
            legacyKey: '6_31',
          },
          {
            title: 'Cape Verde',
            key: 'countryCapeVerde',
            legacyKey: '6_32',
          },
          {
            title: 'Central African Republic',
            key: 'countryCentralAfricanRepublic',
            legacyKey: '6_33',
          },
          {
            title: 'Chad',
            key: 'countryChad',
            legacyKey: '6_34',
          },
          {
            title: 'Chile',
            key: 'countryChile',
            legacyKey: '6_35',
          },
          {
            title: 'China',
            key: 'countryChina',
            legacyKey: '6_36',
          },
          {
            title: 'Colombia',
            key: 'countryColombia',
            legacyKey: '6_37',
          },
          {
            title: 'Comoros',
            key: 'countryComoros',
            legacyKey: '6_38',
          },
          {
            title: 'Congo (Brazzaville)',
            key: 'countryCongo',
            legacyKey: '6_39',
          },
          {
            title: 'Costa Rica',
            key: 'countryCostaRica',
            legacyKey: '6_40',
          },
          {
            title: "Côte d'Ivoire",
            key: 'countryCotedIvoire',
            legacyKey: '6_41',
          },
          {
            title: 'Croatia',
            key: 'countryCroatia',
            legacyKey: '6_42',
          },
          {
            title: 'Cuba',
            key: 'countryCuba',
            legacyKey: '6_43',
          },
          {
            title: 'Cyprus',
            key: 'countryCyprus',
            legacyKey: '6_44',
          },
          {
            title: 'Czech Republic',
            key: 'countryCzechRepublic',
            legacyKey: '6_45',
          },
          {
            title: "Korea (Democratic People's Republic of)",
            key: 'countryKoreaDPR',
            legacyKey: '6_46',
          },
          {
            title: 'Congo (Democratic Republic of)',
            key: 'countryCongoDPR',
            legacyKey: '6_47',
          },
          {
            title: 'Denmark',
            key: 'countryDenmark',
            legacyKey: '6_48',
          },
          {
            title: 'Djibouti',
            key: 'countryDjibouti',
            legacyKey: '6_49',
          },
          {
            title: 'Dominica',
            key: 'countryDominica',
            legacyKey: '6_50',
          },
          {
            title: 'Dominican Republic',
            key: 'countryDominicanRepublic',
            legacyKey: '6_51',
          },
          {
            title: 'Ecuador',
            key: 'countryEcuador',
            legacyKey: '6_52',
          },
          {
            title: 'Egypt',
            key: 'countryEgypt',
            legacyKey: '6_53',
          },
          {
            title: 'El Salvador',
            key: 'countryElSalvador',
            legacyKey: '6_54',
          },
          {
            title: 'Equatorial Guinea',
            key: 'countryEquatorialGuinea',
            legacyKey: '6_55',
          },
          {
            title: 'Eritrea',
            key: 'countryEritrea',
            legacyKey: '6_56',
          },
          {
            title: 'Estonia',
            key: 'countryEstonia',
            legacyKey: '6_57',
          },
          {
            title: 'Ethiopia',
            key: 'countryEthiopia',
            legacyKey: '6_58',
          },
          {
            title: 'Fiji',
            key: 'countryFiji',
            legacyKey: '6_59',
          },
          {
            title: 'Finland',
            key: 'countryFinland',
            legacyKey: '6_60',
          },
          {
            title: 'France',
            key: 'countryFrance',
            legacyKey: '6_61',
          },
          {
            title: 'Gabon',
            key: 'countryGabon',
            legacyKey: '6_62',
          },
          {
            title: 'Gambia',
            key: 'countryGambia',
            legacyKey: '6_63',
          },
          {
            title: 'Georgia',
            key: 'countryGeorgia',
            legacyKey: '6_64',
          },
          {
            title: 'Germany',
            key: 'countryGermany',
            legacyKey: '6_65',
          },
          {
            title: 'Ghana',
            key: 'countryGhana',
            legacyKey: '6_66',
          },
          {
            title: 'Greece',
            key: 'countryGreece',
            legacyKey: '6_67',
          },
          {
            title: 'Grenada',
            key: 'countryGrenada',
            legacyKey: '6_68',
          },
          {
            title: 'Guatemala',
            key: 'countryGuatemala',
            legacyKey: '6_69',
          },
          {
            title: 'Guinea',
            key: 'countryGuinea',
            legacyKey: '6_70',
          },
          {
            title: 'Guinea-Bissau',
            key: 'countryGuineaBissau',
            legacyKey: '6_71',
          },
          {
            title: 'Guyana',
            key: 'countryGuyana',
            legacyKey: '6_72',
          },
          {
            title: 'Haiti',
            key: 'countryHaiti',
            legacyKey: '6_73',
          },
          {
            title: 'Honduras',
            key: 'countryHonduras',
            legacyKey: '6_74',
          },
          {
            title: 'Hungary',
            key: 'countryHungary',
            legacyKey: '6_75',
          },
          {
            title: 'Iceland',
            key: 'countryIceland',
            legacyKey: '6_76',
          },
          {
            title: 'India',
            key: 'countryIndia',
            legacyKey: '6_77',
          },
          {
            title: 'Indonesia',
            key: 'countryIndonesia',
            legacyKey: '6_78',
          },
          {
            title: 'Iran (Islamic Republic of)',
            key: 'countryIran',
            legacyKey: '6_79',
          },
          {
            title: 'Iraq',
            key: 'countryIraq',
            legacyKey: '6_80',
          },
          {
            title: 'Ireland',
            key: 'countryIreland',
            legacyKey: '6_81',
          },
          {
            title: 'Israel',
            key: 'countryIsrael',
            legacyKey: '6_82',
          },
          {
            title: 'Italy',
            key: 'countryItaly',
            legacyKey: '6_83',
          },
          {
            title: 'Jamaica',
            key: 'countryJamaica',
            legacyKey: '6_84',
          },
          {
            title: 'Japan',
            key: 'countryJapan',
            legacyKey: '6_85',
          },
          {
            title: 'Jordan',
            key: 'countryJordan',
            legacyKey: '6_86',
          },
          {
            title: 'Kazakhstan',
            key: 'countryKazakhstan',
            legacyKey: '6_87',
          },
          {
            title: 'Kenya',
            key: 'countryKenya',
            legacyKey: '6_88',
          },
          {
            title: 'Kiribati',
            key: 'countryKiribati',
            legacyKey: '6_89',
          },
          {
            title: 'Kuwait',
            key: 'countryKuwait',
            legacyKey: '6_90',
          },
          {
            title: 'Kyrgyzstan',
            key: 'countryKyrgyzstan',
            legacyKey: '6_91',
          },
          {
            title: "Lao People's Democratic Republic",
            key: 'countryLao',
            legacyKey: '6_92',
          },
          {
            title: 'Latvia',
            key: 'countryLatvia',
            legacyKey: '6_93',
          },
          {
            title: 'Lebanon',
            key: 'countryLebanon',
            legacyKey: '6_94',
          },
          {
            title: 'Lesotho',
            key: 'countryLesotho',
            legacyKey: '6_95',
          },
          {
            title: 'Liberia',
            key: 'countryLiberia',
            legacyKey: '6_96',
          },
          {
            title: 'Libya',
            key: 'countryLibya',
            legacyKey: '6_97',
          },
          {
            title: 'Liechtenstein',
            key: 'countryLiechtenstein',
            legacyKey: '6_98',
          },
          {
            title: 'Lithuania',
            key: 'countryLithuania',
            legacyKey: '6_99',
          },
          {
            title: 'Luxembourg',
            key: 'countryLuxembourg',
            legacyKey: '6_100',
          },
          {
            title: 'Madagascar',
            key: 'countryMadagascar',
            legacyKey: '6_101',
          },
          {
            title: 'Malawi',
            key: 'countryMalawi',
            legacyKey: '6_102',
          },
          {
            title: 'Malaysia',
            key: 'countryMalaysia',
            legacyKey: '6_103',
          },
          {
            title: 'Maldives',
            key: 'countryMaldives',
            legacyKey: '6_104',
          },
          {
            title: 'Mali',
            key: 'countryMali',
            legacyKey: '6_105',
          },
          {
            title: 'Malta',
            key: 'countryMalta',
            legacyKey: '6_106',
          },
          {
            title: 'Marshall Islands',
            key: 'countryMarshallIslands',
            legacyKey: '6_107',
          },
          {
            title: 'Mauritania',
            key: 'countryMauritania',
            legacyKey: '6_108',
          },
          {
            title: 'Mauritius',
            key: 'countryMauritius',
            legacyKey: '6_109',
          },
          {
            title: 'Mexico',
            key: 'countryMexico',
            legacyKey: '6_110',
          },
          {
            title: 'Micronesia (Federated States of)',
            key: 'countryMicronesia',
            legacyKey: '6_111',
          },
          {
            title: 'Monaco',
            key: 'countryMonaco',
            legacyKey: '6_112',
          },
          {
            title: 'Mongolia',
            key: 'countryMongolia',
            legacyKey: '6_113',
          },
          {
            title: 'Montenegro',
            key: 'countryMontenegro',
            legacyKey: '6_114',
          },
          {
            title: 'Morocco',
            key: 'countryMorocco',
            legacyKey: '6_115',
          },
          {
            title: 'Mozambique',
            key: 'countryMozambique',
            legacyKey: '6_116',
          },
          {
            title: 'Myanmar',
            key: 'countryMyanmar',
            legacyKey: '6_117',
          },
          {
            title: 'Namibia',
            key: 'countryNamibia',
            legacyKey: '6_118',
          },
          {
            title: 'Nauru',
            key: 'countryNauru',
            legacyKey: '6_119',
          },
          {
            title: 'Nepal',
            key: 'countryNepal',
            legacyKey: '6_120',
          },
          {
            title: 'Netherlands',
            key: 'countryNetherlands',
            legacyKey: '6_121',
          },
          {
            title: 'New Zealand',
            key: 'countryNewZealand',
            legacyKey: '6_122',
          },
          {
            title: 'Nicaragua',
            key: 'countryNicaragua',
            legacyKey: '6_123',
          },
          {
            title: 'Niger',
            key: 'countryNiger',
            legacyKey: '6_124',
          },
          {
            title: 'Nigeria',
            key: 'countryNigeria',
            legacyKey: '6_125',
          },
          {
            title: 'Norway',
            key: 'countryNorway',
            legacyKey: '6_126',
          },
          {
            title: 'Oman',
            key: 'countryOman',
            legacyKey: '6_127',
          },
          {
            title: 'Pakistan',
            key: 'countryPakistan',
            legacyKey: '6_128',
          },
          {
            title: 'Palau',
            key: 'countryPalau',
            legacyKey: '6_129',
          },
          {
            title: 'Panama',
            key: 'countryPanama',
            legacyKey: '6_130',
          },
          {
            title: 'Papua New Guinea',
            key: 'countryPapuaNewGuinea',
            legacyKey: '6_131',
          },
          {
            title: 'Paraguay',
            key: 'countryParaguay',
            legacyKey: '6_132',
          },
          {
            title: 'Peru',
            key: 'countryPeru',
            legacyKey: '6_133',
          },
          {
            title: 'Philippines',
            key: 'countryPhilippines',
            legacyKey: '6_134',
          },
          {
            title: 'Poland',
            key: 'countryPoland',
            legacyKey: '6_135',
          },
          {
            title: 'Portugal',
            key: 'countryPortugal',
            legacyKey: '6_136',
          },
          {
            title: 'Qatar',
            key: 'countryQatar',
            legacyKey: '6_137',
          },
          {
            title: 'Korea (Republic of)',
            key: 'countryKorea',
            legacyKey: '6_138',
          },
          {
            title: 'Moldova (Republic of)',
            key: 'countryMoldova',
            legacyKey: '6_139',
          },
          {
            title: 'Romania',
            key: 'countryRomania',
            legacyKey: '6_140',
          },
          {
            title: 'Russian Federation',
            key: 'countryRussianFederation',
            legacyKey: '6_141',
          },
          {
            title: 'Rwanda',
            key: 'countryRwanda',
            legacyKey: '6_142',
          },
          {
            title: 'Saint Kitts and Nevis',
            key: 'countrySaintKittsAndNevis',
            legacyKey: '6_143',
          },
          {
            title: 'Saint Lucia',
            key: 'countrySaintLucia',
            legacyKey: '6_144',
          },
          {
            title: 'Saint Vincent and the Grenadines',
            key: 'countrySaintVincentAndTheGrenadines',
            legacyKey: '6_145',
          },
          {
            title: 'Samoa',
            key: 'countrySamoa',
            legacyKey: '6_146',
          },
          {
            title: 'San Marino',
            key: 'countrySanMarino',
            legacyKey: '6_147',
          },
          {
            title: 'Sao Tome and Principe',
            key: 'countrySaoTomeAndPrincipe',
            legacyKey: '6_148',
          },
          {
            title: 'Saudi Arabia',
            key: 'countrySaudiArabia',
            legacyKey: '6_149',
          },
          {
            title: 'Senegal',
            key: 'countrySenegal',
            legacyKey: '6_150',
          },
          {
            title: 'Serbia',
            key: 'countrySerbia',
            legacyKey: '6_151',
          },
          {
            title: 'Seychelles',
            key: 'countrySeychelles',
            legacyKey: '6_152',
          },
          {
            title: 'Sierra Leone',
            key: 'countrySierraLeone',
            legacyKey: '6_153',
          },
          {
            title: 'Singapore',
            key: 'countrySingapore',
            legacyKey: '6_154',
          },
          {
            title: 'Slovakia',
            key: 'countrySlovakia',
            legacyKey: '6_155',
          },
          {
            title: 'Slovenia',
            key: 'countrySlovenia',
            legacyKey: '6_156',
          },
          {
            title: 'Solomon Islands',
            key: 'countrySolomonIslands',
            legacyKey: '6_157',
          },
          {
            title: 'Somalia',
            key: 'countrySomalia',
            legacyKey: '6_158',
          },
          {
            title: 'South Africa',
            key: 'countrySouthAfrica',
            legacyKey: '6_159',
          },
          {
            title: 'Spain',
            key: 'countrySpain',
            legacyKey: '6_160',
          },
          {
            title: 'Sri Lanka',
            key: 'countrySriLanka',
            legacyKey: '6_161',
          },
          {
            title: 'Suriname',
            key: 'countrySuriname',
            legacyKey: '6_163',
          },
          {
            title: 'Swaziland',
            key: 'countrySwaziland',
            legacyKey: '6_164',
          },
          {
            title: 'Switzerland',
            key: 'countrySwitzerland',
            legacyKey: '6_165',
          },
          {
            title: 'Sweden',
            key: 'countrySweden',
            legacyKey: '6_166',
          },
          {
            title: 'Syrian Arab Republic',
            key: 'countrySyrianArabRepublic',
            legacyKey: '6_167',
          },
          {
            title: 'Tajikistan',
            key: 'countryTajikistan',
            legacyKey: '6_168',
          },
          {
            title: 'Thailand',
            key: 'countryThailand',
            legacyKey: '6_169',
          },
          {
            title: 'Macedonia (The former Yugoslav Republic of)',
            key: 'countryMacedonia',
            legacyKey: '6_170',
          },
          {
            title: 'Timor-Leste',
            key: 'countryTimorLeste',
            legacyKey: '6_171',
          },
          {
            title: 'Togo',
            key: 'countryTogo',
            legacyKey: '6_172',
          },
          {
            title: 'Tonga',
            key: 'countryTonga',
            legacyKey: '6_173',
          },
          {
            title: 'Trinidad and Tobago',
            key: 'countryTrinidadAndTobago',
            legacyKey: '6_174',
          },
          {
            title: 'Tunisia',
            key: 'countryTunisia',
            legacyKey: '6_175',
          },
          {
            title: 'Turkey',
            key: 'countryTurkey',
            legacyKey: '6_176',
          },
          {
            title: 'Turkmenistan',
            key: 'countryTurkmenistan',
            legacyKey: '6_177',
          },
          {
            title: 'Tuvalu',
            key: 'countryTuvalu',
            legacyKey: '6_178',
          },
          {
            title: 'Uganda',
            key: 'countryUganda',
            legacyKey: '6_179',
          },
          {
            title: 'Ukraine',
            key: 'countryUkraine',
            legacyKey: '6_180',
          },
          {
            title: 'United Arab Emirates',
            key: 'countryUnitedArabEmirates',
            legacyKey: '6_181',
          },
          {
            title: 'United Kingdom',
            key: 'countryUnitedKingdom',
            legacyKey: '6_182',
          },
          {
            title: 'Tanzania (United Republic of)',
            key: 'countryTanzania',
            legacyKey: '6_183',
          },
          {
            title: 'United States',
            key: 'countryUnitedStates',
            legacyKey: '6_184',
          },
          {
            title: 'Uruguay',
            key: 'countryUruguay',
            legacyKey: '6_185',
          },
          {
            title: 'Uzbekistan',
            key: 'countryUzbekistan',
            legacyKey: '6_186',
          },
          {
            title: 'Vanuatu',
            key: 'countryVanuatu',
            legacyKey: '6_187',
          },
          {
            title: 'Venezuela (Bolivarian Republic of)',
            key: 'countryVenezuela',
            legacyKey: '6_188',
          },
          {
            title: 'Viet Nam',
            key: 'countryVietNam',
            legacyKey: '6_189',
          },
          {
            title: 'Yemen',
            key: 'countryYemen',
            legacyKey: '6_190',
          },
          {
            title: 'Zambia',
            key: 'countryZambia',
            legacyKey: '6_191',
          },
          {
            title: 'Zimbabwe',
            key: 'countryZimbabwe',
            legacyKey: '6_192',
          },
          {
            title: 'Bermuda',
            key: 'countryBermuda',
            legacyKey: '6_193',
          },
          {
            title: 'French Polynesia',
            key: 'countryFrenchPolynesia',
            legacyKey: '6_194',
          },
          {
            title: 'Hong Kong',
            key: 'countryHongKong',
            legacyKey: '6_195',
          },
          {
            title: 'Kosovo',
            key: 'countryKosovo',
            legacyKey: '6_196',
          },
          {
            title: 'Northern Mariana Islands',
            key: 'countryNorthernMarianaIslands',
            legacyKey: '6_197',
          },
          {
            title: 'Palestine',
            key: 'countryPalestine',
            legacyKey: '6_198',
          },
          {
            title: 'Puerto Rico',
            key: 'countryPuertoRico',
            legacyKey: '6_199',
          },
          {
            title: 'Taiwan',
            key: 'countryTaiwan',
            legacyKey: '6_200',
          },
          {
            title: 'Anguilla',
            key: 'countryAnguilla',
            legacyKey: '6_253',
          },
          {
            title: 'Cook Islands',
            key: 'countryCookIslands',
            legacyKey: '6_255',
          },
          {
            title: 'Niue',
            key: 'countryNiue',
            legacyKey: '6_259',
          },
          {
            title: 'South Sudan',
            key: 'countrySouthSudan',
            legacyKey: '6_266',
          },
        ],
      },
      {
        title: 'Country groupings',
        key: 'areaOfFocusCountryGroupings',
        legacyKey: '0_7',
        children: [
          {
            title: 'ASEAN',
            key: 'areaOfFocusCountryGroupingsASEAN',
            legacyKey: '7_267',
          },
          {
            title: 'BRICS',
            key: 'areaOfFocusCountryGroupingsBRICS',
            legacyKey: '7_268',
          },
          {
            title: 'CARICOM',
            key: 'areaOfFocusCountryGroupingsCARICOM',
            legacyKey: '7_269',
          },
          {
            title: 'Commonwealth',
            key: 'areaOfFocusCountryGroupingsCommonwealth',
            legacyKey: '7_270',
          },
          {
            title: 'Francophonie',
            key: 'areaOfFocusCountryGroupingsFrancophonie',
            legacyKey: '7_271',
          },
          {
            title: 'G7',
            key: 'areaOfFocusCountryGroupingsG7',
            legacyKey: '7_272',
          },
          {
            title: 'G20',
            key: 'areaOfFocusCountryGroupingsG20',
            legacyKey: '7_273',
          },
          {
            title: 'EFTA',
            key: 'areaOfFocusCountryGroupingsEFTA',
            legacyKey: '7_274',
          },
          {
            title: 'European Union',
            key: 'areaOfFocusCountryGroupingsEU',
            legacyKey: '7_275',
          },
          {
            title: 'OPEC',
            key: 'areaOfFocusCountryGroupingsOPEC',
            legacyKey: '7_276',
          },
          {
            title: 'SAARC',
            key: 'areaOfFocusCountryGroupingsSAARC',
            legacyKey: '7_277',
          },
          {
            title: 'OECD',
            key: 'areaOfFocusCountryGroupingsOECD',
            legacyKey: '7_221',
          },
        ],
      },
      {
        title: 'WHO regions',
        key: 'areaOfFocusWhoRegions',
        legacyKey: '0_8',
        children: [
          {
            title: 'WHO - African region',
            key: 'areaOfFocusWhoRegionAfrican',
            legacyKey: '8_1',
          },
          {
            title: 'WHO - Americas region',
            key: 'areaOfFocusWhoRegionAmericas',
            legacyKey: '8_2',
          },
          {
            title: 'WHO - Eastern Mediterranean region',
            key: 'areaOfFocusWhoRegionEasternMediterranean',
            legacyKey: '8_4',
          },
          {
            title: 'WHO - South East Asia region',
            key: 'areaOfFocusWhoRegionSouthEastAsia',
            legacyKey: '8_8',
          },
          {
            title: 'WHO - Western Pacific region',
            key: 'areaOfFocusWhoRegionWesternPacific',
            legacyKey: '8_16',
          },
          {
            title: 'WHO - European region',
            key: 'areaOfFocusWhoRegionEuropean',
            legacyKey: '8_32',
          },
        ],
      },
    ],
  },
  features: {
    title: 'Document features',
    legacyKey: '0_4',
    items: [
      {
        title: 'Producer',
        key: 'docFeatureProducer',
        legacyKey: '0_16',
        children: [
          {
            title: 'Campbell Collaboration',
            key: 'docFeatureCampbellCollaboration',
            legacyKey: '0_35',
          },
          {
            title: 'Cochrane Collaboration',
            key: 'docFeatureCochraneCollaboration',
            legacyKey: '0_28',
          },
        ],
      },
      {
        title: 'Living',
        key: 'docFeatureLiving',
        legacyKey: '0_99',
      },
      {
        title: 'Type',
        key: 'docFeatureType',
        legacyKey: '0_10',
        children: [
          {
            title: 'Evidence briefs for policy',
            key: 'docFeatureTypeEvidenceBriefs',
            legacyKey: '10_1',
          },
          {
            title: 'Overviews of evidence syntheses',
            key: 'docFeatureTypeSystematicReviews',
            legacyKey: '10_2',
          },
          {
            title: 'Evidence syntheses of effects',
            key: 'docFeatureTypeSystematicReviewsEffects',
            legacyKey: '10_3',
          },
          {
            title: 'Evidence syntheses addressing other questions',
            key: 'docFeatureTypeSystematicReviewsOther',
            legacyKey: '10_4',
          },
          {
            title: 'Evidence syntheses in progress',
            key: 'docFeatureTypeSystematicReviewsInProgress',
            legacyKey: '10_5',
          },
          {
            title: 'Evidence syntheses being planned',
            key: 'docFeatureTypeSystematicReviewsPlanned',
            legacyKey: '10_6',
          },
          {
            title: 'Economic evaluations and costing studies',
            key: 'docFeatureTypeEconomicEvaluations',
            legacyKey: '10_7',
          },
        ],
      },
      {
        title: 'Free full-text',
        key: 'docFeatureFreeFullText',
        legacyKey: '0_11',
        children: [
          {
            title: 'Limit to records with free full-text link',
            key: 'docFeatureTypeFreeFullText',
            legacyKey: '0_27',
          },
        ],
      },
      {
        title: 'Date range',
        key: 'docFeatureDateRange',
        legacyKey: '0_12',
        type: 'dateRange',
      },
      // {
      //   title: 'Focus',
      //   key: 'docFeatureFocus',
      //   legacyKey: '0_13',
      //   children: [
      //     {
      //       title: 'General',
      //       key: 'docFeatureFocusGeneral',
      //       legacyKey: '13_1',
      //     },
      //     {
      //       title: 'Specific',
      //       key: 'docFeatureFocusSpecific',
      //       legacyKey: '13_2',
      //     },
      //   ],
      // },
      {
        title: 'Theme',
        key: 'docFeatureTheme',
        legacyKey: '0_15',
        children: [
          {
            title: 'Optimal aging',
            key: 'docFeatureThemeOptimalAging',
            legacyKey: '15_1',
          },
        ],
      },
    ],
  },
};

export default {
  types,
  questionTypes,
  tree,
};
