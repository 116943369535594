import React, { useState } from 'react';
import { debounce } from 'underscore';
import Autosuggest from 'react-autosuggest';
import Context from './Context';
import SearchService from '../services/SearchService';
import Button from './Button';
import useQueryString from '../components/useQueryString';

const SearchBar = ({
  onSearch,
  onBest = () => {},
  onQuery = () => {},
  background = false,
  site,
  language,
  t,
}) => {
  const Search = SearchService();
  const [best, setBest] = useQueryString('best', false);
  const [query, setQuery] = useQueryString('q', '');

  const [suggestions, setSuggestions] = useState([]);
  const [count, setCount] = useState(0);

  const fetchSuggestions = ({ value }) => {
    Search.suggestions(value, site, language)
      .then((res) => {
        console.log(res);
        if (res && res.success) {
          setSuggestions(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (_event, { newValue }) => {
    setQuery(newValue);
    onQuery(newValue);
  };

  const renderSuggestion = (suggestion, input) => {
    const index = suggestion.query.toLowerCase().indexOf(input.toLowerCase());

    let suggestionCount = '';
    if (suggestion.count)
      suggestionCount = (
        <span className="suggestion-count">({suggestion.count})</span>
      );

    if (index >= 0) {
      const start = suggestion.query.slice(0, index);
      const match = suggestion.query.slice(index, index + input.length);
      const end = suggestion.query.slice(index + input.length);
      return (
        <span>
          {start}
          <strong>{match}</strong>
          {end}
          {suggestionCount}
        </span>
      );
    } else {
      return (
        <span>
          {suggestion.query}
          {suggestionCount}
        </span>
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(query, false);
  };

  const dismissKeyboard = () => document.activeElement.blur();
  const getSuggestionValue = (suggestion) => {
    console.log(suggestion);
    return suggestion.query;
  };

  const clearInput = () => {
    setQuery('');
    onQuery('');
    setSuggestions([]);
    dismissKeyboard();
  };

  const searchBest = (e) => {
    e.preventDefault();
    onBest(true);
    setBest(true);
    onSearch(query, true);
  };

  const searchAll = (e) => {
    e.preventDefault();
    onBest(false);
    setBest(false);
    onSearch(query, false);
  };

  return (
    <div className="search-box">
      <form action="#" onSubmit={handleSubmit} className="search-bar">
        <div className="react-autosuggest">
          <Autosuggest
            key={`autosuggest-${count}`}
            suggestions={suggestions}
            onSuggestionsFetchRequested={debounce(fetchSuggestions, 200)}
            // onSuggestionsClearRequested={clearInput}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              placeholder: t('search_page.search_box.placeholder'),
              value: query,
              onChange,
              id: 'search',
              ref: 'search',
            }}
            cache={false}
          />
          <Button className="btn-clear" onClick={clearInput}>
            &#x00D7;
          </Button>
        </div>
        <div className="feeling-lucky">
          <button
            type="submit"
            onClick={searchAll}
            className={`btn ${
              best === false || best === 'false'
                ? 'primary active'
                : 'btn-not-active'
            }`}
          >
            {t('search_page.search_box.search_all')}
          </button>
          <span style={{ color: background ? '#fff' : '#333' }}>/</span>
          <button
            type="submit"
            onClick={searchBest}
            className={`btn ${
              best === true || best === 'true'
                ? 'primary active'
                : 'btn-not-active'
            }`}
          >
            {t('search_page.search_box.search_best')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Context(SearchBar);
