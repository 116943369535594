import { useState, Fragment } from 'react';
import Context from './Context';
import LayerToggle from './LayerToggle';

const COLORS = [
  '#D8712A',
  '#178EEE',
  '#66C192',
  '#FFC46D',
  '#D8712A',
  '#C4536E',
];

const FiltersMenu = ({ t, context, onShowMenu }) => {
  const { menu, filters, onFilterChange } = context;
  const [applied, setApplied] = useState(filters);

  // Note: This should be modified into a recursive
  // fn() as there are still a few outliers
  const toggle = (item, parent) => {
    let assigned = [...applied];
    let i = assigned.indexOf(item.legacyKey);
    if (i >= 0) {
      assigned.splice(i, 1);
      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => {
          let ci = assigned.indexOf(child.legacyKey);
          if (ci >= 0) {
            assigned.splice(ci, 1);
          }
          if (child.children && child.children.length > 0) {
            child.children.forEach((c2) => {
              let ci2 = assigned.indexOf(c2.legacyKey);
              if (ci2 >= 0) {
                assigned.splice(ci2, 1);
              }
            });
          }
        });
      }
    } else {
      assigned.push(item.legacyKey);
      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => {
          assigned.push(child.legacyKey);
          if (child.children && child.children.length > 0) {
            child.children.forEach((c2) => {
              assigned.push(c2.legacyKey);
            });
          }
        });
      }
    }

    if (parent && parent.children && parent.children.length > 1) {
      if (parent.children.every((item) => assigned.includes(item.legacyKey))) {
        assigned.push(parent.legacyKey);
      } else {
        let ci = assigned.indexOf(parent.legacyKey);
        if (ci >= 0) {
          assigned.splice(ci, 1);
          const parts = parent.legacyKey.split('_');
          const topKey = `${parts[0]}_${parts[1]}`;
          let top_index = assigned.indexOf(topKey);
          if (top_index >= 0) {
            assigned.splice(top_index, 1);
          }
        }
      }
    }

    setApplied(assigned);
    onFilterChange(assigned);
  };

  const nextColor = (index) => {
    let i = (index + 1) % COLORS.length;
    return COLORS[i];
  };

  // Note: Only render the LayerToggle if we need to go down > 2 levels
  // Otherwise we can simply render recursively with the child levels inline
  const getDepth = (menu) => {
    // return the depth of the tree to determine if we need to render layer toggles or not
    return 2;
  };

  const hasApplied = (filter) => {
    let results = [];
    results = [applied.includes(filter)];
    if (filter.children && filter.children.length > 0) {
      results = results.concat(
        filter.children.some((child) => hasApplied(child))
      );
    }
    return results.some((a) => a);
  };

  const renderAnyItem = (menu) => {
    return menu.children.length > 1 ? (
      <li
        className="menu-item menu-filter-item any-filters"
        style={{
          borderColor: COLORS[0],
          borderLeftWidth: '4px',
          borderLeftStyle: 'solid',
        }}
      >
        <a
          className="menu-item-text"
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            toggle(menu);
          }}
        >
          Any {t(`filters.${menu.legacyKey}`).toLowerCase()}
        </a>
        {hasApplied(menu.legacyKey) && <i className="checkmark"></i>}
      </li>
    ) : null;
  };

  const renderItems = (menu, parentColor) => {
    let depth = getDepth(menu);
    return depth > 2
      ? menu.children.map((item, i) => {
          let className = 'menu-item menu-filter-item';
          return (
            <li className={className} key={`filter-group-${i}`}>
              <LayerToggle
                className="menu-item-text"
                menu="filters"
                title={t(`filters.${item.legacyKey}`)}
                context={{
                  menu: item,
                  filters: applied,
                  onFilterChange,
                }}
                onToggle={onShowMenu}
                style={{
                  borderColor: nextColor(i),
                  borderLeftWidth: '4px',
                  borderLeftStyle: 'solid',
                }}
              >
                {t(`filters.${item.legacyKey}`)}
              </LayerToggle>
            </li>
          );
        })
      : menu.children.map((item, i) => {
          let color = parentColor || nextColor(i);
          //console.log(parentColor, color, i);
          let result = [
            <li
              key={item.legacyKey}
              className="menu-item menu-filter-item"
              style={{
                borderColor: color,
                borderLeftWidth: '4px',
                borderLeftStyle: 'solid',
              }}
            >
              <a
                className="menu-item-text"
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  toggle(item, menu);
                }}
              >
                {t(`filters.${item.legacyKey}`)}
              </a>
              {hasApplied(item.legacyKey) && <i className="checkmark"></i>}
            </li>,
          ];
          if (item.children) {
            result.push(
              <li
                className="menu-item nested-filters"
                key="filters-list-#{item.id}"
              >
                <ul className="menu-list">{renderItems(item, color)}</ul>
              </li>
            );
          }
          return result;
        });
  };

  return (
    <Fragment>
      <div className="filters-menu nested-menu">
        <ul className="menu-list">
          {renderAnyItem(menu)}
          {renderItems(menu)}
        </ul>
      </div>
    </Fragment>
  );
};

export default Context(FiltersMenu);
