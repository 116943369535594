import Context from './Context';
import LayerToggle from './LayerToggle';

const BestTips = ({ t, onShowMenu }) => {
  return (
    <span className="search-tips">
      <span className="layer-toggles">
        <LayerToggle menu="best" onToggle={onShowMenu} />
      </span>
    </span>
  );
};

export default Context(BestTips);
