import React from 'react';
import { useState } from 'react';

const ModalContext = React.createContext();
const ModalConsumer = ModalContext.Consumer;

const ModalProvider = ({ t, children }) => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [accept, setAccept] = useState(() => () => {});

  const showModal = (title = '', text, onAccept) => {
    setText(text);
    setTitle(title);
    setVisible(true);
    setAccept(onAccept);
  };

  const cancel = () => {
    setVisible(false);
  };

  const approve = () => {
    accept();
    setVisible(false);
  };

  return (
    <ModalContext.Provider value={{ showModal }}>
      {children}
      {visible && (
        <div>
          <div className="modal fade">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={cancel}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h4 className="modal-title">{title}</h4>
                </div>
                <div className="modal-body">
                  <p></p>
                  <p>{text}</p>
                  <p></p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                    onClick={cancel}
                  >
                    {t('saved_search_page.dialog.cancel')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={approve}
                  >
                    {t('saved_search_page.dialog.confirm')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade in"></div>
        </div>
      )}
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalConsumer };
