import API from './API';
/*
 * Document service for interacting with the API backend
 */

const DocumentService = () => {
  const get = async (type) => {
    const id = localStorage.getItem('client_token');
    if (!id) {
      return [];
    }
    return await API.call(`/documents/${type}`);
  };

  const subscribe = async (type, documents) => {
    const opts = {
      method: 'POST',
      body: JSON.stringify({
        type,
        documents,
      }),
    };

    try {
      const response = await API.call('/documents/subscribe', opts);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const subscribe_all = async (type, query, filters) => {
    const opts = {
      method: 'POST',
      body: JSON.stringify({
        type,
        query,
        filters,
      }),
    };

    try {
      const response = await API.call('/documents/subscribe-all', opts);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const unsubscribe = async (type, documents) => {
    const opts = {
      method: 'POST',
      body: JSON.stringify({
        type,
        documents,
      }),
    };

    try {
      const response = await API.call('/documents/unsubscribe', opts);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const send = async (type, documents) => {
    const opts = {
      method: 'POST',
      body: JSON.stringify({
        type,
        documents,
      }),
    };

    try {
      const response = await API.call('/documents/send', opts);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const send_all = async (type, query, filters) => {
    const opts = {
      method: 'POST',
      body: JSON.stringify({
        type,
        query,
        filters,
      }),
    };

    try {
      const response = await API.call('/documents/send-all', opts);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const csv = async (type, documents) => {
    const opts = {
      method: 'GET',
      data: {
        type,
        documents,
      },
    };

    try {
      return await API.download('/documents/csv', opts);
    } catch (e) {
      throw e;
    }
  };

  return {
    get,
    csv,
    send,
    send_all,
    subscribe,
    subscribe_all,
    unsubscribe,
  };
};

export default DocumentService;
