import Context from './Context';

const SignupPrompt = ({ t, cancel }) => {
  return (
    <div className="signup-prompt">
      <div className="signup-prompt-title">
        <span>{t('search_page.signup_prompt.instructions')}</span>
        <a className="signup-prompt-dismiss" href="/" onClick={cancel}>
          x
        </a>
      </div>
    </div>
  );
};

export default Context(SignupPrompt);
