import Context from './Context';
import { useEffect, useState } from 'react';
import SubscriptionService from '../services/SubscriptionService';

const UserSavedSearches = ({ t, site, language, only_subscribed }) => {
  const [subs, setSubs] = useState([]);
  const [selected, setSelected] = useState([]);
  const [all, setAll] = useState(false);

  const Subs = SubscriptionService();

  useEffect(() => {
    Subs.get(site, language)
      .then((res) => {
        if (res && res.success) {
          setSubs(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [site]);

  const select = (e, index) => {
    e.preventDefault();
    let items = [...selected];
    let found = selected.includes(index);

    if (!found) {
      items = [...selected, index];
    } else {
      items = items.filter((item) => item !== index);
    }

    if (items.length === subs.length) {
      setAll(true);
      setAllSubs(false);
    } else {
      setAll(false);
    }

    setSelected(items);
  };

  const setAllSubs = (clear) => {
    const indexes = subs
      .filter((sub) => {
        return (
          (only_subscribed === true && sub.subscribed) ||
          only_subscribed === false
        );
      })
      .map((_sub, index) => index);
    setSelected(clear ? [] : indexes);
  };

  const selectAllToggle = (e) => {
    e.preventDefault();
    setAll(!all);
    setAllSubs(all);
  };

  const toggleSubscribed = (e, sub, index) => {
    e.preventDefault();
    Subs.toggle(site, sub.query, !sub.subscribed)
      .then((res) => {
        if (res.ok) {
          let newSubs = [...subs].map((s, i) => {
            s.subscribed =
              i === index && s.query === sub.query
                ? !s.subscribed
                : s.subscribed;
            return s;
          });
          setSubs(newSubs);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const remove = (e) => {
    e.preventDefault();
    const newSubs = subs.filter((_s, i) => !selected.includes(i));
    Subs.edit(site, newSubs)
      .then((res) => {
        setSubs(res);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div className="saved-search-list">
      <div className="saved-search-list-header">
        <ul className="saved-search-list-actions list-actions list-inline">
          {selected.length > 0 && (
            <li className="action remove-selected">
              <a href="/remove" className="button" onClick={remove}>
                {t('remove_selected')}
              </a>
            </li>
          )}
          <li className="action">
            <label>
              <span>{t('select_all')}</span>
              <div className={`checkbox${all ? ' checkbox-checked' : ''}`}>
                <input
                  type="checkbox"
                  name="search_to_delete"
                  onClick={selectAllToggle}
                />
              </div>
            </label>
          </li>
        </ul>
      </div>
      <ol className="selectable-list saved-search-list-content list">
        {subs
          .filter((sub) => {
            return (
              (only_subscribed === true && sub.subscribed) ||
              only_subscribed === false
            );
          })
          .map((sub, i) => {
            return (
              <li
                key={i}
                className="selectable-item saved-search-item list-item"
              >
                <div className="saved-search-item-header clearfix">
                  <h2>
                    <a
                      rel="alternate"
                      href={`/search?q=${
                        sub.query
                      }&applied_filters=${sub.keys.join(',')}`}
                    >
                      {sub.query}
                    </a>
                  </h2>
                  <label className="saved-search-select action">
                    <span>{t('select')}</span>
                    <div
                      className={`checkbox${
                        selected.includes(i) ? ' checkbox-checked' : ''
                      }`}
                    >
                      <input type="checkbox" onClick={(e) => select(e, i)} />
                    </div>
                  </label>
                </div>
                <div className="saved-search-keywords">
                  <span className="saved-search-keywords-label">
                    {t('saved_search_page.keywords')}
                  </span>
                  <span className="saved-search-keywords-value">
                    {sub.query}
                  </span>
                </div>
                {sub.filters.length > 0 && (
                  <div className="applied-filters-wrapper">
                    <span class="saved-search-filters-label">Filters:</span>
                    <ul className="applied-filters">
                      {sub.filters.map((f, i) => (
                        <li key={`filter${i}`} className="applied-filter-item">
                          <div className="applied-filter-item-content">
                            <span className="applied-filter-item-filters">
                              {t(`filters.${sub.keys[i]}`)}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <label className="saved-search-control">
                  <span>{t('saved_search_page.subscribe')}</span>
                  <div
                    className={`react-toggle${
                      sub.subscribed
                        ? ' react-toggle--checked'
                        : ' react-toggle--focus'
                    }`}
                    onClick={(e) => toggleSubscribed(e, sub, i)}
                  >
                    <div className="react-toggle-track">
                      <div className="react-toggle-track-check">
                        <svg width="14" height="11" viewBox="0 0 14 11">
                          <title>switch-check</title>
                          <path
                            d="M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0"
                            fill="#fff"
                          ></path>
                        </svg>
                      </div>
                      <div className="react-toggle-track-x">
                        <svg width="10" height="10" viewBox="0 0 10 10">
                          <title>switch-x</title>
                          <path
                            d="M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 7.072 4.95 9.9 2.12"
                            fill="#fff"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="react-toggle-thumb"></div>
                    <input
                      className="react-toggle-screenreader-only"
                      type="checkbox"
                    />
                  </div>
                </label>
              </li>
            );
          })}
      </ol>
    </div>
  );
};

export default Context(UserSavedSearches);
