import { useState, useEffect } from 'react';
import AuthService from '../../services/AuthService';
import Context from '../../components/Context';
import { useNavigate, useParams } from 'react-router-dom';

const ConfirmEmail = ({ setPage }) => {
  const Auth = new AuthService();
  const [confirmed, setConfirmed] = useState(false);
  const { token } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    setPage('confirm');
  });

  useEffect(() => {
    if (!token) {
      return;
    }
    Auth.confirmEmail(token).then(
      (res) => {
        if (res.success && res.data.confirmed) {
          setConfirmed(true);
          setTimeout(() => {
            return navigate('/');
          }, 4000);
        }
      },
      (err) => {
        navigate('/');
      }
    );
  });

  return (
    <>
      {confirmed && (
        <div className="d-flex flex-column flex">
          <div className="navbar light bg pos-rlt box-shadow">
            <div className="mx-auto"></div>
          </div>
          <div id="content-body">
            <div className="py-5 text-center w-100">
              <div className="mx-auto w-auto-xs">
                <div className="px-3">
                  <div className="my-3 text-lg">You are now confirmed</div>
                  <p>You will be automatically redirected to the home page.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Context(ConfirmEmail);
