import Context from './Context';
import { useEffect, useState } from 'react';
import CuratedSearchService from '../services/CuratedSearchService';

const UserCuratedSearches = ({ t, site, lang, user }) => {
  const [curated, setCurated] = useState([]);
  const [subscribed, setSubscribed] = useState([]);

  const CuratedSearch = CuratedSearchService();

  useEffect(() => {
    if (user) {
      list();
      get_subscribed();
    }
  }, [site, user]);

  const list = () => {
    CuratedSearch.list(site)
      .then((res) => {
        if (res.success) {
          setCurated(res.data || []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const get_subscribed = () => {
    CuratedSearch.list_by_subscriber(site, user._id)
      .then((res) => {
        if (res.success) {
          setSubscribed(res.data.map((s) => s._id));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggle = (e, _id) => {
    e.preventDefault();

    if (subscribed.includes(_id)) {
      CuratedSearch.unsubscribe(_id, user._id)
        .then((res) => {
          if (res.success) {
            setSubscribed(subscribed.filter((s) => s !== _id));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      CuratedSearch.subscribe(_id, user._id)
        .then((res) => {
          if (res.success) {
            setSubscribed([...subscribed, _id]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="saved-search-list">
      <ol className="selectable-list saved-search-list-content list">
        {curated.map((search, i) => {
          return (
            <li key={i} className="selectable-item saved-search-item list-item">
              <div className="saved-search-item-header clearfix">
                <h2>
                  <a
                    rel="alternate"
                    href={`/search?q=${
                      search.query
                    }&applied_filters=${search.keys.join(',')}`}
                  >
                    {search.title}
                  </a>
                </h2>
              </div>
              <div className="saved-search-keywords">
                <span className="saved-search-keywords-label">
                  {t('saved_search_page.keywords')}
                </span>
                <span className="saved-search-keywords-value">
                  {search.query}
                </span>
              </div>
              {search.filters.length > 0 && (
                <div className="applied-filters-wrapper">
                  <span class="saved-search-filters-label">Filters:</span>
                  <ul className="applied-filters">
                    {search.filters.map((f, i) => (
                      <li key={`filter${i}`} className="applied-filter-item">
                        <div className="applied-filter-item-content">
                          <span className="applied-filter-item-filters">
                            {t(`filters.${search.keys[i]}`)}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <label className="saved-search-control">
                <span>{t('saved_search_page.subscribe')}</span>
                <div
                  className={`react-toggle${
                    subscribed.includes(search._id)
                      ? ' react-toggle--checked'
                      : ' react-toggle--focus'
                  }`}
                  onClick={(e) => toggle(e, search._id)}
                >
                  <div className="react-toggle-track">
                    <div className="react-toggle-track-check">
                      <svg width="14" height="11" viewBox="0 0 14 11">
                        <title>switch-check</title>
                        <path
                          d="M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                    <div className="react-toggle-track-x">
                      <svg width="10" height="10" viewBox="0 0 10 10">
                        <title>switch-x</title>
                        <path
                          d="M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 7.072 4.95 9.9 2.12"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="react-toggle-thumb"></div>
                  <input
                    className="react-toggle-screenreader-only"
                    type="checkbox"
                  />
                </div>
              </label>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Context(UserCuratedSearches);
