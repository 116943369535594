import Context from './Context';

const SaveDocumentButtons = ({
  t,
  user,
  docs = [],
  onEmail,
  onSave,
  onView,
  onSelectAll,
  allSelected = false,
}) => {
  return (
    user && (
      <div className="saved-articles-actions">
        {docs.length > 0 && (
          <>
            <a
              href="/email"
              className="button icon icon-email"
              onClick={onEmail}
            >
              <span>{t('search_page.result_box.email')}</span>
            </a>
            <a
              href="/save"
              className="button icon icon-save-article"
              onClick={onSave}
            >
              <span>{t('search_page.result_box.save')}</span>
            </a>
          </>
        )}
        <a
          href="/user/articles"
          className="icon icon-view-saved-articles button"
          onClick={onView}
        >
          <span>{t('search_page.result_box.view_saved')}</span>
        </a>
        <label className="select-all-action action" id="select_all">
          <span>{t('select_all')}</span>
          <div
            className={`checkbox${
              allSelected
                ? ' checkbox-checked'
                : docs.length > 0
                ? ' checkbox-deselect'
                : ''
            }`}
          >
            <input type="checkbox" onChange={onSelectAll} />
          </div>
        </label>
      </div>
    )
  );
};

export default Context(SaveDocumentButtons);
