const formatLastLitDate = (t, article) => {
  const has_search =
    article.hasOwnProperty('lastLitSearch') && article.lastLitSearch != null;
  const has_search_year =
    has_search && article.lastLitSearch.hasOwnProperty('year');
  const has_search_month =
    has_search && article.lastLitSearch.hasOwnProperty('month');
  const has_search_day =
    has_search && article.lastLitSearch.hasOwnProperty('day');

  if (!has_search || !has_search_year) {
    return t('articles_page.no_last_year_literature_searched');
  }

  const year = has_search_year ? article.lastLitSearch.year : null;
  const month = has_search_month ? pad(article.lastLitSearch.month) : null;
  const day = has_search_day ? pad(article.lastLitSearch.day) : null;

  return `${year ? year : ''}${month ? '-' + month : ''}${
    day ? '-' + day : ''
  }`;
};

const pad = (value) => {
  return value < 10 ? `0${value}` : value;
};

module.exports = {
  formatLastLitDate,
};
