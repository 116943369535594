import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Context from '../../components/Context';
import AuthService from '../../services/AuthService';

const ResetPassword = ({ t, site, setPage }) => {
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState([]);

  let { token } = useParams();
  let navigate = useNavigate();

  const Auth = new AuthService();
  setPage('reset_password');

  const submit = (e) => {
    e.preventDefault();

    if (password === '') {
      setErrors({ password: 'cannot be empty' });
      return;
    }
    if (password === '') {
      setErrors({ confirm: 'cannot be empty' });
      return;
    }

    if (password !== confirm) {
      setErrors({ password: 'mismatch' });
      return;
    }

    Auth.passwordReset(token, confirm)
      .then((res) => {
        if (res.data && res.data.hasOwnProperty('error')) {
          setErrors({});
        } else {
          setErrors([]);
          setSubmitted(true);
          toast.success('Password successfully updated.', {
            onClose: () => {
              navigate('/login');
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const change = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(value);
    } else {
      setConfirm(value);
    }
  };

  return (
    <div id="page-content">
      <Helmet>
        <title>
          {t('reset_password_page.title')} | {t('site_name')}
        </title>
      </Helmet>
      <form className="forgot-password-page" onSubmit={submit}>
        <h1>Reset password</h1>
        {!submitted && (
          <>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                onChange={change}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Password Confirmation"
                name="confirm"
                onChange={change}
              />
            </div>
            <button type="submit" className="btn primary" onClick={submit}>
              {t('reset_password_page.submit')}
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default Context(ResetPassword);
