import Context from './Context';
import LayerToggle from './LayerToggle';

const SearchTipsAdvanced = ({ t, onShowMenu, onAdvanced }) => {
  return (
    <div className="search-tips">
      <div className="layer-toggles clearfix">
        <LayerToggle menu="help" onToggle={onShowMenu}>
          {t('search_page.search_box.tips')}
        </LayerToggle>
        <div className="advanced-search-wrapper">
          <a className="advanced-search" href="/search2?" onClick={onAdvanced}>
            {t('search_page.search_box.advanced_search')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Context(SearchTipsAdvanced);
