import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

import SearchBar from '../components/SearchBar';
import SearchTips from '../components/SearchTips';
import GuidedQuestions from '../components/GuidedQuestions';
import Context from '../components/Context';
import FilterGroupsMenu from '../components/FilterGroupsMenu';
import SearchResults from '../components/SearchResults';
import SearchService from '../services/SearchService';
import SubscriptionService from '../services/SubscriptionService';
import DocumentService from '../services/DocumentService';
import BestTips from '../components/BestTips';
import useQueryString from '../components/useQueryString';

const Search = ({
  user,
  t,
  site,
  language,
  setPage,
  toggleLayer,
  showModal,
  sendEvent,
}) => {
  const [results, setResults] = useState([]);
  const [filters, setFilters] = useQueryString('applied_filters', []);
  const filter = site === 'cvd' ? 'best' : 'relevance';
  const [sort, setSort] = useQueryString('s', filter);
  const [best, setBest] = useQueryString('best', false);
  const [query, setQuery] = useQueryString('q', '');
  const [pageNumber, setPageNumber] = useQueryString('p', 0);

  const [prompt, setPrompt] = useState(false);
  const [count, setCount] = useState(0);
  const [error, setError] = useState('');
  const [subs, setSubs] = useState([]);
  const [docs, setDocs] = useState([]);
  const [saved, setSaved] = useState(false);
  const [subbed, setSubbed] = useState(false);
  const [all, setAll] = useState(false);

  const Search = SearchService();
  const Subscription = SubscriptionService();
  const Document = DocumentService();

  let navigate = useNavigate();

  useEffect(() => {
    setPage('search');
  });

  const getSubs = (q) => {
    Subscription.get(site)
      .then((res) => {
        if (res && res.success) {
          setSubs(res.data);
          const is_saved = res.data.some(
            (s) => s.query === q && isEqual(s.keys, filters)
          );
          const is_subbed = res.data.some(
            (s) =>
              s.query === q && s.subscribed === true && isEqual(s.keys, filters)
          );
          setSaved(is_saved);
          setSubbed(is_subbed);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const isEqual = (a, b) =>
    JSON.stringify(a.sort()) === JSON.stringify(b.sort());

  useEffect(() => {
    debounced_search(query, best, filters, sort, pageNumber);
  }, [user, query, best, filters, sort]);

  useEffect(() => {
    search(query, best, filters, sort, pageNumber);
  }, [language]);

  const search = (q, b, f, s, p) => {
    // send ga4 search event
    sendEvent('search', { search_term: q });

    Search.search({
      query: q,
      best: b,
      filters: f || filters,
      sort: s || sort,
      page: p,
      site,
      language,
    })
      .then((res) => {
        if (res && res.hits) {
          let list = p > 0 ? results.concat(res.hits) : res.hits;
          setResults(list);
          setCount(res.total.value);
          setPrompt(false);
          setError('');
          getSubs(q);
          if (all) {
            setDocs(list.map((a) => a.id));
          }
        } else {
          setError('Too many requests detected, please try again later');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const debounced_search = debounce(search, 1000);

  const cancelPrompt = (e) => {
    e.preventDefault();
    setPrompt(false);
  };

  const toggleFilters = (filters) => {
    setFilters(filters);
    setPageNumber(0);
  };

  const changeSort = (sort_type) => {
    setPageNumber(0);
    setSort(sort_type);
  };

  const scroll = () => {
    if (!user) {
      setPrompt(true);
    } else {
      let page = parseInt(pageNumber) + 1;
      search(query, best, filters, sort, page);
      setPageNumber(page);
    }
  };

  const new_search = (q, b) => {
    setPageNumber(0);
    debounced_search(q, b, filters, sort, 0);
  };

  const new_query = (q) => {
    setPageNumber(0);
    setQuery(q);
  };

  const saveSearch = (e) => {
    e.preventDefault();
    if (query === '' && filters.length === 0) {
      return;
    }
    if (!saved) {
      Subscription.subscribe(site, query, filters, false).then((_res) => {
        toast.success(t('search_page.on_save_search'));
        getSubs(query);
      });
    } else {
      Subscription.unsubscribe(site, query, filters).then((_res) => {
        toast.success(t('search_page.on_unsave_search'));
        getSubs(query);
      });
    }
  };

  const subscribeSearch = (e) => {
    e.preventDefault();
    if (query === '' && filters.length === 0) {
      return;
    }

    if (!subbed) {
      showModal('', t('saved_search_page.dialog.message'), () => () => {
        Subscription.subscribe(site, query, filters, true).then((_res) => {
          toast.success(t('search_page.on_save_and_subscribe'));
          getSubs(query);
        });
      });
    } else {
      Subscription.toggle(site, query, filters, false).then((_res) => {
        toast.success(t('search_page.on_unsubscribe'));
        getSubs(query);
      });
    }
  };

  const selectDoc = (id, checked) => {
    let doc_list = [...docs];
    if (checked) {
      if (!docs.includes(id)) {
        doc_list = docs.concat([id]);
      }
    } else {
      doc_list = docs.filter((d) => d !== id);
    }
    setDocs(doc_list);
    setAll(doc_list.length > 0 && doc_list.length === results.length);
  };

  const emailDocs = (e) => {
    e.preventDefault();
    const term = docs.length > 1 ? 'on_email_plural' : 'on_email';
    const msg = t(`search_page.result_list.${term}`)
      .replace('%{email}', user.email)
      .replace('%{count}', all ? count : docs.length);
    if (all) {
      Document.send_all(site, query, filters).then((_res) => {
        toast.success(msg);
      });
    } else {
      Document.send(site, docs).then((_res) => {
        toast.success(msg);
      });
    }
  };

  const viewDocs = (e) => {
    e.preventDefault();
    navigate('/user/articles');
  };

  const saveDocs = (e) => {
    e.preventDefault();
    const term = docs.length > 1 ? 'on_save_plural' : 'on_save';
    const msg = t(`search_page.result_list.${term}`)
      .replace('%{email}', user.email)
      .replace('%{count}', all ? count : docs.length);
    if (all) {
      Document.subscribe_all(site, query, filters).then((_res) => {
        toast.success(msg);
      });
    } else {
      Document.subscribe(site, docs).then((_res) => {
        toast.success(msg);
      });
    }
  };

  const selectAllDocs = (e) => {
    const { checked } = e.target;
    setAll(checked);
    if (checked) {
      setDocs(results.map((r) => r.id));
    } else {
      setDocs([]);
    }
  };

  return (
    <div id="page-content">
      <div className="search-page">
        {t('home_page.intro2') !== '' && (
          <p className="intro">
            {t('home_page.intro2')} <BestTips onShowMenu={toggleLayer} />
          </p>
        )}
        {t('home_page.intro3') !== '' && (
          <p className="intro">{t('home_page.intro3')}</p>
        )}
        <SearchBar
          onSearch={new_search}
          onBest={setBest}
          onQuery={new_query}
          onFilters={setFilters}
          background={false}
        />
        <SearchTips
          filters={filters}
          onShowMenu={toggleLayer}
          onSaveSearch={saveSearch}
          onSubscribeSearch={subscribeSearch}
          onFilterChange={toggleFilters}
          isSaved={saved}
          isSubbed={subbed}
          isChecked={all}
          docs={docs}
          onSelectAllDocs={selectAllDocs}
        />
        <GuidedQuestions isCollapsed={true} onFilters={setFilters} />
        <FilterGroupsMenu filters={filters} onFilterChange={toggleFilters} />
        <SearchResults
          results={results}
          onSort={changeSort}
          onCancelPrompt={cancelPrompt}
          promptVisible={prompt}
          onBottom={scroll}
          onSelectDoc={selectDoc}
          onEmailDocs={emailDocs}
          onSaveDocs={saveDocs}
          onViewDocs={viewDocs}
          onSelectAllDocs={selectAllDocs}
          allSelected={all}
          count={count}
          docs={docs}
          sort={sort}
        />
        {error && <div>{error}</div>}
      </div>
    </div>
  );
};

export default Context(Search);
