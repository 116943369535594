import _ from 'underscore';
import Context from './Context';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DocumentService from '../services/DocumentService';
import { slugify, ellipsis } from '../utils/text';
import { formatLastLitDate } from '../utils/date';
import { toast } from 'react-toastify';

const UserSavedDocuments = ({ t, site, lang, setPage, user }) => {
  const [articles, setArticles] = useState([]);
  const [selected, setSelected] = useState([]);
  const [all, setAll] = useState(false);

  const Document = DocumentService();

  useEffect(() => {
    setPage('saved_articles');
  });

  useEffect(() => {
    getDocs();
  }, [site]);

  const getDocs = () => {
    Document.get(site)
      .then((res) => {
        if (res && res.success) {
          setArticles(res.data);
          setSelected([]);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const csv = (e) => {
    e.preventDefault();
    Document.csv(site, selected);
  };

  const email = (e) => {
    e.preventDefault();
    const term = selected.length > 1 ? 'on_email_plural' : 'on_email';
    const msg = t(`search_page.result_list.${term}`)
      .replace('%{email}', user.email)
      .replace('%{count}', selected.length);
    Document.send(site, selected).then((_res) => {
      toast.success(msg);
    });
  };

  const remove = (e) => {
    e.preventDefault();
    Document.unsubscribe(site, selected)
      .then((res) => {
        if (res.ok) {
          const key = selected.length > 1 ? 'on_remove_plural' : 'on_remove';
          const msg = t(`search_page.result_list.${key}`).replace(
            '%{count}',
            selected.length
          );
          toast.success(msg);
          getDocs();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const select = (e, id) => {
    const { checked } = e.target;
    let doc_list = [...selected];
    if (checked) {
      if (!selected.includes(id)) {
        doc_list = selected.concat([id]);
      }
    } else {
      doc_list = selected.filter((d) => d !== id);
    }
    setSelected(doc_list);
    setAll(doc_list.length > 0 && doc_list.length === articles.length);
  };

  const selectAll = (e) => {
    const { checked } = e.target;
    let items = checked ? articles.map((a) => a.id) : [];
    setSelected(items);
    setAll(checked);
  };

  const getStudiesConductedIn = (studies_conducted_in) => {
    if (!studies_conducted_in) {
      return '';
    }
    return studies_conducted_in.countries
      .map((c) => {
        let name = c.name_abbreviation;
        name = _.isEmpty(name) ? c.title : name;
        if (
          c.conducted_count &&
          c.conducted_count !== '0' &&
          c.conducted_count !== 'null'
        )
          name = `${name} (${c.conducted_count})`;
        return name;
      })
      .join('; ');
  };

  return (
    <>
      <div className="saved-articles-list-header">
        {selected.length > 0 ? (
          <div className="export-buttons fixed-footer">
            <a href="/export" className="button icon icon-export" onClick={csv}>
              {t('saved_articles_page.export')}
            </a>
            <a href="/email" className="button icon icon-email" onClick={email}>
              {t('saved_articles_page.email')}
            </a>
          </div>
        ) : null}
        <ul className="list-actions list-inline">
          {selected.length > 0 ? (
            <li className="action remove-selected">
              <a href="/remove" className="button" onClick={remove}>
                {t('remove_selected')}
              </a>
            </li>
          ) : null}
          {articles.length > 0 ? (
            <li className="action">
              <label className="result-item-select">
                <span>{t('select_all')}&nbsp;</span>

                <div
                  className={`checkbox${
                    all
                      ? ' checkbox-checked'
                      : selected.length > 0
                      ? ' checkbox-deselect'
                      : ''
                  }`}
                >
                  <input
                    type="checkbox"
                    name="search_to_delete"
                    onChange={selectAll}
                  />
                </div>
              </label>
            </li>
          ) : null}
        </ul>
      </div>
      <ol className="selectable-list result-list">
        {articles.length === 0
          ? t('saved_articles_page.no_saved_articles')
          : articles.map((article, i) => {
              let href = `/articles/${article.id}-${slugify(article.title)}`;
              return (
                <li key={article.id} className="selectable-item result-item">
                  <header className="result-item-header">
                    <div className="result-item-header-left">
                      <span className="result-item-number">
                        <span>{i + 1}</span>
                        <span>.</span>
                      </span>
                      <div className="field result-item-published">
                        <span className="field-name">
                          {t('search_page.result_item.date')}
                        </span>
                        <span className="field-value">
                          {formatLastLitDate(t, article)}
                        </span>
                      </div>
                      <div className="field result-item-quality">
                        <span className="field-name">
                          {t('search_page.result_item.quality')}
                        </span>
                        <span className="field-value">{article.quality}</span>
                      </div>
                    </div>
                    <div className="result-item-header-right action">
                      <label className="result-item-select">
                        <span>{t('select')}</span>
                        <div
                          className={`checkbox${
                            selected.includes(article.id)
                              ? ' checkbox-checked'
                              : ''
                          }`}
                        >
                          <input
                            type="checkbox"
                            checked={selected.includes(article.id)}
                            onChange={(e) => select(e, article.id)}
                          />
                        </div>
                      </label>
                    </div>
                  </header>
                  <section className="result-item-description">
                    <h2 className="result-item-title">
                      <Link to={href}>{article.title}</Link>
                    </h2>
                    <div className="result-item-categories">
                      <span>
                        {article.category
                          ? article.category
                          : getStudiesConductedIn(article.studies_conducted_in)}
                      </span>
                    </div>
                    <span>{ellipsis(article.description, 250)}</span>
                  </section>
                </li>
              );
            })}
      </ol>
    </>
  );
};

export default Context(UserSavedDocuments);
