import React from 'react';
import ReactGA from 'react-ga4';

const SiteContext = React.createContext();
const SiteConsumer = SiteContext.Consumer;

const ANALYTICS = {
  hse: 'G-8JD4PKNTZW',
  sse: 'G-152XL0459T',
  cvd: 'G-J71ESHQWEH',
};

const SiteProvider = ({ children }) => {
  let site = '';
  const url = window.location.origin;

  if (url.indexOf('healthsystemsevidence') >= 0) {
    site = 'hse';
  } else if (
    url.indexOf('socialsystemevidence') >= 0 ||
    url.indexOf('socialsystemsevidence') >= 0
  ) {
    site = 'sse';
  } else {
    site = 'cvd';
  }

  ReactGA.initialize(ANALYTICS[site]);

  const sendEvent = (name, params) => {
    ReactGA.event(name, params);
  };

  return (
    <SiteContext.Provider value={{ site, sendEvent }}>
      {children}
    </SiteContext.Provider>
  );
};

export { SiteProvider, SiteConsumer };
