import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Context from '../components/Context';
import SubscriptionService from '../services/SubscriptionService';

const Unsubscribe = ({ t, site, setPage }) => {
  const [email, setEmail] = useState('');
  const Subscription = SubscriptionService();

  useEffect(() => {
    setPage('unsubscribe');
  });

  const unsubscribe = () => {
    if (email !== '') {
      Subscription.unsubscribe_all(site, email).then((res) => {
        if (res.ok) {
          toast.success(t('unsubscribe_page.success'));
        } else {
          toast.error('Failed to unsubscribe for this email address');
        }
      });
    }
  };

  const emailChanged = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div id="page-content">
      <h1>{t('unsubscribe_page.title')}</h1>
      <div className="unsubscribe">
        <div className="form-group">
          <input
            placeholder={t('menus.signup.email')}
            className="form-control"
            name="email"
            type="email"
            onChange={emailChanged}
          />
        </div>
        <p>{t('unsubscribe_page.confirmation_msg')}</p>
        <div className="form-group">
          <button className="btn-primary" onClick={unsubscribe}>
            {t('unsubscribe_page.unsubscribe')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Context(Unsubscribe);
