const countries = [
  { id: '1', name: 'Afghanistan' },
  { id: '2', name: 'Albania' },
  { id: '3', name: 'Algeria' },
  { id: '4', name: 'Andorra' },
  { id: '5', name: 'Angola' },
  { id: '6', name: 'Antigua and Barbuda' },
  { id: '7', name: 'Argentina' },
  { id: '8', name: 'Armenia' },
  { id: '9', name: 'Australia' },
  { id: '10', name: 'Austria' },
  { id: '11', name: 'Azerbaijan' },
  { id: '12', name: 'Bahamas' },
  { id: '13', name: 'Bahrain' },
  { id: '14', name: 'Bangladesh' },
  { id: '15', name: 'Barbados' },
  { id: '16', name: 'Belarus' },
  { id: '17', name: 'Belgium' },
  { id: '18', name: 'Belize' },
  { id: '19', name: 'Benin' },
  { id: '20', name: 'Bhutan' },
  { id: '21', name: 'Bolivia (Plurinational State of)' },
  { id: '22', name: 'Bosnia and Herzegovina' },
  { id: '23', name: 'Botswana' },
  { id: '24', name: 'Brazil' },
  { id: '25', name: 'Brunei Darussalam' },
  { id: '26', name: 'Bulgaria' },
  { id: '27', name: 'Burkina Faso' },
  { id: '28', name: 'Burundi' },
  { id: '29', name: 'Cambodia' },
  { id: '30', name: 'Cameroon' },
  { id: '31', name: 'Canada' },
  { id: '32', name: 'Cape Verde' },
  { id: '33', name: 'Central African Republic' },
  { id: '34', name: 'Chad' },
  { id: '35', name: 'Chile' },
  { id: '36', name: 'China' },
  { id: '37', name: 'Colombia' },
  { id: '38', name: 'Comoros' },
  { id: '39', name: 'Congo (Brazzaville)' },
  { id: '47', name: 'Congo (Democratic Republic of)' },
  { id: '40', name: 'Costa Rica' },
  { id: '41', name: "Côte d'Ivoire" },
  { id: '42', name: 'Croatia' },
  { id: '43', name: 'Cuba' },
  { id: '44', name: 'Cyprus' },
  { id: '45', name: 'Czech Republic' },
  { id: '48', name: 'Denmark' },
  { id: '49', name: 'Djibouti' },
  { id: '50', name: 'Dominica' },
  { id: '51', name: 'Dominican Republic' },
  { id: '52', name: 'Ecuador' },
  { id: '53', name: 'Egypt' },
  { id: '54', name: 'El Salvador' },
  { id: '55', name: 'Equatorial Guinea' },
  { id: '56', name: 'Eritrea' },
  { id: '57', name: 'Estonia' },
  { id: '58', name: 'Ethiopia' },
  { id: '59', name: 'Fiji' },
  { id: '60', name: 'Finland' },
  { id: '61', name: 'France' },
  { id: '62', name: 'Gabon' },
  { id: '63', name: 'Gambia' },
  { id: '64', name: 'Georgia' },
  { id: '65', name: 'Germany' },
  { id: '66', name: 'Ghana' },
  { id: '67', name: 'Greece' },
  { id: '68', name: 'Grenada' },
  { id: '69', name: 'Guatemala' },
  { id: '70', name: 'Guinea' },
  { id: '71', name: 'Guinea-Bissau' },
  { id: '72', name: 'Guyana' },
  { id: '73', name: 'Haiti' },
  { id: '74', name: 'Honduras' },
  { id: '75', name: 'Hungary' },
  { id: '76', name: 'Iceland' },
  { id: '77', name: 'India' },
  { id: '78', name: 'Indonesia' },
  { id: '79', name: 'Iran (Islamic Republic of)' },
  { id: '80', name: 'Iraq' },
  { id: '81', name: 'Ireland' },
  { id: '82', name: 'Israel' },
  { id: '83', name: 'Italy' },
  { id: '84', name: 'Jamaica' },
  { id: '85', name: 'Japan' },
  { id: '86', name: 'Jordan' },
  { id: '87', name: 'Kazakhstan' },
  { id: '88', name: 'Kenya' },
  { id: '89', name: 'Kiribati' },
  { id: '46', name: "Korea (Democratic People's Republic of)" },
  { id: '138', name: 'Korea (Republic of)' },
  { id: '90', name: 'Kuwait' },
  { id: '91', name: 'Kyrgyzstan' },
  { id: '92', name: "Lao People's Democratic Republic" },
  { id: '93', name: 'Latvia' },
  { id: '94', name: 'Lebanon' },
  { id: '95', name: 'Lesotho' },
  { id: '96', name: 'Liberia' },
  { id: '97', name: 'Libya' },
  { id: '98', name: 'Liechtenstein' },
  { id: '99', name: 'Lithuania' },
  { id: '100', name: 'Luxembourg' },
  { id: '170', name: 'Macedonia (The former Yugoslav Republic of)' },
  { id: '101', name: 'Madagascar' },
  { id: '102', name: 'Malawi' },
  { id: '103', name: 'Malaysia' },
  { id: '104', name: 'Maldives' },
  { id: '105', name: 'Mali' },
  { id: '106', name: 'Malta' },
  { id: '107', name: 'Marshall Islands' },
  { id: '108', name: 'Mauritania' },
  { id: '109', name: 'Mauritius' },
  { id: '110', name: 'Mexico' },
  { id: '111', name: 'Micronesia (Federated States of)' },
  { id: '112', name: 'Monaco' },
  { id: '113', name: 'Mongolia' },
  { id: '114', name: 'Montenegro' },
  { id: '115', name: 'Morocco' },
  { id: '116', name: 'Mozambique' },
  { id: '117', name: 'Myanmar' },
  { id: '118', name: 'Namibia' },
  { id: '119', name: 'Nauru' },
  { id: '120', name: 'Nepal' },
  { id: '121', name: 'Netherlands' },
  { id: '122', name: 'New Zealand' },
  { id: '123', name: 'Nicaragua' },
  { id: '124', name: 'Niger' },
  { id: '125', name: 'Nigeria' },
  { id: '126', name: 'Norway' },
  { id: '127', name: 'Oman' },
  { id: '128', name: 'Pakistan' },
  { id: '129', name: 'Palau' },
  { id: '130', name: 'Panama' },
  { id: '131', name: 'Papua New Guinea' },
  { id: '132', name: 'Paraguay' },
  { id: '133', name: 'Peru' },
  { id: '134', name: 'Philippines' },
  { id: '135', name: 'Poland' },
  { id: '136', name: 'Portugal' },
  { id: '137', name: 'Qatar' },
  { id: '139', name: 'Moldova (Republic of)' },
  { id: '140', name: 'Romania' },
  { id: '141', name: 'Russian Federation' },
  { id: '142', name: 'Rwanda' },
  { id: '143', name: 'Saint Kitts and Nevis' },
  { id: '144', name: 'Saint Lucia' },
  { id: '145', name: 'Saint Vincent and the Grenadines' },
  { id: '146', name: 'Samoa' },
  { id: '147', name: 'San Marino' },
  { id: '148', name: 'Sao Tome and Principe' },
  { id: '149', name: 'Saudi Arabia' },
  { id: '150', name: 'Senegal' },
  { id: '151', name: 'Serbia' },
  { id: '152', name: 'Seychelles' },
  { id: '153', name: 'Sierra Leone' },
  { id: '154', name: 'Singapore' },
  { id: '155', name: 'Slovakia' },
  { id: '156', name: 'Slovenia' },
  { id: '157', name: 'Solomon Islands' },
  { id: '158', name: 'Somalia' },
  { id: '159', name: 'South Africa' },
  { id: '266', name: 'South Sudan' },
  { id: '160', name: 'Spain' },
  { id: '161', name: 'Sri Lanka' },
  { id: '162', name: 'Sudan' },
  { id: '163', name: 'Suriname' },
  { id: '164', name: 'Swaziland' },
  { id: '166', name: 'Sweden' },
  { id: '165', name: 'Switzerland' },
  { id: '167', name: 'Syrian Arab Republic' },
  { id: '168', name: 'Tajikistan' },
  { id: '183', name: 'Tanzania (United Republic of)' },
  { id: '169', name: 'Thailand' },
  { id: '170', name: 'Macedonia (The former Yugoslav Republic of)' },
  { id: '171', name: 'Timor-Leste' },
  { id: '172', name: 'Togo' },
  { id: '173', name: 'Tonga' },
  { id: '174', name: 'Trinidad and Tobago' },
  { id: '175', name: 'Tunisia' },
  { id: '176', name: 'Turkey' },
  { id: '177', name: 'Turkmenistan' },
  { id: '178', name: 'Tuvalu' },
  { id: '179', name: 'Uganda' },
  { id: '180', name: 'Ukraine' },
  { id: '181', name: 'United Arab Emirates' },
  { id: '182', name: 'United Kingdom' },
  { id: '184', name: 'United States' },
  { id: '185', name: 'Uruguay' },
  { id: '186', name: 'Uzbekistan' },
  { id: '187', name: 'Vanuatu' },
  { id: '188', name: 'Venezuela (Bolivarian Republic of)' },
  { id: '189', name: 'Viet Nam' },
  { id: '190', name: 'Yemen' },
  { id: '191', name: 'Zambia' },
  { id: '192', name: 'Zimbabwe' },

  { id: '193', name: 'Bermuda' },
  { id: '194', name: 'French Polynesia' },
  { id: '195', name: 'Hong Kong' },
  { id: '196', name: 'Kosovo' },
  { id: '197', name: 'Northern Mariana Islands' },
  { id: '198', name: 'Palestine' },
  { id: '199', name: 'Puerto Rico' },
  { id: '200', name: 'Taiwan' },
  { id: '201', name: 'United Kingdom (England)' },
  { id: '202', name: 'United Kingdom (Northern Ireland)' },
  { id: '203', name: 'United Kingdom (Scotland)' },
  { id: '204', name: 'United Kingdom (Wales)' },
  { id: '205', name: 'Yugoslavia' },
  { id: '207', name: 'Scandinavia' },
  { id: '208', name: 'Ireland & UK (Northern Ireland)' },
  { id: '209', name: 'Africa' },
  { id: '210', name: 'Asia' },
  { id: '211', name: 'Europe' },
  { id: '212', name: 'North America' },
  { id: '213', name: 'South America' },
  { id: '214', name: 'Latin America' },
  { id: '215', name: 'Australasia' },
  { id: '216', name: 'Caribbean' },
  { id: '217', name: 'Developed countries' },
  { id: '218', name: 'Developing countries' },
  { id: '219', name: 'Eastern Europe' },
  { id: '220', name: 'Middle East' },
  { id: '221', name: 'OECD countries' },
  { id: '222', name: 'Sub-Saharan Africa' },
  { id: '223', name: 'West Bank' },
  { id: '225', name: 'Mostly from USA' },
  { id: '226', name: 'Caribbean & Latin America' },
  { id: '227', name: 'Austria, Germany & Switzerland' },
  { id: '228', name: 'Canada & USA' },
  { id: '229', name: 'Guinea & Kenya' },
  { id: '231', name: 'Asia & Europe' },
  { id: '232', name: 'Africa, Asia, Australia & Europe' },
  { id: '233', name: 'Western countries' },
  { id: '234', name: 'France, Italy, Spain & UK' },
  { id: '235', name: 'South Africa & Zimbabwe' },
  { id: '236', name: 'Australia or New Zealand' },
  { id: '237', name: 'UK & USA' },
  { id: '238', name: 'Spain & UK' },
  { id: '239', name: 'Kenya & Tanzania' },
  { id: '240', name: 'India, Paraguay, Tanzania & Uganda' },
  { id: '241', name: 'Brazil & South Africa' },
  { id: '242', name: 'Asia & Middle East' },
  { id: '243', name: 'UK (England & Wales)' },
  { id: '245', name: 'Netherlands & UK' },
  { id: '246', name: 'Ireland & UK' },
  { id: '247', name: 'Low income countries' },
  { id: '248', name: 'Asia, Australasia & Europe' },
  { id: '249', name: 'Australia, New Zealand & Oceania' },
  { id: '250', name: 'Africa, Asia & Middle East' },
  { id: '251', name: 'Central America, Mexico & South America' },
  { id: '252', name: 'Montserrat' },
  { id: '253', name: 'Anguilla' },
  { id: '254', name: 'American Samoa' },
  { id: '255', name: 'Cook Islands' },
  { id: '256', name: 'Guam' },
  { id: '257', name: 'Macao' },
  { id: '258', name: 'New Caledonia' },
  { id: '259', name: 'Niue' },
  { id: '260', name: 'Commonwealth Of Northern Mariana Islands' },
  { id: '261', name: 'Pitcairn Islands' },
  { id: '262', name: 'Tokelau' },
  { id: '263', name: 'Wallis and Futuna' },
  { id: '264', name: 'Other' },
  { id: '265', name: 'Global' },
  { id: '266', name: 'South Sudan' },
];

export default countries;
