import { useState, useEffect } from 'react';
import AuthService from '../../services/AuthService';
import { useNavigate, Link } from 'react-router-dom';
import Context from '../../components/Context';

const Login = ({ site, setPage, getProfile }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  let navigate = useNavigate();
  const Auth = new AuthService();

  useEffect(() => {
    setPage('login');
    if (Auth.loggedIn()) {
      navigate('/');
    }
  });

  const submit = (e) => {
    e.preventDefault();
    Auth.login(site, email, password)
      .then((res) => {
        if (res.data.error) {
          setError(res.data.error);
        } else {
          setError(null);
          getProfile();
          navigate('/');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div id="page-content">
      <div className="login-page">
        <h1>Log in</h1>
        {error && <div className="form-errors">{error}</div>}
        <form className="login-menu" onSubmit={submit}>
          <div className="form-group">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="md-check">
              <input type="checkbox" />
              <i className="primary"></i> Keep me signed in
            </label>
          </div>
          <button type="submit" className="btn primary">
            Log in
          </button>
        </form>
        <div className="my-4">
          <Link to="/forgot-password" className="text-primary _600">
            Forgot password?
          </Link>
        </div>
        <div>
          Do not have an account?
          <Link to="/signup" className="text-primary _600">
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Context(Login);
