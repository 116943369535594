import { useState, useCallback } from 'react';
import { getQueryStringValue, setQueryStringValue } from './queryString';

function useQueryString(key, initialValue) {
  let qsValue = getQueryStringValue(key);
  let initial = initialValue;
  if (qsValue !== undefined) {
    initial = Array.isArray(initialValue)
      ? (qsValue && qsValue.split(',')) || []
      : qsValue || initialValue;
  }
  const [value, setValue] = useState(initial);
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      const value = Array.isArray(newValue) ? newValue.join(',') : newValue;
      setQueryStringValue(key, value);
    },
    [key]
  );

  return [value, onSetValue];
}

export default useQueryString;
