import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ScrollToTop from './components/ScrollToTop';
import {
  Login,
  Signup,
  SignupSuccess,
  ConfirmEmail,
  ForgotPassword,
  ResetPassword,
} from './views/Accounts';

import Home from './views/Home';
import About from './views/About';
import Terms from './views/Terms';
import Latest from './views/Latest';
import Article from './views/Article';
import Search from './views/Search';
import Profile from './views/Profile';
import UserArticleView from './views/User/Articles';
import UserSearchView from './views/User/Searches';
import UnsubscribeView from './views/Unsubscribe';

import { SiteProvider, SiteConsumer } from './components/SiteContext';
import { PageProvider, PageConsumer } from './components/PageContext';
import { ModalProvider } from './components/ModalContext';
import { UserProvider } from './components/UserContext';

import {
  LanguageProvider,
  LanguageConsumer,
} from './components/LanguageContext';
import { GuidedSearchProvider } from './components/GuidedSearchContext';
import { LayerProvider, LayerConsumer } from './components/LayerContext';
import { Helmet } from 'react-helmet';
import Layer from './components/Layer';
import LayerGroup from './components/LayerGroup';
import HelpMenu from './components/HelpMenu';
import AccountMenu from './components/AccountMenu';
import FilterGroupsMenu from './components/FilterGroupsMenu';
import FiltersMenu from './components/FiltersMenu';
import CountriesMenu from './components/CountriesMenu';
import DateRangeMenu from './components/DateRangeMenu';

import MainMenu from './components/MainMenu';
import Header from './components/Header';
import Footer from './components/Footer';
import { LanguageChooser } from './components/LanguageContext';
import SignupMenu from './components/SignupMenu';
import LoginMenu from './components/LoginMenu';
import BestTipsMenu from './components/BestTipsMenu';

const App = () => {
  return (
    <SiteProvider>
      <SiteConsumer>
        {({ site }) => (
          <LanguageProvider site={site}>
            <LanguageConsumer>
              {({ t, updateLanguage }) => (
                <PageProvider>
                  <ModalProvider t={t}>
                    <UserProvider updateLanguage={updateLanguage}>
                      <Helmet>
                        <title>{t('site_name')}</title>
                      </Helmet>
                      <PageConsumer>
                        {({ page }) => (
                          <GuidedSearchProvider>
                            <Router>
                              <LayerProvider page={page} site={site}>
                                <LayerGroup>
                                  <Layer
                                    name="help"
                                    title={t('menus.help.title')}
                                  >
                                    <HelpMenu />
                                  </Layer>
                                  <Layer
                                    name="best"
                                    title={t('menus.help.title')}
                                  >
                                    <BestTipsMenu />
                                  </Layer>
                                  <Layer
                                    name="main"
                                    title={t('menus.main.title')}
                                  >
                                    <MainMenu />
                                  </Layer>
                                  <Layer
                                    name="languages"
                                    title={t('menus.languages.title')}
                                  >
                                    <LayerConsumer>
                                      {({ dismissAll }) => (
                                        <LanguageChooser
                                          site={site}
                                          isVisible={true}
                                          onDismissMenu={dismissAll}
                                        />
                                      )}
                                    </LayerConsumer>
                                  </Layer>
                                  <Layer
                                    name="signup"
                                    title={t('menus.signup.title')}
                                  >
                                    <SignupMenu />
                                  </Layer>
                                  <Layer
                                    name="login"
                                    title={t('menus.login.title')}
                                  >
                                    <LoginMenu />
                                  </Layer>
                                  <Layer
                                    name="account"
                                    title={t('menus.login.profile')}
                                  >
                                    <AccountMenu />
                                  </Layer>
                                  <Layer
                                    name="filter-groups"
                                    title={t('menus.filter_groups.title')}
                                  >
                                    <FilterGroupsMenu setOverlayContent={''} />
                                  </Layer>
                                  <Layer name="filters">
                                    <FiltersMenu />
                                  </Layer>
                                  <Layer
                                    name="countries"
                                    title={t('menus.countries.title')}
                                  >
                                    <CountriesMenu />
                                  </Layer>
                                  <Layer
                                    name="date-range"
                                    title={t('menus.date_range.date_range')}
                                  >
                                    <DateRangeMenu />
                                  </Layer>
                                </LayerGroup>
                                <LayerConsumer>
                                  {({ toggleLayer }) => (
                                    <>
                                      <Header onShowMenu={toggleLayer} />
                                      <ToastContainer position="top-center" />
                                    </>
                                  )}
                                </LayerConsumer>
                                <ScrollToTop />
                                <Routes>
                                  <Route exact path="/" element={<Home />} />
                                  <Route
                                    exact
                                    path="/search"
                                    element={<Search />}
                                  />
                                  <Route
                                    exact
                                    path="/about"
                                    element={<About />}
                                  />
                                  <Route
                                    exact
                                    path="/terms"
                                    element={<Terms />}
                                  />
                                  <Route
                                    exact
                                    path="/signup"
                                    element={<Signup />}
                                  />
                                  <Route
                                    exact
                                    path="/signup-success"
                                    element={<SignupSuccess />}
                                  />
                                  <Route
                                    exact
                                    path="/signup-confirm/:token"
                                    element={<ConfirmEmail />}
                                  />
                                  <Route
                                    exact
                                    path="/login"
                                    element={<Login />}
                                  />
                                  <Route
                                    exact
                                    path="/forgot-password"
                                    element={<ForgotPassword />}
                                  />
                                  <Route
                                    exact
                                    path="/reset-password/:token"
                                    element={<ResetPassword />}
                                  />
                                  <Route
                                    exact
                                    path="/latest-content"
                                    element={<Latest />}
                                  />
                                  <Route
                                    exact
                                    path="/articles/:id"
                                    element={<Article />}
                                  />
                                  <Route
                                    exact
                                    path="/profile"
                                    element={<Profile />}
                                  />

                                  <Route
                                    exact
                                    path="/user/articles"
                                    element={<UserArticleView />}
                                  />
                                  <Route
                                    exact
                                    path="/user/searches"
                                    element={<UserSearchView />}
                                  />
                                  <Route
                                    exact
                                    path="/unsubscribe"
                                    element={<UnsubscribeView />}
                                  />
                                </Routes>
                                <Footer />
                              </LayerProvider>
                            </Router>
                          </GuidedSearchProvider>
                        )}
                      </PageConsumer>
                    </UserProvider>
                  </ModalProvider>
                </PageProvider>
              )}
            </LanguageConsumer>
          </LanguageProvider>
        )}
      </SiteConsumer>
    </SiteProvider>
  );
};

export default App;
