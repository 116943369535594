import { useState, useEffect, Fragment } from 'react';
import Context from './Context';
import AppliedFilters from './AppliedFilters';
import LayerToggle from './LayerToggle';
import { hse, sse, cvd } from '../data';

const Menus = ({ t, tree, filters, onShowMenu, onFilterChange }) => {
  if (!tree) return null;
  return Object.keys(tree).map((key) => (
    <Menu
      key={key}
      menu={tree[key]}
      t={t}
      filters={filters}
      onShowMenu={onShowMenu}
      onFilterChange={onFilterChange}
    />
  ));
};

const getTitle = (item, t) => {
  // const parts = item.legacyKey.split('_');
  // const section_title = t(`filters.${parts[0]}`);
  // const filter_group_title = t(`filters.${parts[0]}_${parts[1]}`);
  // return t('menus.filters.title', { section_title, filter_group_title });
  //Util.sentenceCase(title);
  return '';
};

const getMenuName = (type) => {
  let menu = '';
  switch (type) {
    case 'countries':
      menu = 'countries_countries';
      break;
    case 'dateRange':
      menu = 'dateRange';
      break;
    default:
      menu = 'filters';
      break;
  }
  return menu;
};

const Menu = ({ menu, t, filters, onShowMenu, onFilterChange }) => {
  const hasApplied = (item) => {
    let results = [];
    results = [filters.includes(item.legacyKey)];
    if (item.children && item.children.length > 0) {
      results = results.concat(
        item.children.some((child) => hasApplied(child))
      );
    }
    return results.some((a) => a);
  };
  const getMenuContext = (item) => {
    return {
      menu: item,
      filters,
      onFilterChange,
      onShowFilterGroup: onShowMenu,
    };
  };

  const topLevelClick = (e, { legacyKey }) => {
    e.preventDefault();
    const filters2 = filters.includes(legacyKey)
      ? filters.filter((f) => f !== legacyKey)
      : [...filters, legacyKey];
    onFilterChange(filters2);
  };
  return (
    <Fragment>
      <h2>{menu.title}</h2>
      <ul className="menu-list">
        {menu.items.map((item, i) => {
          let className = 'menu-item';
          if (hasApplied(item)) {
            className += ' filter-applied';
          }
          let title = t(`filters.${item.legacyKey}`);
          return (
            <li className={className} key={`filter-group-${i}`}>
              {item.children || item.type === 'dateRange' ? (
                <LayerToggle
                  className="menu-item-text"
                  menu={getMenuName(item.type)}
                  title={getTitle(item, t)}
                  context={getMenuContext(item)}
                  onToggle={onShowMenu}
                >
                  {title}
                </LayerToggle>
              ) : (
                <a
                  className="layer-toggle layer-toggle-filters menu-item-text"
                  href="/#"
                  onClick={(e) => topLevelClick(e, item)}
                >
                  {title}
                </a>
              )}
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
};

const FilterGroupsMenu = ({
  site,
  language,
  t,
  filters = [],
  toggleLayer,
  onFilterChange,
  context,
}) => {
  const [tree, setTree] = useState();
  const [local_filters, setLocalFilters] = useState(
    (context && context.filters) || filters
  );

  useEffect(() => {
    initData();
  }, [site]);

  const initData = () => {
    let tree_data = {};
    switch (site) {
      case 'hse':
        tree_data = hse.tree;
        break;
      case 'sse':
        tree_data = sse.tree;
        break;
      case 'cvd':
        tree_data = cvd.tree;
        break;
      default:
        break;
    }
    setTree(tree_data);
  };

  const filter_changed = (f) => {
    const change_fn = (context && context.onFilterChange) || onFilterChange;
    change_fn(f);
    setLocalFilters(f);
  };

  return (
    <div className="filter-groups-menu-wrapper">
      <div className="filter-groups-menu-title">
        <span>{t('menus.filter_groups.title')}</span>
      </div>
      <div className="filter-groups-menu nested-menu">
        <AppliedFilters
          tree={tree}
          filters={local_filters}
          onShowMenu={toggleLayer}
          onFilterChange={filter_changed}
        />
        <Menus
          t={t}
          tree={tree}
          filters={local_filters}
          onShowMenu={toggleLayer}
          onFilterChange={filter_changed}
        />
      </div>
    </div>
  );
};

export default Context(FilterGroupsMenu);
