import { useState, useEffect } from 'react';
import Context from './Context';

const DateRangeMenu = ({ t, context }) => {
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  useEffect(() => {
    const date_range_index = context.filters.findIndex((f) => {
      const parts = f.split('_');
      return parts.length === 4 && parts[0] === '0' && parts[1] === '12';
    });

    if (date_range_index !== -1) {
      const date_range = context.filters[date_range_index];
      const parts = date_range.split('_');
      setStart(parseInt(parts[2]));
      setEnd(parseInt(parts[3]));
    }
  }, [context.filters]);

  const changeStart = (e) => {
    const { value } = e.target;
    setStart(value);
    applyFilter(value, end);
  };

  const changeEnd = (e) => {
    const { value } = e.target;
    setEnd(value);
    applyFilter(start, value);
  };

  const applyFilter = (s, e) => {
    let filters = getBaseFilters();
    if (s.toString().length === 4 && e.toString().length === 4) {
      context.onFilterChange(filters.concat(`0_12_${s}_${e}`));
    } else if (s.toString().length === 0 && e.toString().length === 0) {
      context.onFilterChange(filters);
    }
  };

  const getBaseFilters = () => {
    let filters = [].concat(context.filters);
    const date_range_index = context.filters.findIndex((f) => {
      const parts = f.split('_');
      return parts.length === 4 && parts[0] === '0' && parts[1] === '12';
    });
    filters.splice(date_range_index, 1);
    return filters;
  };

  const trim = (e) => {
    const { value } = e.target;
    e.target.value = value.slice(0, 4);
  };

  const clearStart = (e) => {
    if (e.keyCode === 8) {
      changeStart(e);
    }
  };
  const clearEnd = (e) => {
    if (e.keyCode === 8) {
      changeEnd(e);
    }
  };

  return (
    <form className="date-range-menu">
      <span className="label">{t('menus.date_range.published')}</span>
      <input
        type="number"
        maxLength="4"
        min="1900"
        name="start"
        onChange={changeStart}
        onInput={trim}
        onKeyUp={clearStart}
        defaultValue={start}
        placeholder={t('menus.date_range.placeholder')}
      />
      <span className="seperator">-</span>
      <input
        type="number"
        maxLength="4"
        max={new Date().getFullYear()}
        name="end"
        onChange={changeEnd}
        onKeyUp={clearEnd}
        onInput={trim}
        defaultValue={end}
        placeholder={t('menus.date_range.placeholder')}
      />
      <p className="instructions">{t('menus.date_range.instructions')}</p>
    </form>
  );
};

export default Context(DateRangeMenu);
