//import { saveAs } from 'file-saver';

/*
 * Profile service for interacting with the API backend
 */
import AuthService from './AuthService';

const API = () => {
  const Auth = new AuthService();
  const is_localhost = window.location.origin.indexOf('localhost') > 0;
  const baseURL = is_localhost ? 'http://localhost:5001' : '/api';

  const getQueryString = (params) => {
    const esc = encodeURIComponent;
    return Object.keys(params)
      .map((k) => esc(k) + '=' + esc(params[k]))
      .join('&');
  };

  const call = async (url, opts) => {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (Auth.loggedIn()) {
      headers['Authorization'] = `Bearer ${Auth.getToken()}`;
    }

    if (opts) {
      if (opts.method === 'GET' || opts.method === 'DELETE') {
        const qs = '?' + getQueryString(opts.data);
        url = url + qs;
      }
    }

    return fetch(`${baseURL}${url}`, {
      headers,
      ...opts,
    })
      .then(_checkStatus)
      .then((resp) => resp.json());
  };

  const formatDate = (date) => {
    date = date || new Date();
    return (
      date.getFullYear().toString() +
      (1 === (date.getMonth() + 1).toString().length ? '0' : '') +
      (date.getMonth() + 1).toString() +
      (1 === date.getDate().toString().length ? '0' : '') +
      date.getDate().toString() +
      date.getHours().toString() +
      date.getMinutes().toString() +
      date.getSeconds().toString()
    );
  };

  const download = async (url, opts) => {
    let site = opts.data.type.toUpperCase();
    site = site === 'CVD' ? 'COVID' : site;
    const headers = {};
    if (Auth.loggedIn()) {
      headers['Authorization'] = `Bearer ${Auth.getToken()}`;
    }
    if (opts) {
      if (opts.method === 'GET') {
        const qs = '?' + getQueryString(opts.data);
        url = url + qs;
      }
    }
    const response = await fetch(`${baseURL}${url}`, {
      headers,
      ...opts,
    });
    response.text().then((text) => {
      const blob = new Blob([text], {
        type: 'text/csv;charset=utf-8',
      });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      const filename = `${site}${formatDate()}.csv`;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    });
  };

  const _checkStatus = (resp) => {
    // raises an error in case response status is not a success
    if (resp.status >= 200 && resp.status < 300) {
      // Success status lies between 200 to 300
      return resp;
    } else {
      let error = new Error(resp.statusText);
      error.response = resp;

      // Unauthorized
      if (resp.status === 401) {
        Auth.logout();
        document.location = window.location.origin;
      }
      throw error;
    }
  };

  return {
    call,
    download,
  };
};

export default API();
