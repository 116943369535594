const types = [
  { value: 'Evidence briefs for policy', label: 'Evidence briefs for policy' },
  {
    value: 'Overviews of evidence syntheses',
    label: 'Overviews of evidence syntheses',
  },
  {
    label: 'Evidence syntheses of effects',
    value: 'Evidence syntheses of effects',
  },
  {
    value: 'Evidence syntheses addressing other questions',
    label: 'Evidence syntheses addressing other questions',
  },
  {
    value: 'Evidence syntheses in progress',
    label: 'Evidence syntheses in progress',
  },
  {
    value: 'Evidence syntheses being planned',
    label: 'Evidence syntheses being planned',
  },
  {
    value: 'Economic evaluations and costing studies',
    label: 'Economic evaluations and costing studies',
  },
  { value: 'Health reform descriptions', label: 'Health reform descriptions' },
  { value: 'Health system descriptions', label: 'Health system descriptions' },
  {
    value:
      'No, after reviewing the document types and eligibility criteria, this record is not eligible for inclusions in HSE.',
    label:
      'No, after reviewing the document types and eligibility criteria, this record is not eligible for inclusions in HSE.',
  },
];

const questionTypes = [
  {
    label: 'Many',
    value: 'Many',
  },
  {
    label: 'Effectiveness',
    value: 'Effectiveness',
  },
  {
    label: 'Not effectiveness',
    value: 'Not effectiveness',
  },
  {
    label:
      'Cost-effectiveness/benefit/utility analysis or description of costs',
    value:
      'Cost-effectiveness/benefit/utility analysis or description of costs',
  },
  {
    label: 'Description',
    value: 'Description',
  },
];

const tree = {
  checkedKeysHST: {
    title: 'Health Systems Topic',
    legacyKey: '0_1',
    items: [
      {
        title: 'Governance arrangements',
        key: 'governance',
        legacyKey: '2_1001',
        children: [
          {
            title: 'Policy authority',
            key: 'governancePolicy',
            legacyKey: '2_1005',
            children: [
              {
                title: 'Centralization/decentralization of policy authority',
                key: 'governancePolicyCentralization',
                legacyKey: '2_1022',
              },
              {
                title:
                  "Accountability of the state sector's role in financing & delivery",
                key: 'governancePolicyAccountability',
                legacyKey: '2_1023',
              },
              {
                title:
                  "Stewardship of the non-state sector's role in financing & delivery",
                key: 'governancePolicyStewardship',
                legacyKey: '2_1024',
              },
              {
                title:
                  'Decision-making authority about who is covered and what can or must be provided to them',
                key: 'governancePolicyDecisionMaking',
                legacyKey: '2_1025',
              },
              {
                title: 'Corruption protections',
                key: 'governancePolicyCorruptionProtections',
                legacyKey: '2_1026',
              },
            ],
          },
          {
            title: 'Organizational authority',
            key: 'organization',
            legacyKey: '2_1006',
            children: [
              {
                title: 'Ownership',
                key: 'organizationOwnership',
                legacyKey: '2_1027',
              },
              {
                title: 'Management approaches',
                key: 'organizationManagement',
                legacyKey: '2_1028',
              },
              {
                title: 'Accreditation',
                key: 'organizationAccreditation',
                legacyKey: '2_1029',
              },
              {
                title: 'Networks/multi-institutional arrangements',
                key: 'organizationNetworks',
                legacyKey: '2_1030',
              },
            ],
          },
          {
            title: 'Commercial authority',
            key: 'commercial',
            legacyKey: '2_1007',
            children: [
              {
                title: 'Licensure & registration requirements',
                key: 'commercialLicense',
                legacyKey: '2_1031',
              },
              {
                title: 'Patents & profits',
                key: 'commercialPatentsAndProfits',
                legacyKey: '2_1032',
              },
              {
                title: 'Pricing & purchasing',
                key: 'commercialPricingAndPurchasing',
                legacyKey: '2_1033',
              },
              {
                title: 'Marketing',
                key: 'commercialMarketing',
                legacyKey: '2_1034',
              },
              {
                title: 'Sales & dispensing',
                key: 'commercialSalesAndDispensing',
                legacyKey: '2_1035',
              },
              {
                title: 'Commercial liability',
                key: 'commercialLiability',
                legacyKey: '2_1036',
              },
            ],
          },
          {
            title: 'Professional authority',
            key: 'professional',
            legacyKey: '2_1008',
            children: [
              {
                title: 'Training & licensure requirements',
                key: 'professionalTrainingAndLicense',
                legacyKey: '2_1037',
              },
              {
                title: 'Scope of practice',
                key: 'professionalScopeOfPractice',
                legacyKey: '2_1038',
              },
              {
                title: 'Setting of practice',
                key: 'settingOfPractice',
                legacyKey: '2_1039',
              },
              {
                title: 'Continuing competence',
                key: 'professionalContinuingCompetance',
                legacyKey: '2_1040',
              },
              {
                title: 'Quality & safety',
                key: 'professionalQualityAndSafety',
                legacyKey: '2_1041',
              },
              {
                title: 'Professional liability',
                key: 'professionalLiability',
                legacyKey: '2_1042',
              },
              {
                title: 'Strike/job action',
                key: 'professionalStrikeJobAction',
                legacyKey: '2_1132',
              },
            ],
          },
          {
            title: 'Consumer & stakeholder involvement',
            key: 'consumer',
            legacyKey: '2_1009',
            children: [
              {
                title:
                  'Consumer participation in policy & organizational decisions',
                key: 'consumerPolicyDecisions',
                legacyKey: '2_1043',
              },
              {
                title: 'Consumer participation in system monitoring',
                key: 'consumerSystemMonitoring',
                legacyKey: '2_1044',
              },
              {
                title: 'Consumer participation in service delivery',
                key: 'consumerServiceDelivery',
                legacyKey: '2_1045',
              },
              {
                title: 'Consumer complaints management',
                key: 'consumerComplaintsManagement',
                legacyKey: '2_1046',
              },
              {
                title:
                  'Stakeholder participation in policy & organizational decisions (or monitoring)',
                key: 'consumerStakeholder',
                legacyKey: '2_1047',
              },
            ],
          },
        ],
      },
      {
        title: 'Financial arrangements',
        key: 'financialArrangements',
        legacyKey: '2_1002',
        children: [
          {
            title: 'Financing systems',
            key: 'financialSystems',
            legacyKey: '2_1010',
            children: [
              {
                title: 'Taxation',
                key: 'financialTaxation',
                legacyKey: '2_1048',
              },
              {
                title: 'Social health insurance',
                key: 'financialSocialHealthInsurance',
                legacyKey: '2_1049',
              },
              {
                title: 'Community-based health insurance',
                key: 'financialCommunityBasedHealthInsurance',
                legacyKey: '2_1050',
              },
              {
                title: 'Community loan funds',
                key: 'financialCommunityLoanFunds',
                legacyKey: '2_1051',
              },
              {
                title: 'Private insurance',
                key: 'financialPrivateInsurance',
                legacyKey: '2_1052',
              },
              {
                title: 'Health savings accounts (Individually financed)',
                key: 'financialHealthSavingsAccountsIndividuallyFinanced',
                legacyKey: '2_1053',
              },
              {
                title: 'User fees',
                key: 'financialUserFees',
                legacyKey: '2_1054',
              },
              {
                title: 'Donor contributions',
                key: 'financialDonorContributions',
                legacyKey: '2_1055',
              },
              {
                title: 'Fundraising',
                key: 'financialFundraising',
                legacyKey: '2_1131',
              },
            ],
          },
          {
            title: 'Funding organizations',
            key: 'fundingOrganization',
            legacyKey: '2_1011',
            children: [
              {
                title: 'Fee-for-service (Funding)',
                key: 'fundingOrganizationServiceFees',
                legacyKey: '2_1056',
              },
              {
                title: 'Capitation (Funding)',
                key: 'fundingOrganizationCapitation',
                legacyKey: '2_1057',
              },
              {
                title: 'Global budget',
                key: 'fundingOrganizationGlobalBudget',
                legacyKey: '2_1058',
              },
              {
                title: 'Case-mix funding',
                key: 'fundingOrganizationCaseMixFunding',
                legacyKey: '2_1059',
              },
              {
                title: 'Targeted payments/penalties (Funding)',
                key: 'fundingOrganizationPaymentsPenalties',
                legacyKey: '2_1061',
              },
            ],
          },
          {
            title: 'Remunerating providers',
            key: 'remuneratingProvider',
            legacyKey: '2_1012',
            children: [
              {
                title: 'Fee-for-service (Remuneration)',
                key: 'remuneratingProviderServiceFees',
                legacyKey: '2_1062',
              },
              {
                title: 'Capitation (Remuneration)',
                key: 'remuneratingProviderCapitation',
                legacyKey: '2_1063',
              },
              {
                title: 'Salary',
                key: 'remuneratingProviderSalary',
                legacyKey: '2_1064',
              },
              {
                title: 'Episode-based payment',
                key: 'remuneratingProviderEpisodeBasedPayment',
                legacyKey: '2_1065',
              },
              {
                title: 'Fundholding',
                key: 'remuneratingProviderFundholding',
                legacyKey: '2_1066',
              },
              {
                title: 'Targeted payments/penalties (Remuneration)',
                key: 'remuneratingProviderPaymentPenalties',
                legacyKey: '2_1068',
              },
            ],
          },
          {
            title: 'Purchasing products & services',
            key: 'purchasing',
            legacyKey: '2_1013',
            children: [
              {
                title: 'Scope & nature of insurance plans',
                key: 'purchasingScope',
                legacyKey: '2_1069',
              },
              {
                title:
                  'Lists of covered/reimbursed organizations, providers, services & products',
                key: 'purchasingCoveredReimbursedOrganizations',
                legacyKey: '2_1070',
              },
              {
                title:
                  'Restrictions in coverage/reimbursement rates for organizations, providers, services & products',
                key: 'purchasingRestrictions',
                legacyKey: '2_1071',
              },
              {
                title:
                  'Caps on coverage/reimbursement for organizations, providers, services & products',
                key: 'purchasingCaps',
                legacyKey: '2_1072',
              },
              {
                title:
                  'Prior approval requirements for organizations, providers, services & products',
                key: 'purchasingApprovalRequirements',
                legacyKey: '2_1073',
              },
              {
                title: 'Lists of substitutable services & products',
                key: 'purchasingSubstitutes',
                legacyKey: '2_1074',
              },
            ],
          },
          {
            title: 'Incentivizing consumers',
            key: 'incentivizingConsumer',
            legacyKey: '2_1014',
            children: [
              {
                title: 'Premium (level & features)',
                key: 'incentivizingConsumerPremium',
                legacyKey: '2_1075',
              },
              {
                title: 'Cost-sharing',
                key: 'incentivizingConsumerCostSharing',
                legacyKey: '2_1076',
              },
              {
                title: 'Health savings accounts (Third party contributions)',
                key: 'incentivizingConsumerSavingsThirdPartyContributions',
                legacyKey: '2_1077',
              },
              {
                title: 'Targeted payments/penalties (Incentivizing consumers)',
                key: 'incentivizingConsumerSavingsTargetedPaymentsPenalties',
                legacyKey: '2_1078',
              },
            ],
          },
        ],
      },
      {
        title: 'Delivery arrangements',
        key: 'deliveryArrangement',
        legacyKey: '2_1003',
        children: [
          {
            title: "How care is designed to meet consumers' needs",
            key: 'deliveryArrangementCare',
            legacyKey: '2_1015',
            children: [
              {
                title: 'Availability of care',
                key: 'deliveryArrangementCareAvailability',
                legacyKey: '2_1079',
              },
              {
                title: 'Timely access to care',
                key: 'deliveryArrangementCareTimelyAcess',
                legacyKey: '2_1080',
              },
              {
                title: 'Culturally appropriate care',
                key: 'deliveryArrangementCareCulturallyAppropriate',
                legacyKey: '2_1081',
              },
              {
                title: 'Case management',
                key: 'deliveryArrangementCareCaseManagement',
                legacyKey: '2_1082',
              },
              {
                title: 'Package of care/care pathways/disease management',
                key: 'deliveryArrangementCareDiseaseManagement',
                legacyKey: '2_1083',
              },
              {
                title: 'Group care',
                key: 'deliveryArrangementCareGroup',
                legacyKey: '2_1084',
              },
            ],
          },
          {
            title: 'By whom care is provided',
            key: 'careProvider',
            legacyKey: '2_1016',
            children: [
              {
                title: 'System - Need, demand & supply',
                key: 'careProviderSystemDemandSupply',
                legacyKey: '2_1085',
              },
              {
                title: 'System - Recruitment, retention & transitions',
                key: 'careProviderSystemRecruitmentRetention',
                legacyKey: '2_1086',
              },
              {
                title: 'System - Performance management',
                key: 'careProviderSystemPerformanceManagement',
                legacyKey: '2_1087',
              },
              {
                title: 'Workplace conditions - Provider satisfaction',
                key: 'careProviderWorkplaceConditionsProviderSatisfaction',
                legacyKey: '2_1088',
              },
              {
                title: 'Workplace conditions - Health & safety',
                key: 'careProviderWorkplaceConditionsHealthSafety',
                legacyKey: '2_1089',
              },
              {
                title: 'Skill mix - Role performance',
                key: 'careProviderSkillMixRolePerformance',
                legacyKey: '2_1090',
              },
              {
                title: 'Skill mix - Role expansion or extension',
                key: 'careProviderSkillMixRoleExpansionOrExtension',
                legacyKey: '2_1091',
              },
              {
                title: 'Skill mix - Task shifting / substitution',
                key: 'careProviderSkillMixTaskShiftingSubstitution',
                legacyKey: '2_1092',
              },
              {
                title: 'Skill mix - Multidisciplinary teams',
                key: 'careProviderSkillMixMultidisciplinaryTeams',
                legacyKey: '2_1093',
              },
              {
                title: 'Skill mix - Volunteers or caregivers',
                key: 'careProviderSkillMixVolunteersOrCaregivers',
                legacyKey: '2_1094',
              },
              {
                title:
                  'Skill mix - Communication & case discussion between distant health professionals',
                key: 'careProviderSkillMixCommunicationDistantHealthProfessionals',
                legacyKey: '2_1095',
              },
              {
                title: 'Staff - Training',
                key: 'careProviderStaffTraining',
                legacyKey: '2_1096',
              },
              {
                title: 'Staff - Support',
                key: 'careProviderStaffSupport',
                legacyKey: '2_1097',
              },
              {
                title: 'Staff - Workload/workflow/intensity',
                key: 'careProviderStaffWorkloadWorkflowIntensity',
                legacyKey: '2_1098',
              },
              {
                title: 'Staff - Continuity of care',
                key: 'careProviderStaffContinuityOfCare',
                legacyKey: '2_1099',
              },
              {
                title: 'Staff/self - Shared decision-making',
                key: 'careProviderStaffSelfSharedDecisionMaking',
                legacyKey: '2_1100',
              },
              {
                title: 'Self-management',
                key: 'careProviderSelfManagement',
                legacyKey: '2_1101',
              },
            ],
          },
          {
            title: 'Where care is provided',
            key: 'careSource',
            legacyKey: '2_1017',
            children: [
              {
                title: 'Site of service delivery',
                key: 'careSourceSiteOfServiceDelivery',
                legacyKey: '2_1102',
              },
              {
                title: 'Physical structure, facilities & equipment',
                key: 'careSourcePhysicalstructureFacilitiesEquipment',
                legacyKey: '2_1103',
              },
              {
                title: 'Organizational scale',
                key: 'careSourceOrganizationalScale',
                legacyKey: '2_1104',
              },
              {
                title: 'Integration of services',
                key: 'careSourceIntegrationOfServices',
                legacyKey: '2_1105',
              },
              {
                title: 'Continuity of care',
                key: 'careSourceContinuityOfCare',
                legacyKey: '2_1106',
              },
              {
                title: 'Outreach',
                key: 'careSourceOutreach',
                legacyKey: '2_1107',
              },
            ],
          },
          {
            title: 'With what supports is care provided',
            key: 'careSupport',
            legacyKey: '2_1018',
            children: [
              {
                title: 'Health record systems',
                key: 'careSupportHealthRecordSystems',
                legacyKey: '2_1108',
              },
              {
                title: 'Electronic health record',
                key: 'careSupportElectronicHealthRecord',
                legacyKey: '2_1109',
              },
              {
                title: 'Other ICT that support individuals who provide care',
                key: 'careSupportOtherICTThatSupportIndividuals',
                legacyKey: '2_1110',
              },
              {
                title: 'ICT that support individuals who receive care',
                key: 'careSupportICTThatSupportIndividualsWhoReceiveCare',
                legacyKey: '2_1111',
              },
              {
                title: 'Quality monitoring and improvement systems',
                key: 'careSupportQualityMonitoringAndImprovementSystems',
                legacyKey: '2_1112',
              },
              {
                title: 'Safety monitoring and improvement systems',
                key: 'careSupportSafetyMonitoringAndImprovementSystems',
                legacyKey: '2_1113',
              },
            ],
          },
        ],
      },
      {
        title: 'Implementation strategies',
        key: 'implementationStrategies',
        legacyKey: '2_1004',
        children: [
          {
            title: 'Consumer-targeted strategy',
            key: 'implementationConsumerTargetedStrategy',
            legacyKey: '2_1019',
            children: [
              {
                title: 'Information or education provision',
                key: 'implementationInformationOrEducationProvision',
                legacyKey: '2_1114',
              },
              {
                title: 'Behaviour change support',
                key: 'implementationBehaviourChangeSupport',
                legacyKey: '2_1115',
              },
              {
                title: 'Skills and competencies development',
                key: 'implementationSkillsAndCompetenciesDevelopment',
                legacyKey: '2_1116',
              },
              {
                title: '(Personal) Support',
                key: 'implementationPersonalSupport',
                legacyKey: '2_1117',
              },
              {
                title: 'Communication and decision-making facilitation',
                key: 'implementationCommunicationAndDecisionMakingFacilitation',
                legacyKey: '2_1118',
              },
              {
                title: 'System participation',
                key: 'implementationSystemParticipation',
                legacyKey: '2_1119',
              },
            ],
          },
          {
            title: 'Provider-targeted strategy',
            key: 'providerStrategies',
            legacyKey: '2_1020',
            children: [
              {
                title: 'Educational material',
                key: 'providerStrategyEducationalMaterial',
                legacyKey: '2_1120',
              },
              {
                title: 'Educational meeting',
                key: 'providerStrategyEducationalMeeting',
                legacyKey: '2_1121',
              },
              {
                title: 'Educational outreach visit',
                key: 'providerStrategyEducationalOutreachVisit',
                legacyKey: '2_1122',
              },
              {
                title: 'Local opinion leader',
                key: 'providerStrategyLocalOpinionLeader',
                legacyKey: '2_1123',
              },
              {
                title: 'Local consensus process',
                key: 'providerStrategyLocalConsensusProcess',
                legacyKey: '2_1124',
              },
              {
                title: 'Peer review',
                key: 'providerStrategyPeerReview',
                legacyKey: '2_1125',
              },
              {
                title: 'Audit and feedback',
                key: 'providerStrategyAuditAndFeedback',
                legacyKey: '2_1126',
              },
              {
                title: 'Reminders and prompts',
                key: 'providerStrategyRemindersAndPrompts',
                legacyKey: '2_1127',
              },
              {
                title: 'Tailored intervention',
                key: 'providerStrategyTailoredIntervention',
                legacyKey: '2_1128',
              },
              {
                title: 'Patient-mediated intervention',
                key: 'providerStrategyPatientMediatedIntervention',
                legacyKey: '2_1129',
              },
              {
                title: 'Multi-faceted intervention',
                key: 'providerStrategyMultiFacetedIntervention',
                legacyKey: '2_1130',
              },
            ],
          },
          {
            title: 'Organization-targeted strategy',
            key: 'organizationStrategy',
            legacyKey: '2_1021',
          },
        ],
      },
    ],
  } /*
  checkedKeysCA: {
    title: 'Canadian Areas',
    items: [
      {
        title: 'Home and community care',
        key: 'canadianHomeAndCommunityCare',
      },
      {
        title: 'Mental health addiction services',
        key: 'canadianMentalHealthAndAddictionServices',
      },
      {
        title: 'Indigenous health (Fedral)',
        key: 'canadianIndigenousHealthFederal',
      },
      { title: 'Aging (emergent)', key: 'canadianAgingEmergent' },
    ],
  },*/,
  checkedDomain: {
    title: 'Domains',
    legacyKey: '0_2',
    items: [
      {
        title: 'Conditions',
        key: 'conditions',
        legacyKey: '2_1',
        children: [
          {
            title: 'Infectious diseases',
            key: 'conditionInfectiousDiseases',
            legacyKey: '2_5',
            children: [
              { title: 'HIV/AIDS', key: 'conditionHIVAIDS', legacyKey: '2_22' },
              {
                title: 'Tuberculosis',
                key: 'conditionTuberculosis',
                legacyKey: '2_23',
              },
              { title: 'Malaria', key: 'conditionMalaria', legacyKey: '2_24' },
              {
                title: 'Diarrhoeal disease',
                key: 'conditionDiarrhoealDisease',
                legacyKey: '2_25',
              },
              {
                title: 'Lower respiratory infections',
                key: 'conditionLowerRespiratoryInfections',
                legacyKey: '2_26',
              },
            ],
          },
          {
            title: 'Non-communicable diseases',
            key: 'conditionNonCommunicableDiseases',
            legacyKey: '2_6',
            children: [
              { title: 'Cancer', key: 'conditionCancer', legacyKey: '2_27' },
              {
                title: 'Cardiovascular disease',
                key: 'conditionCardiovascularDisease',
                legacyKey: '2_28',
              },
              {
                title: 'Diabetes',
                key: 'conditionDiabetes',
                legacyKey: '2_29',
              },
              {
                title: 'Alzheimer and other dementias',
                key: 'conditionAlzheimerAndOtherDementias',
                legacyKey: '2_30',
              },
              {
                title: 'Chronic obstructive pulmonary disease',
                key: 'conditionsChronicObstructivePulmonaryDisease',
                legacyKey: '2_31',
              },
            ],
          },
          {
            title: 'Other',
            key: 'conditionOther',
            legacyKey: '2_7',
            children: [
              {
                title: 'Accidents/injuries',
                key: 'conditionOtherAccidentsInjuries',
                legacyKey: '2_33',
              },
              {
                title: 'Maternal and child health',
                key: 'conditionOtherMaternalAndChildHealth',
                legacyKey: '2_32',
              },
              {
                title: 'Mental health and addictions',
                key: 'conditionMentalHealthAddictions',
                legacyKey: '2_34',
              },
              {
                title: 'Oral health',
                key: 'conditionOtherOralHealth',
                legacyKey: '2_39',
              },
            ],
          },
        ],
      },
      {
        title: 'Technologies',
        key: 'conditionTechnologies',
        legacyKey: '2_2',
        children: [
          { title: 'Drugs', key: 'conditionTechnologyDrugs', legacyKey: '2_8' },
          {
            title: 'Devices',
            key: 'conditionTechnologyDevices',
            legacyKey: '2_9',
          },
          {
            title: 'Diagnostics',
            key: 'conditionTechnologyDiagnostics',
            legacyKey: '2_10',
          },
          {
            title: 'Surgery',
            key: 'conditionTechnologySurgery',
            legacyKey: '2_11',
          },
        ],
      },
      {
        title: 'Sectors',
        key: 'conditionSectors',
        legacyKey: '2_3',
        children: [
          {
            title: 'Home and community care',
            key: 'conditionSectorPrimaryCare',
            legacyKey: '2_13',
          },
          {
            title: 'Primary care',
            key: 'primaryCare',
            legacyKey: '2_12',
          },
          {
            title: 'Specialty care',
            key: 'conditionSectorSpecialtyCare',
            legacyKey: '2_14',
          },
          {
            title: 'Rehabilitation care',
            key: 'conditionSectorRehabilitationCare',
            legacyKey: '2_15',
          },
          {
            title: 'Long-term care',
            key: 'conditionSectorLongTermCare',
            legacyKey: '2_16',
          },
          {
            title: 'Public health',
            key: 'conditionSectorPublicHealth',
            legacyKey: '2_37',
          },
        ],
      },
      {
        title: 'Providers',
        key: 'conditionProviders',
        legacyKey: '2_4',
        children: [
          {
            title: 'Physicians',
            key: 'conditionProviderPhysician',
            legacyKey: '2_17',
            children: [
              {
                title: 'Generalists',
                key: 'conditionProviderGeneralist',
                legacyKey: '2_35',
              },
              {
                title: 'Specialists',
                key: 'conditionProviderSpecialist',
                legacyKey: '2_36',
              },
            ],
          },
          {
            title: 'Nurses',
            key: 'conditionProviderNurse',
            legacyKey: '2_18',
          },
          {
            title: 'Pharmacists',
            key: 'conditionProviderPharmacist',
            legacyKey: '2_19',
          },
          {
            title: 'Allied health professionals',
            key: 'conditionProviderAlliedHealthProfessional',
            legacyKey: '2_20',
          },
          {
            title: 'Lay/community health workers',
            key: 'conditionProviderLayCommunityCealthWorker',
            legacyKey: '2_21',
          },
          {
            title: 'Informal/family caregivers',
            key: 'conditionProviderInformalFamilyCaregivers',
            legacyKey: '2_38',
          },
        ],
      },
    ],
  },
  checkedLMIC: {
    title: 'LMIC Focus',
    legacyKey: '0_9',
    items: [
      {
        title: 'Target of document',
        key: 'lmicTargetOfDocument',
        legacyKey: '9_1',
      },
      {
        title: 'At least one LMIC author',
        key: 'lmicAtLeastOneAuthor',
        legacyKey: '9_2',
      },
      {
        title: 'At least one LMIC study included',
        key: 'lmicAtLeastOneStudy',
        legacyKey: '9_3',
      },
    ],
  } /*
  checkedProvince: {
    title: 'Province Focus',
    items: [
      { title: 'Federal/national', key: 'territoryFederalNational' },
      { title: 'Alberta', key: 'territoryAlberta' },
      { title: 'British Columbia', key: 'territoryBritishColumbia' },
      { title: 'Manitoba', key: 'territoryManitoba' },
      { title: 'New Brunswick', key: 'territoryNewBrunswick' },
      {
        title: 'Newfoundland and Labrador',
        key: 'territoryNewFoundlandAndLabrador',
      },
      {
        title: 'Northwest Territories',
        key: 'territoryNorthwestTerritories',
      },
      { title: 'Nova Scotia', key: 'territoryNovaScotia' },
      { title: 'Nunavut', key: 'territoryNunavut' },
      { title: 'Ontario', key: 'territoryOntario' },
      { title: 'Prince Edward Island', key: 'territoryPrinceEdwardIsland' },
      { title: 'Quebec', key: 'territoryQuebec' },
      { title: 'Saskatchewan', key: 'territorySaskatchewan' },
      { title: 'Yukon', key: 'territoryYukon' },
    ],
  },
  checkedTheme: {
    title: 'Theme',
    legacyKey: '0_15',
    items: [
      { title: 'Optimal aging', key: 'themeOptimalAging', legacyKey: '15_1' },
      {
        title: 'Health promotion/primary prevention',
        key: 'themeHealthPromotionPrimaryPrevention',
        legacyKey: '15_2',
      },
    ],
  },*/,
  checkedPopulation: {
    title: 'Population',
    items: [
      {
        title: 'Children and youth',
        key: 'populationChildrenAndYouth',
        legacyKey: '31_164',
      },
      {
        title: 'Indigenous peoples',
        key: 'populationIndigenousPeoples',
        legacyKey: '31_169',
      },
      {
        title: 'Military personnel, Veterans and their families',
        key: 'populationMilitaryPersonnelVeteransAndFamily',
        legacyKey: '31_199',
      },
      {
        title: 'Older adults',
        key: 'populationOldAdults',
        legacyKey: '31_166',
      },
    ],
    legacyKey: '31_5',
  } /*
  checkedOPA: {
    title: 'Ontario Priority Areas',
    items: [
      {
        title: 'Community-based care',
        key: 'ontarioPriorityCommunityBasedCare',
      },
      {
        title: 'Health system performance and sustainability',
        key: 'ontarioPriorityHealthSystemPerformanceAndSustainability',
      },
      {
        title: 'Healthy living, with a focus on tobacco control',
        key: 'ontarioPriorityHealthyLivingWithAFocusOnTobaccoControl',
      },
      {
        title: 'Mental health and addictions',
        key: 'ontarioPriorityMentalHealthAndAddictions',
      },
      { title: 'Nursing research', key: 'ontarioPriorityNursingResearch' },
      {
        title: 'Primary care reform',
        key: 'ontarioPriorityPrimaryCareReform',
      },
      {
        title: 'Quality improvement and safety',
        key: 'ontarioPriorityQualityImprovementAndSafety',
      },
      { title: 'Seniors’ care', key: 'ontarioPrioritySeniorsCare' },
      {
        title: 'Vulnerable and special health needs populations',
        key: 'ontarioPriorityVulnerableAndSpecialHealthNeedsPopulations',
      },
      { title: 'Women’s health', key: 'ontarioPriorityWomensHealth' },
    ],
  } */,
  countries: {
    title: 'Area of focus',
    legacyKey: '0_3',
    items: [
      {
        title: 'Countries',
        key: 'areaOfFocusCountries',
        legacyKey: '0_6',
        children: [
          {
            title: 'Afghanistan',
            key: 'countryAfghanistan',
            legacyKey: '6_1',
          },
          {
            title: 'Albania',
            key: 'countryAlbania',
            legacyKey: '6_2',
          },
          {
            title: 'Algeria',
            key: 'countryAlgeria',
            legacyKey: '6_3',
          },
          {
            title: 'Andorra',
            key: 'countryAndorra',
            legacyKey: '6_4',
          },
          {
            title: 'Angola',
            key: 'countryAngola',
            legacyKey: '6_5',
          },
          {
            title: 'Antigua and Barbuda',
            key: 'countryAntiguaAndBarbuda',
            legacyKey: '6_6',
          },
          {
            title: 'Argentina',
            key: 'countryArgentina',
            legacyKey: '6_7',
          },
          {
            title: 'Armenia',
            key: 'countryArmenia',
            legacyKey: '6_8',
          },
          {
            title: 'Australia',
            key: 'countryAustralia',
            legacyKey: '6_9',
          },
          {
            title: 'Austria',
            key: 'countryAustria',
            legacyKey: '6_10',
          },
          {
            title: 'Azerbaijan',
            key: 'countryAzerbaijan',
            legacyKey: '6_11',
          },
          {
            title: 'Bahamas',
            key: 'countryBahamas',
            legacyKey: '6_12',
          },
          {
            title: 'Bahrain',
            key: 'countryBahrain',
            legacyKey: '6_13',
          },
          {
            title: 'Bangladesh',
            key: 'countryBangladesh',
            legacyKey: '6_14',
          },
          {
            title: 'Barbados',
            key: 'countryBarbados',
            legacyKey: '6_15',
          },
          {
            title: 'Belarus',
            key: 'countryBelarus',
            legacyKey: '6_16',
          },
          {
            title: 'Belgium',
            key: 'countryBelgium',
            legacyKey: '6_17',
          },
          {
            title: 'Belize',
            key: 'countryBelize',
            legacyKey: '6_18',
          },
          {
            title: 'Benin',
            key: 'countryBenin',
            legacyKey: '6_19',
          },
          {
            title: 'Bhutan',
            key: 'countryBhutan',
            legacyKey: '6_20',
          },
          {
            title: 'Bolivia (Plurinational State of)',
            key: 'countryBolivia',
            legacyKey: '6_21',
          },
          {
            title: 'Bosnia and Herzegovina',
            key: 'countryBosniaAndHerzegovina',
            legacyKey: '6_22',
          },
          {
            title: 'Botswana',
            key: 'countryBotswana',
            legacyKey: '6_23',
          },
          {
            title: 'Brazil',
            key: 'countryBrazil',
            legacyKey: '6_24',
          },
          {
            title: 'Brunei Darussalam',
            key: 'countryBruneiDarussalam',
            legacyKey: '6_25',
          },
          {
            title: 'Bulgaria',
            key: 'countryBulgaria',
            legacyKey: '6_26',
          },
          {
            title: 'Burkina Faso',
            key: 'countryBurkinaFaso',
            legacyKey: '6_27',
          },
          {
            title: 'Burundi',
            key: 'countryBurundi',
            legacyKey: '6_28',
          },
          {
            title: 'Cambodia',
            key: 'countryCambodia',
            legacyKey: '6_29',
          },
          {
            title: 'Cameroon',
            key: 'countryCameroon',
            legacyKey: '6_30',
          },
          {
            title: 'Canada',
            key: 'countryCanada',
            legacyKey: '6_31',
          },
          {
            title: 'Cape Verde',
            key: 'countryCapeVerde',
            legacyKey: '6_32',
          },
          {
            title: 'Central African Republic',
            key: 'countryCentralAfricanRepublic',
            legacyKey: '6_33',
          },
          {
            title: 'Chad',
            key: 'countryChad',
            legacyKey: '6_34',
          },
          {
            title: 'Chile',
            key: 'countryChile',
            legacyKey: '6_35',
          },
          {
            title: 'China',
            key: 'countryChina',
            legacyKey: '6_36',
          },
          {
            title: 'Colombia',
            key: 'countryColombia',
            legacyKey: '6_37',
          },
          {
            title: 'Comoros',
            key: 'countryComoros',
            legacyKey: '6_38',
          },
          {
            title: 'Congo (Brazzaville)',
            key: 'countryCongo',
            legacyKey: '6_39',
          },
          {
            title: 'Costa Rica',
            key: 'countryCostaRica',
            legacyKey: '6_40',
          },
          {
            title: "Côte d'Ivoire",
            key: 'countryCotedIvoire',
            legacyKey: '6_41',
          },
          {
            title: 'Croatia',
            key: 'countryCroatia',
            legacyKey: '6_42',
          },
          {
            title: 'Cuba',
            key: 'countryCuba',
            legacyKey: '6_43',
          },
          {
            title: 'Cyprus',
            key: 'countryCyprus',
            legacyKey: '6_44',
          },
          {
            title: 'Czech Republic',
            key: 'countryCzechRepublic',
            legacyKey: '6_45',
          },
          {
            title: "Korea (Democratic People's Republic of)",
            key: 'countryKoreaDPR',
            legacyKey: '6_46',
          },
          {
            title: 'Congo (Democratic Republic of)',
            key: 'countryCongoDPR',
            legacyKey: '6_47',
          },
          {
            title: 'Denmark',
            key: 'countryDenmark',
            legacyKey: '6_48',
          },
          {
            title: 'Djibouti',
            key: 'countryDjibouti',
            legacyKey: '6_49',
          },
          {
            title: 'Dominica',
            key: 'countryDominica',
            legacyKey: '6_50',
          },
          {
            title: 'Dominican Republic',
            key: 'countryDominicanRepublic',
            legacyKey: '6_51',
          },
          {
            title: 'Ecuador',
            key: 'countryEcuador',
            legacyKey: '6_52',
          },
          {
            title: 'Egypt',
            key: 'countryEgypt',
            legacyKey: '6_53',
          },
          {
            title: 'El Salvador',
            key: 'countryElSalvador',
            legacyKey: '6_54',
          },
          {
            title: 'Equatorial Guinea',
            key: 'countryEquatorialGuinea',
            legacyKey: '6_55',
          },
          {
            title: 'Eritrea',
            key: 'countryEritrea',
            legacyKey: '6_56',
          },
          {
            title: 'Estonia',
            key: 'countryEstonia',
            legacyKey: '6_57',
          },
          {
            title: 'Ethiopia',
            key: 'countryEthiopia',
            legacyKey: '6_58',
          },
          {
            title: 'Fiji',
            key: 'countryFiji',
            legacyKey: '6_59',
          },
          {
            title: 'Finland',
            key: 'countryFinland',
            legacyKey: '6_60',
          },
          {
            title: 'France',
            key: 'countryFrance',
            legacyKey: '6_61',
          },
          {
            title: 'Gabon',
            key: 'countryGabon',
            legacyKey: '6_62',
          },
          {
            title: 'Gambia',
            key: 'countryGambia',
            legacyKey: '6_63',
          },
          {
            title: 'Georgia',
            key: 'countryGeorgia',
            legacyKey: '6_64',
          },
          {
            title: 'Germany',
            key: 'countryGermany',
            legacyKey: '6_65',
          },
          {
            title: 'Ghana',
            key: 'countryGhana',
            legacyKey: '6_66',
          },
          {
            title: 'Greece',
            key: 'countryGreece',
            legacyKey: '6_67',
          },
          {
            title: 'Grenada',
            key: 'countryGrenada',
            legacyKey: '6_68',
          },
          {
            title: 'Guatemala',
            key: 'countryGuatemala',
            legacyKey: '6_69',
          },
          {
            title: 'Guinea',
            key: 'countryGuinea',
            legacyKey: '6_70',
          },
          {
            title: 'Guinea-Bissau',
            key: 'countryGuineaBissau',
            legacyKey: '6_71',
          },
          {
            title: 'Guyana',
            key: 'countryGuyana',
            legacyKey: '6_72',
          },
          {
            title: 'Haiti',
            key: 'countryHaiti',
            legacyKey: '6_73',
          },
          {
            title: 'Honduras',
            key: 'countryHonduras',
            legacyKey: '6_74',
          },
          {
            title: 'Hungary',
            key: 'countryHungary',
            legacyKey: '6_75',
          },
          {
            title: 'Iceland',
            key: 'countryIceland',
            legacyKey: '6_76',
          },
          {
            title: 'India',
            key: 'countryIndia',
            legacyKey: '6_77',
          },
          {
            title: 'Indonesia',
            key: 'countryIndonesia',
            legacyKey: '6_78',
          },
          {
            title: 'Iran (Islamic Republic of)',
            key: 'countryIran',
            legacyKey: '6_79',
          },
          {
            title: 'Iraq',
            key: 'countryIraq',
            legacyKey: '6_80',
          },
          {
            title: 'Ireland',
            key: 'countryIreland',
            legacyKey: '6_81',
          },
          {
            title: 'Israel',
            key: 'countryIsrael',
            legacyKey: '6_82',
          },
          {
            title: 'Italy',
            key: 'countryItaly',
            legacyKey: '6_83',
          },
          {
            title: 'Jamaica',
            key: 'countryJamaica',
            legacyKey: '6_84',
          },
          {
            title: 'Japan',
            key: 'countryJapan',
            legacyKey: '6_85',
          },
          {
            title: 'Jordan',
            key: 'countryJordan',
            legacyKey: '6_86',
          },
          {
            title: 'Kazakhstan',
            key: 'countryKazakhstan',
            legacyKey: '6_87',
          },
          {
            title: 'Kenya',
            key: 'countryKenya',
            legacyKey: '6_88',
          },
          {
            title: 'Kiribati',
            key: 'countryKiribati',
            legacyKey: '6_89',
          },
          {
            title: 'Kuwait',
            key: 'countryKuwait',
            legacyKey: '6_90',
          },
          {
            title: 'Kyrgyzstan',
            key: 'countryKyrgyzstan',
            legacyKey: '6_91',
          },
          {
            title: "Lao People's Democratic Republic",
            key: 'countryLao',
            legacyKey: '6_92',
          },
          {
            title: 'Latvia',
            key: 'countryLatvia',
            legacyKey: '6_93',
          },
          {
            title: 'Lebanon',
            key: 'countryLebanon',
            legacyKey: '6_94',
          },
          {
            title: 'Lesotho',
            key: 'countryLesotho',
            legacyKey: '6_95',
          },
          {
            title: 'Liberia',
            key: 'countryLiberia',
            legacyKey: '6_96',
          },
          {
            title: 'Libya',
            key: 'countryLibya',
            legacyKey: '6_97',
          },
          {
            title: 'Liechtenstein',
            key: 'countryLiechtenstein',
            legacyKey: '6_98',
          },
          {
            title: 'Lithuania',
            key: 'countryLithuania',
            legacyKey: '6_99',
          },
          {
            title: 'Luxembourg',
            key: 'countryLuxembourg',
            legacyKey: '6_100',
          },
          {
            title: 'Madagascar',
            key: 'countryMadagascar',
            legacyKey: '6_101',
          },
          {
            title: 'Malawi',
            key: 'countryMalawi',
            legacyKey: '6_102',
          },
          {
            title: 'Malaysia',
            key: 'countryMalaysia',
            legacyKey: '6_103',
          },
          {
            title: 'Maldives',
            key: 'countryMaldives',
            legacyKey: '6_104',
          },
          {
            title: 'Mali',
            key: 'countryMali',
            legacyKey: '6_105',
          },
          {
            title: 'Malta',
            key: 'countryMalta',
            legacyKey: '6_106',
          },
          {
            title: 'Marshall Islands',
            key: 'countryMarshallIslands',
            legacyKey: '6_107',
          },
          {
            title: 'Mauritania',
            key: 'countryMauritania',
            legacyKey: '6_108',
          },
          {
            title: 'Mauritius',
            key: 'countryMauritius',
            legacyKey: '6_109',
          },
          {
            title: 'Mexico',
            key: 'countryMexico',
            legacyKey: '6_110',
          },
          {
            title: 'Micronesia (Federated States of)',
            key: 'countryMicronesia',
            legacyKey: '6_111',
          },
          {
            title: 'Monaco',
            key: 'countryMonaco',
            legacyKey: '6_112',
          },
          {
            title: 'Mongolia',
            key: 'countryMongolia',
            legacyKey: '6_113',
          },
          {
            title: 'Montenegro',
            key: 'countryMontenegro',
            legacyKey: '6_114',
          },
          {
            title: 'Morocco',
            key: 'countryMorocco',
            legacyKey: '6_115',
          },
          {
            title: 'Mozambique',
            key: 'countryMozambique',
            legacyKey: '6_116',
          },
          {
            title: 'Myanmar',
            key: 'countryMyanmar',
            legacyKey: '6_117',
          },
          {
            title: 'Namibia',
            key: 'countryNamibia',
            legacyKey: '6_118',
          },
          {
            title: 'Nauru',
            key: 'countryNauru',
            legacyKey: '6_119',
          },
          {
            title: 'Nepal',
            key: 'countryNepal',
            legacyKey: '6_120',
          },
          {
            title: 'Netherlands',
            key: 'countryNetherlands',
            legacyKey: '6_121',
          },
          {
            title: 'New Zealand',
            key: 'countryNewZealand',
            legacyKey: '6_122',
          },
          {
            title: 'Nicaragua',
            key: 'countryNicaragua',
            legacyKey: '6_123',
          },
          {
            title: 'Niger',
            key: 'countryNiger',
            legacyKey: '6_124',
          },
          {
            title: 'Nigeria',
            key: 'countryNigeria',
            legacyKey: '6_125',
          },
          {
            title: 'Norway',
            key: 'countryNorway',
            legacyKey: '6_126',
          },
          {
            title: 'Oman',
            key: 'countryOman',
            legacyKey: '6_127',
          },
          {
            title: 'Pakistan',
            key: 'countryPakistan',
            legacyKey: '6_128',
          },
          {
            title: 'Palau',
            key: 'countryPalau',
            legacyKey: '6_129',
          },
          {
            title: 'Panama',
            key: 'countryPanama',
            legacyKey: '6_130',
          },
          {
            title: 'Papua New Guinea',
            key: 'countryPapuaNewGuinea',
            legacyKey: '6_131',
          },
          {
            title: 'Paraguay',
            key: 'countryParaguay',
            legacyKey: '6_132',
          },
          {
            title: 'Peru',
            key: 'countryPeru',
            legacyKey: '6_133',
          },
          {
            title: 'Philippines',
            key: 'countryPhilippines',
            legacyKey: '6_134',
          },
          {
            title: 'Poland',
            key: 'countryPoland',
            legacyKey: '6_135',
          },
          {
            title: 'Portugal',
            key: 'countryPortugal',
            legacyKey: '6_136',
          },
          {
            title: 'Qatar',
            key: 'countryQatar',
            legacyKey: '6_137',
          },
          {
            title: 'Korea (Republic of)',
            key: 'countryKorea',
            legacyKey: '6_138',
          },
          {
            title: 'Moldova (Republic of)',
            key: 'countryMoldova',
            legacyKey: '6_139',
          },
          {
            title: 'Romania',
            key: 'countryRomania',
            legacyKey: '6_140',
          },
          {
            title: 'Russian Federation',
            key: 'countryRussianFederation',
            legacyKey: '6_141',
          },
          {
            title: 'Rwanda',
            key: 'countryRwanda',
            legacyKey: '6_142',
          },
          {
            title: 'Saint Kitts and Nevis',
            key: 'countrySaintKittsAndNevis',
            legacyKey: '6_143',
          },
          {
            title: 'Saint Lucia',
            key: 'countrySaintLucia',
            legacyKey: '6_144',
          },
          {
            title: 'Saint Vincent and the Grenadines',
            key: 'countrySaintVincentAndTheGrenadines',
            legacyKey: '6_145',
          },
          {
            title: 'Samoa',
            key: 'countrySamoa',
            legacyKey: '6_146',
          },
          {
            title: 'San Marino',
            key: 'countrySanMarino',
            legacyKey: '6_147',
          },
          {
            title: 'Sao Tome and Principe',
            key: 'countrySaoTomeAndPrincipe',
            legacyKey: '6_148',
          },
          {
            title: 'Saudi Arabia',
            key: 'countrySaudiArabia',
            legacyKey: '6_149',
          },
          {
            title: 'Senegal',
            key: 'countrySenegal',
            legacyKey: '6_150',
          },
          {
            title: 'Serbia',
            key: 'countrySerbia',
            legacyKey: '6_151',
          },
          {
            title: 'Seychelles',
            key: 'countrySeychelles',
            legacyKey: '6_152',
          },
          {
            title: 'Sierra Leone',
            key: 'countrySierraLeone',
            legacyKey: '6_153',
          },
          {
            title: 'Singapore',
            key: 'countrySingapore',
            legacyKey: '6_154',
          },
          {
            title: 'Slovakia',
            key: 'countrySlovakia',
            legacyKey: '6_155',
          },
          {
            title: 'Slovenia',
            key: 'countrySlovenia',
            legacyKey: '6_156',
          },
          {
            title: 'Solomon Islands',
            key: 'countrySolomonIslands',
            legacyKey: '6_157',
          },
          {
            title: 'Somalia',
            key: 'countrySomalia',
            legacyKey: '6_158',
          },
          {
            title: 'South Africa',
            key: 'countrySouthAfrica',
            legacyKey: '6_159',
          },
          {
            title: 'Spain',
            key: 'countrySpain',
            legacyKey: '6_160',
          },
          {
            title: 'Sri Lanka',
            key: 'countrySriLanka',
            legacyKey: '6_161',
          },
          {
            title: 'Suriname',
            key: 'countrySuriname',
            legacyKey: '6_163',
          },
          {
            title: 'Swaziland',
            key: 'countrySwaziland',
            legacyKey: '6_164',
          },
          {
            title: 'Switzerland',
            key: 'countrySwitzerland',
            legacyKey: '6_165',
          },
          {
            title: 'Sweden',
            key: 'countrySweden',
            legacyKey: '6_166',
          },
          {
            title: 'Syrian Arab Republic',
            key: 'countrySyrianArabRepublic',
            legacyKey: '6_167',
          },
          {
            title: 'Tajikistan',
            key: 'countryTajikistan',
            legacyKey: '6_168',
          },
          {
            title: 'Thailand',
            key: 'countryThailand',
            legacyKey: '6_169',
          },
          {
            title: 'Macedonia (The former Yugoslav Republic of)',
            key: 'countryMacedonia',
            legacyKey: '6_170',
          },
          {
            title: 'Timor-Leste',
            key: 'countryTimorLeste',
            legacyKey: '6_171',
          },
          {
            title: 'Togo',
            key: 'countryTogo',
            legacyKey: '6_172',
          },
          {
            title: 'Tonga',
            key: 'countryTonga',
            legacyKey: '6_173',
          },
          {
            title: 'Trinidad and Tobago',
            key: 'countryTrinidadAndTobago',
            legacyKey: '6_174',
          },
          {
            title: 'Tunisia',
            key: 'countryTunisia',
            legacyKey: '6_175',
          },
          {
            title: 'Turkey',
            key: 'countryTurkey',
            legacyKey: '6_176',
          },
          {
            title: 'Turkmenistan',
            key: 'countryTurkmenistan',
            legacyKey: '6_177',
          },
          {
            title: 'Tuvalu',
            key: 'countryTuvalu',
            legacyKey: '6_178',
          },
          {
            title: 'Uganda',
            key: 'countryUganda',
            legacyKey: '6_179',
          },
          {
            title: 'Ukraine',
            key: 'countryUkraine',
            legacyKey: '6_180',
          },
          {
            title: 'United Arab Emirates',
            key: 'countryUnitedArabEmirates',
            legacyKey: '6_181',
          },
          {
            title: 'United Kingdom',
            key: 'countryUnitedKingdom',
            legacyKey: '6_182',
          },
          {
            title: 'Tanzania (United Republic of)',
            key: 'countryTanzania',
            legacyKey: '6_183',
          },
          {
            title: 'United States',
            key: 'countryUnitedStates',
            legacyKey: '6_184',
          },
          {
            title: 'Uruguay',
            key: 'countryUruguay',
            legacyKey: '6_185',
          },
          {
            title: 'Uzbekistan',
            key: 'countryUzbekistan',
            legacyKey: '6_186',
          },
          {
            title: 'Vanuatu',
            key: 'countryVanuatu',
            legacyKey: '6_187',
          },
          {
            title: 'Venezuela (Bolivarian Republic of)',
            key: 'countryVenezuela',
            legacyKey: '6_188',
          },
          {
            title: 'Viet Nam',
            key: 'countryVietNam',
            legacyKey: '6_189',
          },
          {
            title: 'Yemen',
            key: 'countryYemen',
            legacyKey: '6_190',
          },
          {
            title: 'Zambia',
            key: 'countryZambia',
            legacyKey: '6_191',
          },
          {
            title: 'Zimbabwe',
            key: 'countryZimbabwe',
            legacyKey: '6_192',
          },
          {
            title: 'Bermuda',
            key: 'countryBermuda',
            legacyKey: '6_193',
          },
          {
            title: 'French Polynesia',
            key: 'countryFrenchPolynesia',
            legacyKey: '6_194',
          },
          {
            title: 'Hong Kong',
            key: 'countryHongKong',
            legacyKey: '6_195',
          },
          {
            title: 'Kosovo',
            key: 'countryKosovo',
            legacyKey: '6_196',
          },
          {
            title: 'Northern Mariana Islands',
            key: 'countryNorthernMarianaIslands',
            legacyKey: '6_197',
          },
          {
            title: 'Palestine',
            key: 'countryPalestine',
            legacyKey: '6_198',
          },
          {
            title: 'Puerto Rico',
            key: 'countryPuertoRico',
            legacyKey: '6_199',
          },
          {
            title: 'Taiwan',
            key: 'countryTaiwan',
            legacyKey: '6_200',
          },
          {
            title: 'Anguilla',
            key: 'countryAnguilla',
            legacyKey: '6_253',
          },
          {
            title: 'Cook Islands',
            key: 'countryCookIslands',
            legacyKey: '6_255',
          },
          {
            title: 'Niue',
            key: 'countryNiue',
            legacyKey: '6_259',
          },
          {
            title: 'South Sudan',
            key: 'countrySouthSudan',
            legacyKey: '6_266',
          },
        ],
      },
      {
        title: 'Country groupings',
        key: 'areaOfFocusCountryGroupings',
        legacyKey: '0_7',
        children: [
          {
            title: 'ASEAN',
            key: 'areaOfFocusCountryGroupingsASEAN',
            legacyKey: '7_267',
          },
          {
            title: 'BRICS',
            key: 'areaOfFocusCountryGroupingsBRICS',
            legacyKey: '7_268',
          },
          {
            title: 'CARICOM',
            key: 'areaOfFocusCountryGroupingsCARICOM',
            legacyKey: '7_269',
          },
          {
            title: 'Commonwealth',
            key: 'areaOfFocusCountryGroupingsCommonwealth',
            legacyKey: '7_270',
          },
          {
            title: 'Francophonie',
            key: 'areaOfFocusCountryGroupingsFrancophonie',
            legacyKey: '7_271',
          },
          {
            title: 'G7',
            key: 'areaOfFocusCountryGroupingsG7',
            legacyKey: '7_272',
          },
          {
            title: 'G20',
            key: 'areaOfFocusCountryGroupingsG20',
            legacyKey: '7_273',
          },
          {
            title: 'EFTA',
            key: 'areaOfFocusCountryGroupingsEFTA',
            legacyKey: '7_274',
          },
          {
            title: 'European Union',
            key: 'areaOfFocusCountryGroupingsEU',
            legacyKey: '7_275',
          },
          {
            title: 'OPEC',
            key: 'areaOfFocusCountryGroupingsOPEC',
            legacyKey: '7_276',
          },
          {
            title: 'SAARC',
            key: 'areaOfFocusCountryGroupingsSAARC',
            legacyKey: '7_277',
          },
          {
            title: 'OECD',
            key: 'areaOfFocusCountryGroupingsOECD',
            legacyKey: '7_221',
          },
        ],
      },
      {
        title: 'WHO regions',
        key: 'areaOfFocusWhoRegions',
        legacyKey: '0_8',
        children: [
          {
            title: 'WHO - African region',
            key: 'areaOfFocusWhoRegionAfrican',
            legacyKey: '8_1',
          },
          {
            title: 'WHO - Americas region',
            key: 'areaOfFocusWhoRegionAmericas',
            legacyKey: '8_2',
          },
          {
            title: 'WHO - Eastern Mediterranean region',
            key: 'areaOfFocusWhoRegionEasternMediterranean',
            legacyKey: '8_4',
          },
          {
            title: 'WHO - South East Asia region',
            key: 'areaOfFocusWhoRegionSouthEastAsia',
            legacyKey: '8_8',
          },
          {
            title: 'WHO - Western Pacific region',
            key: 'areaOfFocusWhoRegionWesternPacific',
            legacyKey: '8_16',
          },
          {
            title: 'WHO - European region',
            key: 'areaOfFocusWhoRegionEuropean',
            legacyKey: '8_32',
          },
        ],
      },
    ],
  },
  features: {
    title: 'Document features',
    legacyKey: '0_4',
    items: [
      {
        title: 'Living',
        key: 'docFeatureLiving',
        legacyKey: '0_99',
      },
      {
        title: 'Type',
        key: 'docFeatureType',
        legacyKey: '0_10',
        children: [
          {
            title: 'Evidence briefs for policy',
            key: 'docFeatureTypeEvidenceBriefs',
            legacyKey: '10_1',
          },
          {
            title: 'Overviews of evidence syntheses',
            key: 'docFeatureTypeSystematicReviews',
            legacyKey: '10_2',
          },
          {
            title: 'Evidence syntheses of effects',
            key: 'docFeatureTypeSystematicReviewsEffects',
            legacyKey: '10_3',
          },
          {
            title: 'Evidence syntheses addressing other questions',
            key: 'docFeatureTypeSystematicReviewsOther',
            legacyKey: '10_4',
          },
          {
            title: 'Evidence syntheses in progress',
            key: 'docFeatureTypeSystematicReviewsInProgress',
            legacyKey: '10_5',
          },
          {
            title: 'Evidence syntheses being planned',
            key: 'docFeatureTypeSystematicReviewsPlanned',
            legacyKey: '10_6',
          },
          {
            title: 'Economic evaluations and costing studies',
            key: 'docFeatureTypeEconomicEvaluations',
            legacyKey: '10_7',
          },
          {
            title: 'Health reform descriptions',
            key: 'docFeatureTypeHealthReform',
            legacyKey: '10_8',
          },
          {
            title: 'Health system descriptions',
            key: 'docFeatureTypeHealthSystem',
            legacyKey: '10_9',
          },
        ],
      },
      {
        title: 'Free full-text',
        key: 'docFeatureFreeFullText',
        legacyKey: '0_11',
        children: [
          {
            title: 'Limit to records with free full-text link',
            key: 'docFeatureTypeFreeFullText',
            legacyKey: '0_27',
          },
        ],
      },
      {
        title: 'Date range',
        key: 'docFeatureDateRange',
        legacyKey: '0_12',
        type: 'dateRange',
      },
      // {
      //   title: 'Focus',
      //   key: 'docFeatureFocus',
      //   legacyKey: '0_13',
      //   children: [
      //     {
      //       title: 'General',
      //       key: 'docFeatureFocusGeneral',
      //       legacyKey: '13_1',
      //     },
      //     {
      //       title: 'Specific',
      //       key: 'docFeatureFocusSpecific',
      //       legacyKey: '13_2',
      //     },
      //   ],
      // },
      {
        title: 'Theme',
        key: 'docFeatureTheme',
        legacyKey: '0_15',
        children: [
          {
            title: 'Optimal aging',
            key: 'docFeatureThemeOptimalAging',
            legacyKey: '15_1',
          },
          {
            title: 'Health promotion/primary prevention',
            key: 'docFeatureThemeHealthPrevention',
            legacyKey: '15_2',
          },
        ],
      },
      {
        title: 'Producer',
        key: 'docFeatureProducer',
        legacyKey: '0_16',
        children: [
          {
            title: 'Optimal aging',
            key: 'docFeatureThemeOptimalAging',
            legacyKey: '0_28',
          },
        ],
      },
    ],
  },
};

export default {
  types,
  questionTypes,
  tree,
};
