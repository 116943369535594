import Context from './Context';
import LayerToggle from './LayerToggle';
import SaveSearchButtons from './SaveSearchButtons';

const SearchTips = ({
  filters,
  t,
  onShowMenu,
  onFilterChange,
  overlayContent,
  onSaveSearch,
  onSubscribeSearch,
  onSelectAllDocs,
  isSaved = false,
  isSubbed = false,
  isChecked = false,
  docs = [],
}) => {
  const renderFilterCount = () => {
    const count = filters.length || 0;
    return count > 0 ? <span className="filter-count">{count}</span> : null;
  };
  return (
    <div className="search-tips">
      <div className="layer-toggles clearfix">
        <LayerToggle menu="help" onToggle={onShowMenu}>
          {t('search_page.search_box.tips')}
        </LayerToggle>
        <LayerToggle
          menu="filterGroups"
          context={{
            filters: filters,
            onShowFilterGroup: onShowMenu,
            overlayContent: overlayContent,
            onFilterChange: onFilterChange,
          }}
          onToggle={onShowMenu}
        >
          {t('search_page.search_box.filters')}
          {renderFilterCount()}
        </LayerToggle>
      </div>
      <div className="clearfix">
        <SaveSearchButtons
          onSave={onSaveSearch}
          onSubscribe={onSubscribeSearch}
          onSelectAllDocs={onSelectAllDocs}
          isSaved={isSaved}
          isSubbed={isSubbed}
          isChecked={isChecked}
          docs={docs}
        />
      </div>
    </div>
  );
};

export default Context(SearchTips);
