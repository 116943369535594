import Context from './Context';
import { slugify } from '../utils/text';
import SignupPrompt from './SignupPrompt';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import SaveDocumentButtons from './SaveDocumentButtons';
import { formatLastLitDate } from '../utils/date';

const SearchResults = ({
  t,
  user,
  results = [],
  onSort,
  site,
  onCancelPrompt,
  promptVisible,
  onBottom,
  onSelectDoc,
  onEmailDocs,
  onSaveDocs,
  onSelectAllDocs,
  onViewDocs,
  docs = [],
  allSelected = false,
  language,
  count,
  sort,
}) => {
  useBottomScrollListener(onBottom);

  const getCountries = (result) => {
    let countries = result.countries;
    if (['Economic evaluations and costing studies'].includes(result.type)) {
      countries = countries.replaceAll('(0)', '');
    }
    countries = countries.replaceAll(')', ');');
    countries = countries.substring(0, countries.length - 1);
    return countries;
  };

  return (
    <div className="result-box">
      <div className={`result-box-header${user ? ' logged-in' : ''}`}>
        <div className="sort-order">
          <span className="sort-order-label">
            {t('search_page.sort_order.sorted_by')}&nbsp;
          </span>
          <select
            className="sort-order-control"
            onChange={(select) => onSort(select.target.value)}
            value={sort}
          >
            <option value="best">{t('search_page.sort_order.best')}</option>
            <option value="relevance">
              {t('search_page.sort_order.relevance')}
            </option>
            <option value="most_recent">
              {t('search_page.sort_order.most_recent')}
            </option>
            <option value="highest_quality">
              {t('search_page.sort_order.highest_quality')}
            </option>
          </select>
        </div>
        <div className="result-box-count">
          {count} {t('search_page.result_box.results')}
        </div>
        <SaveDocumentButtons
          onEmail={onEmailDocs}
          onSave={onSaveDocs}
          onView={onViewDocs}
          onSelectAll={onSelectAllDocs}
          allSelected={allSelected}
          docs={docs}
        />
      </div>

      <ol className="selectable-list result-list">
        {results.map((result, i) => {
          let href = `/articles/${result.id}-${slugify(
            result.title
          )}?source=search&lang=${language}`;
          return (
            <li key={i} className="selectable-item result-item">
              <header className="result-item-header">
                <div className="result-item-header-left">
                  <span className="result-item-number">{i + 1}.</span>
                  <div className="field result-item-published">
                    <span className="field-name">
                      {t('search_page.result_item.date')}
                      &nbsp;{formatLastLitDate(t, result)}
                      <span className="field_value"></span>
                    </span>
                    <span className="field-value"></span>
                  </div>
                  <div className="field result-item-quality">
                    <span className="field-name">
                      {t('search_page.result_item.quality')}&nbsp;
                      <span className="field_value">{result.quality}</span>
                    </span>
                    <span className="field-value"></span>
                  </div>
                  <div className="result-item-header-right action"></div>
                </div>
                {user && (
                  <div className="result-item-header-right action">
                    <label className="result-item-select">
                      <span>{t('select')}</span>
                      <div
                        className={`checkbox${
                          docs.includes(result.id) ? ' checkbox-checked' : ''
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={docs.includes(result.id)}
                          onChange={(e) =>
                            onSelectDoc(result.id, e.target.checked)
                          }
                        />
                      </div>
                    </label>
                  </div>
                )}
              </header>
              <section className="result-item-description">
                <h2 className="result-item-title">
                  <a href={href}>{result.title}</a>
                </h2>
                <div className="result-item-categories">
                  <span>{result.type}</span>
                  {result.countries !== '' && (
                    <span>&nbsp;{getCountries(result)}</span>
                  )}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: result.description || 'No abstract available',
                  }}
                />
              </section>
            </li>
          );
        })}
      </ol>
      {promptVisible && <SignupPrompt cancel={onCancelPrompt} />}
    </div>
  );
};

export default Context(SearchResults);
