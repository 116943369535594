import Context from './Context';

const SaveSearchButtons = ({
  t,
  user,
  onSave,
  onSubscribe,
  onSelectAllDocs,
  isSaved = false,
  isSubbed = false,
  isChecked = false,
  docs = [],
}) => {
  return (
    user && (
      <div className="saved-search-buttons">
        <a
          href="/save"
          className={`button btn-save${isSaved ? ' btn-save-on' : ''}`}
          onClick={onSave}
        >
          <span>{t('search_page.search_box.save')}</span>
        </a>
        <a
          href="/subscribe"
          className={`button btn-save-and-subscribe${
            isSubbed ? ' btn-save-and-subscribe-on' : ''
          }`}
          onClick={onSubscribe}
        >
          <span>{t('search_page.search_box.save_and_subscribe')}</span>
        </a>
        <label className="select-all-action button action">
          <span>{t('select_all')}</span>
          <div
            className={`checkbox${
              isChecked
                ? ' checkbox-checked'
                : docs.length > 0
                ? ' checkbox-deselect'
                : ''
            }`}
          >
            <input
              type="checkbox"
              name="search_to_delete"
              onChange={onSelectAllDocs}
            />
          </div>
        </label>
      </div>
    )
  );
};

export default Context(SaveSearchButtons);
